import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  supportDetail: {},
  supportCount: null,
  supportList: [],
  isHideAppBar: true
}

const mutations = {
  HIDE_APPBAR: (state, isHideAppBar) => {
    state.isHideAppBar = isHideAppBar
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations
}