export default [
  {
    id: 'CL33',
    tax: 19,
    code: 33,
    name: 'Factura electrónica',
    has_taxes: true,
    has_taxes_detail: true,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: true,
    is_order: false,
    send: true,
    given: true,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL34',
    tax: 19,
    code: 34,
    name: 'Factura exenta electrónica',
    has_taxes: false,
    has_taxes_detail: false,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: true,
    is_order: false,
    send: true,
    given: true,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL39',
    tax: 19,
    code: 39,
    name: 'Boleta electrónica',
    has_taxes: true,
    has_taxes_detail: true,
    has_lines_total: true,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: true,
    is_order: false,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL41',
    tax: 19,
    code: 41,
    name: 'Boleta exenta electrónica',
    has_taxes: false,
    has_taxes_detail: true,
    has_lines_total: true,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: true,
    is_order: false,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL43',
    tax: 19,
    code: 43,
    name: 'Liquidación factura electrónica',
    has_taxes: true,
    has_taxes_detail: true,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: false,
    is_order: false,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL46',
    tax: 19,
    code: 46,
    name: 'Factura de compra electrónica',
    has_taxes: true,
    has_taxes_detail: true,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: false,
    is_order: false,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL52',
    tax: 19,
    code: 52,
    name: 'Guía de despacho electrónica',
    has_taxes: true,
    has_taxes_detail: true,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: false,
    is_purshase: false,
    is_order: true,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
        'CLP'
    ]
  },
  {
    id: 'CL56',
    tax: 19,
    code: 56,
    name: 'Nota de débito electrónica',
    has_taxes: true,
    has_taxes_detail: true,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: true,
    is_order: false,
    send: true,
    given: true,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL61',
    tax: 19,
    code: 61,
    name: 'Nota de crédito electrónica',
    has_taxes: true,
    has_taxes_detail: true,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: true,
    is_order: false,
    send: true,
    given: true,
    country: 'CL',
    permissions: [],
    currencies: [
      'CLP'
    ]
  },
  {
    id: 'CL110',
    tax: 0,
    code: 110,
    name: 'Factura de exportación electrónica',
    has_taxes: false,
    has_taxes_detail: false,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: false,
    is_order: false,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
      'EUR',
      'USD',
      'OTM',
      'PEN',
      'SEK'
    ]
  },
  {
    id: 'CL112',
    tax: 0,
    code: 111,
    name: 'Nota de crédito de exportación electrónica',
    has_taxes: false,
    has_taxes_detail: false,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: false,
    is_order: false,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
      'EUR',
      'USD',
      'OTM',
      'PEN',
      'SEK'
    ]
  },
  {
    id: 'CL111',
    tax: 0,
    code: 112,
    name: 'Nota de débito de exportación electrónica',
    has_taxes: false,
    has_taxes_detail: false,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: false,
    is_order: false,
    send: true,
    given: false,
    country: 'CL',
    permissions: [],
    currencies: [
      'EUR',
      'USD',
      'OTM',
      'PEN',
      'SEK'
    ]
  },
  {
    id: 'PE09',
    tax: 0,
    code: parseInt('09', 8), // Octal literals are not allowed in strict mode
    name: 'Guía de remisión remitente',
    has_taxes: false,
    has_taxes_detail: false,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: false,
    is_order: false,
    send: true,
    given: false,
    country: 'PE',
    permissions: [],
    currencies: [
      'PEN'
    ]
  },
  {
    id: 'PE31',
    tax: 0,
    code: 31,
    name: 'Guía de remisión transportista',
    has_taxes: false,
    has_taxes_detail: false,
    has_lines_total: false,
    has_local_format: true,
    hold_taxes: false,
    is_foreign: false,
    is_sale: true,
    is_purshase: false,
    is_order: false,
    send: true,
    given: false,
    country: 'PE',
    permissions: [],
    currencies: [
      'PEN'
    ]
  }
]
