const generals = {
  RUT: 'RUT',
  'Folio #': 'Folio #',
  'Folio#': 'Folio#',
  documento: 'documento',
  Folio: 'Folio',
  folios: 'folios',
  'en SII': 'en SII',
  'a SII': 'a SII',
  'al SII': 'al SII',
  'Envío SII': 'Envío SII',
  'Estado SII': 'Estado',
  Folios: 'Folios',
  SII: 'SII',
  Comuna: 'Comuna',
  CLP: 'CLP', // x
  'Número fiscal': 'Número fiscal',
  'Respuesta al proveedor': 'Respuesta al proveedor',
  'Selecciona una comuna': 'Selecciona una comuna',
  RUN: 'RUN',
  sii: 'sii',
  'Folio actual': 'Folio actual',
  'Número de folio': 'Número de folio',
  'Enviado a SII': 'Enviado a SII',
  'Automatically sent ': 'enviado automáticamente a',
  'Sent ': 'enviado a'
}
export default {
  generals
}