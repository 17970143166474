const httpRoutes = [
  {
    path: '*',
    name: 'PageNotFound',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      title: 'Página no encontrada'
    },
    component: () => import('@/modules/http/views/PageNotFound')
  },
  {
    path: '*',
    name: 'PermissionDenied',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      title: 'Permiso denegado'
    },
    component: () => import('@/modules/http/views/PermissionDenied')
  }
]
export default httpRoutes