import store from '@/store'

const accountRoutes = [
  {
    path: '/account/settings',
    name: 'AccountMain',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountMain',
      requireAuth: true,
      requireAccount: true,
      showDetails: true,
      use: ['fc', 'ax'],
      title: 'Configuración de la organización'
    },
    component: () => import('@/modules/account/views/Main'),
    children: [
      {
        path: '/account/settings/basic',
        name: 'AccountBasic',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          showDetails: true,
          use: ['fc', 'ax'],
          title: 'Configuración de la organización',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Organización' }
            ]
          }
        },
        component: () => import('@/modules/account/views/Basic')
      },
      {
        path: '/account/settings/branding',
        name: 'AccountSettingsBranding',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['fc', 'ax'],
          title: 'Branding'
        },
        component: () => import('@/modules/account/views/Branding')
      },
      {
        path: '/account/settings/notifications',
        name: 'AccountNotifications',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['ax', 'fc'],
          title: 'Notificaciones',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Notificaciones' }
            ]
          }
        },
        component: () => import('@/modules/account/views/Notifications')
      },
      {
        path: '/account/settings/documents/irs/:id',
        name: 'IrsBiller',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['fc', 'ax'],
          title: 'Emisión'
        },
        component: () => import('@/modules/account/views/irs/Biller')
      },
      {
        path: '/account/settings/irs/built_in',
        name: 'IrsBuiltInSettings',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['fc', 'ax'],
          title: 'SII',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'SII' }
            ]
          }
        },
        component: () => import('@/modules/account/views/irs/sii/Settings')
      },
      {
        path: '/account/settings/collections',
        name: 'AccountSettingsCollections',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['fc', 'ax'],
          title: 'Cobranzas'
        },
        component: () => import('@/modules/account/views/Collections')
      },
      {
        path: '/account/settings/webhooks',
        name: 'AccountSettingsWebhooksListCreate',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['fc', 'ax'],
          title: 'Webhooks',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Developers' }
            ]
          }
        },
        component: () => import('@/modules/account/views/WebhooksListCreate')
      },
      {
        path: '/account/settings/webhooks/:id',
        name: 'AccountSettingsWebhooksRetrieveUpdate',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          use: ['fc', 'ax'],
          title: 'Webhooks'
          // breadcrumbs: [
          //   {
          //     text: 'Configuración',
          //     to: { name: 'AccountBasic' },
          //     link: true,
          //     exact: true,
          //     disabled: false
          //   },
          //   {
          //     text: 'Webhooks',
          //     disabled: true
          //   }
          // ]
        },
        component: () => import('@/modules/account/views/WebhooksRetrieveUpdate')
      },
      {
        path: '/account/settings/billing',
        name: 'AccountBilling',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          use: ['fc', 'ax'],
          title: 'Emisión de Axteroid'
        },
        component: () => import('@/modules/account/views/Billing')
      },
      {
        path: '/account/settings/pos',
        name: 'AccountSendLogPos',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['fc', 'ax'],
          title: 'Envio de LOGS',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'POS' }
            ]
          }
        },
        component: () => import('@/modules/account/views/AccountSendLogPos')
      },
      {
        path: '/account/settings/members',
        name: 'AccountMembers',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['view_member', 'change_member', 'delete_member', 'view_invitation', 'add_invitation', 'delete_invitation'],
          use: ['fc', 'ax'],
          title: 'Usuarios',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Directorio' },
              { text1: 'Miembros' }
            ]
          }
        },
        component: () => import('@/modules/account/views/members/Members')
      },
      {
        path: '/account/settings/members/:id/edit',
        name: 'AccountMemberUpdate',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['change_member'],
          use: ['fc', 'ax'],
          parentTitle: 'Miembros',
          title: 'Actualizar usuario',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Directorio' },
              { text1: 'Miembros' }
            ]
          }
        },
        component: () => import('@/modules/account/views/members/MembersUpdate')
      },
      {
        path: '/account/settings/groups',
        name: 'AccountGroups',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['view_group', 'add_group', 'change_group'],
          use: ['fc', 'ax'],
          itle: 'Roles',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Directorio' },
              { text1: 'Grupos' }
            ]
          }
        },
        component: () => import('@/modules/account/views/groups/Groups')
      },
      {
        path: '/account/settings/groups/create',
        name: 'AccountGroupsCreate',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['add_group'],
          use: ['fc', 'ax'],
          title: 'Crear rol',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Directorio' },
              { text1: 'Grupos' }
            ]
          }
        },
        component: () => import('@/modules/account/views/groups/GroupsCreateUpdate')
      },
      {
        path: '/account/settings/groups/:id/edit',
        name: 'AccountGroupsUpdate',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          // permissionsRequired: ['change_group', 'delete_group'],
          use: ['fc', 'ax'],
          title: 'Actualizar Grupo',
          breadcrumbs: {
            main: 'Configuración',
            children: [
              { text: 'Directorio' },
              { text1: 'Grupos' }
            ]
          }
        },
        component: () => import('@/modules/account/views/groups/GroupsCreateUpdate')
      },
      {
        path: '/account/settings/receipt',
        name: 'AccountReceipt',
        meta: {
          displayNavigationDrawer: true,
          displayOrganization: true,
          parent: 'AccountBasic',
          requireAuth: true,
          requireAccount: true,
          use: ['fc', 'ax'],
          title: 'Equipo'
        },
        component: () => import('@/modules/account/views/Receipt')
      }
    ]
  },
  {
    path: '/account/import/documents',
    name: 'AccountImportDocuments',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountBasic',
      requireAuth: true,
      use: ['fc', 'ax'],
      title: 'Importación de facturas'
      // breadcrumbs: [
      //   {
      //     text: 'Configuración',
      //     to: { name: 'AccountBasic' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Emisión',
      //     to: { name: 'IrsBuiltInSettings' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Importación de facturas',
      //     disabled: true
      //   }
      // ]
    },
    component: () => import('@/modules/account/views/ImportDocuments')
  },
  {
    path: '/account/dashboard',
    name: 'AccountDashboard',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountDashboard',
      requireAuth: true,
      requireAccount: true,
      use: ['ax', 'fc'],
      title: 'Dashboard'
    },
    component: () => import('@/modules/account/views/DashboardAX')
  },
  {
    path: '/account/exports',
    name: 'AccountExports',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountBasic',
      requireAuth: true,
      use: ['fc', 'ax']
    },
    component: () => import('@/modules/account/views/Exports')
  },
  {
    path: '/account/exports/customers',
    name: 'AccountCustomerExport',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountBasic',
      requireAuth: true,
      use: ['fc', 'ax']
    },
    component: () => import('@/modules/account/views/exports/CustomerExport')
  },
  {
    path: '/account/exports/documents',
    name: 'AccountExportDocuments',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountBasic',
      requireAuth: true,
      use: ['fc', 'ax']
    },
    component: () => import('@/modules/account/views/exports/ExportDocuments')
  },
  {
    path: '/account/exports/credit_notes',
    name: 'AccountExportCreditNote',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountBasic',
      requireAuth: true,
      use: ['fc', 'ax']
    },
    component: () => import('@/modules/account/views/exports/ExportCreditNote')
  },
  {
    path: '/account/imports',
    name: 'AccountImports',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountBasic',
      requireAuth: true,
      use: ['fc', 'ax']
    },
    component: () => import('@/modules/account/views/Imports')
  },
  {
    path: '/account/imports/customers',
    name: 'AccountImportCustomers',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'AccountBasic',
      requireAuth: true,
      use: ['fc', 'ax']
    },
    component: () => import('@/modules/account/views/imports/ImportCustomers')
  }
]

const addRoute = (use = [], showSupport) => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return use?.some((v) => {
    if (env === 'ax') {
      return store.state.auth.userAccount.is_staff ? (v === env || showSupport) : (v === env && !showSupport)
    }
    return (v === env && [undefined, true].includes(showSupport))
  })
}

const list = () => {
  return accountRoutes?.filter(({meta, children}, index) => {
    if (children?.length) {
      accountRoutes[0].children = children?.filter(({meta}) => addRoute(meta?.use, meta?.showSupport))
      return accountRoutes[0].children
    } else {
      return addRoute(meta?.use, meta?.showSupport)
    }
  })
}
export default list()