import { actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  labelsDetail: {},
  labelsCount: null,
  labelsList: [],
  retrieveLoader: false,
  createLoader: false,
  deleteLoader: false
}

const getters = {
  orgunits: state => {
    return state.orgunitsList
  }
}

export default {
  actions: actionsAbstract,
  getters,
  mutations: mutationsAbstract,
  namespaced: true,
  state
}
