import { actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  suppliersDetail: {},
  suppliersCount: null,
  suppliersList: [],
  groupsList: [],
  contactsList: [],
  changeShortcuts: false,
  retrieveLoader: false,
  createLoader: false,
  deleteLoader: false
}
const mutations = {
  SET_SHORTCUTS: (state) => {
    state.changeShortcuts = !state.changeShortcuts
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations
}
