const supplierRoutes = [
  {
    path: '/suppliers/groups/:id',
    name: 'SuppliersGroupsRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información del grupo - Proveedores'
    },
    component: () => import('@/modules/supplier/groups/views/SuppliersGroupsRetrieveUpdate')
  },
  {
    path: '/suppliers/groups',
    name: 'SuppliersGroupsList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Grupos - Proveedores'
    },
    component: () => import('@/modules/supplier/groups/views/SuppliersGroupsList')
  },
  {
    path: '/suppliers',
    name: 'SupplierListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      title: 'Proveedores'
    },
    component: () => import('@/modules/supplier/views/SupplierListCreate')
  },
  {
    path: '/suppliers/:id',
    name: 'SupplierRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      title: 'Detalles y actualización de proveedores'
    },
    component: () => import('@/modules/supplier/views/Main')
  }
]
export default supplierRoutes