import store from '@/store'

const helpers = {
  hasSomePermission (list = []) {
    if (!store.state.accounts.me?.is_owner && !store.state.auth.userAccount.is_staff) {
      return list?.some((rp) => store.state.accounts.me?.permissions?.some((p) => rp.includes(p)))
    }
    return true
  },
  hasPermission (list = []) {
    if (!store.state.accounts.me?.is_owner && !store.state.auth.userAccount.is_staff) {
      return list?.every((l) => store.state.accounts.me.permissions?.some((v) => v === l))
    }
    return true
  }
}

export default {
  install (Vue) {
    Vue.prototype.$helpers = helpers
  }
}
