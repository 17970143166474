import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  documentsDetail: {},
  documentsCount: null,
  documentsList: [],
  debitnotesDetail: {},
  debitnotesCount: null,
  debitnotesList: [],
  eventsCount: 0,
  eventsList: [],
  eventsDetail: {},
  creditnotesDetail: {},
  creditnotesCount: null,
  creditnotesList: [],
  cessionsCount: null,
  cessionsList: [],
  sequencesDetail: {},
  sequencesCount: null,
  sequencesList: [],
  sendTypeToggleDropdown: ''
}

const mutations = {
  SET_TOOGLE_SENDTYPE: (state, type) => {
    state.sendTypeToggleDropdown = type
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations
}
