import { account as accountApi } from '@/backend/api'
import Vue from 'vue'

const stateAbstract = {
  retrieveLoader: false,
  createLoader: false,
  destroyLoader: false
}

const actionsAbstract = {
  GET: ({ commit }, { resource, query = {}, loader = true }) => {
    return new Promise((resolve, reject) => {
      if (loader) {
        commit('TOGGLE_RETRIEVE_LOADER')
      }
      accountApi.get(resource, query)
      .then(response => {
        commit('SET_LIST', { response: response, resource: resource })
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        if (loader) {
          commit('TOGGLE_RETRIEVE_LOADER')
        }
      })
    })
  },
  LIST: ({ commit }, { resource, query, loader = true, responseType }) => {
    return new Promise((resolve, reject) => {
      if (loader) {
        commit('TOGGLE_RETRIEVE_LOADER')
      }
      accountApi.list(resource, query, responseType)
      .then(response => {
        commit('SET_LIST', { response: response, resource: resource })
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        if (loader) {
          commit('TOGGLE_RETRIEVE_LOADER')
        }
      })
    })
  },
  RETRIEVE: ({ commit }, { resource, id, detail, query, setDetail = true, loader = true }) => {
    return new Promise((resolve, reject) => {
      if (loader) {
        commit('TOGGLE_RETRIEVE_LOADER')
      }
      accountApi.retrieve(resource, id, detail, query)
      .then(response => {
        if (setDetail) {
          commit('SET_DETAIL', {
            response: response,
            resource: resource,
            loader: loader
          })
        }
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        if (loader) {
          commit('TOGGLE_RETRIEVE_LOADER')
        }
      })
    })
  },
  CREATE: ({ commit }, { resource, payload, detail, id, headers, commitInstance = true, query = {} }) => {
    return new Promise((resolve, reject) => {
      commit('TOGGLE_CREATE_LOADER')
      accountApi.create(resource, payload, detail, id, headers, query)
      .then(response => {
        if (commitInstance) {
          commit('SET_DETAIL', { response: response, resource: resource })
        }
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        commit('TOGGLE_CREATE_LOADER')
      })
    })
  },
  UPDATE: ({ commit }, { resource, id, payload, headers, detail }) => {
    return new Promise((resolve, reject) => {
      commit('TOGGLE_CREATE_LOADER')
      accountApi.update(resource, id, payload, headers, detail)
      .then(response => {
        commit('SET_DETAIL', { response: response, resource: resource })
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        commit('TOGGLE_CREATE_LOADER')
      })
    })
  },
  DESTROY: ({ commit }, { resource, id, query, loader = true, commitInstance = true }) => {
    return new Promise((resolve, reject) => {
      if (loader) {
        commit('TOGGLE_DESTROY_LOADER')
      }
      accountApi.destroy(resource, id, query)
      .then(() => {
        if (commitInstance) {
          commit('SET_DELETE', { resource: resource, id: id })
        }
        resolve()
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        if (loader) {
          commit('TOGGLE_DESTROY_LOADER')
        }
      })
    })
  },
  DOWNLOAD: ({ commit }, { resource, payload, query = {} }) => {
    return new Promise((resolve, reject) => {
      commit('TOGGLE_CREATE_LOADER')
      accountApi.downloadDocuments(resource, payload, query)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        commit('TOGGLE_CREATE_LOADER')
      })
    })
  }
}

const mutationsAbstract = {
  SET_LIST: (state, { response, resource }) => {
    const url = resource.includes('expand') ? resource.split('/')[0] : resource.split('/').pop()

    state[`${url}List`] = response?.data?.results
    state[`${url}Count`] = !!response?.data?.next
  },
  SET_DETAIL: (state, { response, resource, loader }) => {
    const r = resource.split('/').pop()
    const listName = r + 'List'
    const detailName = r + 'Detail'

    if (state[detailName]) {
      state[detailName] = response.data
    }

    if (state[listName]) {
      const method = response?.config?.method

      if (method === 'post') {
        state[listName].push(response.data)
      }

      if (method === 'patch') {
        const index = state[listName].findIndex((item) => item.id === response.data.id)
        Vue.set(state[listName], index, response.data)
      }
    }
  },
  SET_DELETE: (state, { resource, id }) => {
    const r = resource.split('/').pop()
    const index = state[`${r}List`].findIndex(d => d.id === id)
    state[`${r}List`].splice(index, 1)
  },
  SET_LOADER: (state, action) => {
    state[`${action}Loader`] = !state[`${action}Loader`]
  },
  TOGGLE_CREATE_LOADER: (state) => {
    state.createLoader = !state.createLoader
  },
  TOGGLE_RETRIEVE_LOADER: (state) => {
    state.retrieveLoader = !state.retrieveLoader
  },
  TOGGLE_DESTROY_LOADER: (state) => {
    state.destroyLoader = !state.destroyLoader
  }
}

export { stateAbstract, actionsAbstract, mutationsAbstract }