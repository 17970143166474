const generals = {
  RUT: 'RUC',
  'Folio #': 'Serie - Correlativo',
  'Folio#': 'Serie - Correlat.',
  documento: 'doc',
  Folio: 'Serie - Correlativo',
  folios: 'correlativo',
  'en SII': '',
  'a SII': '',
  'al SII': 'al OSE',
  'Envío SII': 'Envío',
  SII: 'SUNAT',
  Folios: 'Correlativos',
  'Estado SII': 'Estado SUNAT',
  Comuna: 'Distrito',
  CLP: 'PEN', // x
  'Número fiscal': 'RUC',
  'Respuesta al proveedor': 'Respuesta comercial',
  'Selecciona una comuna': 'Selecciona un distrito',
  RUN: 'RUC',
  sii: 'sunat',
  'Folio actual': 'Correlativo actual',
  'Número de folio': 'Correlativo',
  'Enviado a SII': 'Enviado a SUNAT',
  'Automatically sent ': 'enviado automáticamente a',
  'Sent ': 'enviado a'
}
export default {
  generals
}