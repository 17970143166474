import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  dashboardsDetail: {},
  dashboardsList: [],
  draft: {},
  ...stateAbstract
}

const actions = {
  DASHBOARD_LIST ({ commit }, draft) {
    commit('SET_DASHBOARD', draft)
  },

  CLEAR_DASHBOARD: ({ commit }) => {
    commit('CLEAR_DASHBOARD')
  },

  ...actionsAbstract
}

const mutations = {
  SET_DASHBOARD (state, draft) {
    state.draft = draft
  },

  CLEAR_DASHBOARD: (state) => {
    state.draft = {}
  },

  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
