import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  event_logDetail: {},
  event_logCount: null,
  event_logList: []
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}