export default [
  {
    id: "CL",
    states: [
      {
        id: "CL11201",
        code: "11201",
        name: "Aisén"
      },
      {
        id: "CL05602",
        code: "05602",
        name: "Algarrobo"
      },
      {
        id: "CL13502",
        code: "13502",
        name: "Alhué"
      },
      {
        id: "CL08314",
        code: "08314",
        name: "Alto Biobío"
      },
      {
        id: "CL03302",
        code: "03302",
        name: "Alto del Carmen"
      },
      {
        id: "CL01107",
        code: "01107",
        name: "Alto Hospicio"
      },
      {
        id: "CL10202",
        code: "10202",
        name: "Ancud"
      },
      {
        id: "CL04103",
        code: "04103",
        name: "Andacollo"
      },
      {
        id: "CL09201",
        code: "09201",
        name: "Angol"
      },
      {
        id: "CL12202",
        code: "12202",
        name: "Antártica"
      },
      {
        id: "CL02101",
        code: "02101",
        name: "Antofagasta"
      },
      {
        id: "CL08302",
        code: "08302",
        name: "Antuco"
      },
      {
        id: "CL08202",
        code: "08202",
        name: "Arauco"
      },
      {
        id: "CL15101",
        code: "15101",
        name: "Arica"
      },
      {
        id: "CL13402",
        code: "13402",
        name: "Buin"
      },
      {
        id: "CL16102",
        code: "16102",
        name: "Bulnes"
      },
      {
        id: "CL05402",
        code: "05402",
        name: "Cabildo"
      },
      {
        id: "CL12201",
        code: "12201",
        name: "Cabo de Hornos"
      },
      {
        id: "CL08303",
        code: "08303",
        name: "Cabrero"
      },
      {
        id: "CL02201",
        code: "02201",
        name: "Calama"
      },
      {
        id: "CL10102",
        code: "10102",
        name: "Calbuco"
      },
      {
        id: "CL03102",
        code: "03102",
        name: "Caldera"
      },
      {
        id: "CL05502",
        code: "05502",
        name: "Calera"
      },
      {
        id: "CL13403",
        code: "13403",
        name: "Calera de Tango"
      },
      {
        id: "CL05302",
        code: "05302",
        name: "Calle Larga"
      },
      {
        id: "CL15102",
        code: "15102",
        name: "Camarones"
      },
      {
        id: "CL01402",
        code: "01402",
        name: "Camiña"
      },
      {
        id: "CL04202",
        code: "04202",
        name: "Canela"
      },
      {
        id: "CL08203",
        code: "08203",
        name: "Cañete"
      },
      {
        id: "CL09102",
        code: "09102",
        name: "Carahue"
      },
      {
        id: "CL05603",
        code: "05603",
        name: "Cartagena"
      },
      {
        id: "CL05102",
        code: "05102",
        name: "Casablanca"
      },
      {
        id: "CL10201",
        code: "10201",
        name: "Castro"
      },
      {
        id: "CL05702",
        code: "05702",
        name: "Catemu"
      },
      {
        id: "CL07201",
        code: "07201",
        name: "Cauquenes"
      },
      {
        id: "CL13102",
        code: "13102",
        name: "Cerrillos"
      },
      {
        id: "CL13103",
        code: "13103",
        name: "Cerro Navia"
      },
      {
        id: "CL10401",
        code: "10401",
        name: "Chaitén"
      },
      {
        id: "CL03201",
        code: "03201",
        name: "Chañaral"
      },
      {
        id: "CL07202",
        code: "07202",
        name: "Chanco"
      },
      {
        id: "CL06302",
        code: "06302",
        name: "Chépica"
      },
      {
        id: "CL08103",
        code: "08103",
        name: "Chiguayante"
      },
      {
        id: "CL11401",
        code: "11401",
        name: "Chile Chico"
      },
      {
        id: "CL16101",
        code: "16101",
        name: "Chillán"
      },
      {
        id: "CL16103",
        code: "16103",
        name: "Chillán Viejo"
      },
      {
        id: "CL06303",
        code: "06303",
        name: "Chimbarongo"
      },
      {
        id: "CL09121",
        code: "09121",
        name: "Cholchol"
      },
      {
        id: "CL10203",
        code: "10203",
        name: "Chonchi"
      },
      {
        id: "CL11202",
        code: "11202",
        name: "Cisnes"
      },
      {
        id: "CL16202",
        code: "16202",
        name: "Cobquecura"
      },
      {
        id: "CL10103",
        code: "10103",
        name: "Cochamó"
      },
      {
        id: "CL11301",
        code: "11301",
        name: "Cochrane"
      },
      {
        id: "CL06102",
        code: "06102",
        name: "Codegua"
      },
      {
        id: "CL16203",
        code: "16203",
        name: "Coelemu"
      },
      {
        id: "CL11101",
        code: "11101",
        name: "Coyhaique"
      },
      {
        id: "CL16302",
        code: "16302",
        name: "Coihueco"
      },
      {
        id: "CL06103",
        code: "06103",
        name: "Coinco"
      },
      {
        id: "CL07402",
        code: "07402",
        name: "Colbún"
      },
      {
        id: "CL01403",
        code: "01403",
        name: "Colchane"
      },
      {
        id: "CL13301",
        code: "13301",
        name: "Colina"
      },
      {
        id: "CL09202",
        code: "09202",
        name: "Collipulli"
      },
      {
        id: "CL06104",
        code: "06104",
        name: "Coltauco"
      },
      {
        id: "CL04302",
        code: "04302",
        name: "Combarbalá"
      },
      {
        id: "CL08101",
        code: "08101",
        name: "Concepción"
      },
      {
        id: "CL13104",
        code: "13104",
        name: "Conchalí"
      },
      {
        id: "CL05103",
        code: "05103",
        name: "Concón"
      },
      {
        id: "CL07102",
        code: "07102",
        name: "Constitución"
      },
      {
        id: "CL08204",
        code: "08204",
        name: "Contulmo"
      },
      {
        id: "CL03101",
        code: "03101",
        name: "Copiapó"
      },
      {
        id: "CL04102",
        code: "04102",
        name: "Coquimbo"
      },
      {
        id: "CL08102",
        code: "08102",
        name: "Coronel"
      },
      {
        id: "CL14102",
        code: "14102",
        name: "Corral"
      },
      {
        id: "CL09103",
        code: "09103",
        name: "Cunco"
      },
      {
        id: "CL09203",
        code: "09203",
        name: "Curacautín"
      },
      {
        id: "CL13503",
        code: "13503",
        name: "Curacaví"
      },
      {
        id: "CL10204",
        code: "10204",
        name: "Curaco de Vélez"
      },
      {
        id: "CL08205",
        code: "08205",
        name: "Curanilahue"
      },
      {
        id: "CL09104",
        code: "09104",
        name: "Curarrehue"
      },
      {
        id: "CL07103",
        code: "07103",
        name: "Curepto"
      },
      {
        id: "CL07301",
        code: "07301",
        name: "Curicó"
      },
      {
        id: "CL10205",
        code: "10205",
        name: "Dalcahue"
      },
      {
        id: "CL03202",
        code: "03202",
        name: "Diego de Almagro"
      },
      {
        id: "CL06105",
        code: "06105",
        name: "Doñihue"
      },
      {
        id: "CL13105",
        code: "13105",
        name: "El Bosque"
      },
      {
        id: "CL16104",
        code: "16104",
        name: "El Carmen"
      },
      {
        id: "CL13602",
        code: "13602",
        name: "El Monte"
      },
      {
        id: "CL05604",
        code: "05604",
        name: "El Quisco"
      },
      {
        id: "CL05605",
        code: "05605",
        name: "El Tabo"
      },
      {
        id: "CL07104",
        code: "07104",
        name: "Empedrado"
      },
      {
        id: "CL09204",
        code: "09204",
        name: "Ercilla"
      },
      {
        id: "CL13106",
        code: "13106",
        name: "Estación Central"
      },
      {
        id: "CL08104",
        code: "08104",
        name: "Florida"
      },
      {
        id: "CL09105",
        code: "09105",
        name: "Freire"
      },
      {
        id: "CL03303",
        code: "03303",
        name: "Freirina"
      },
      {
        id: "CL10104",
        code: "10104",
        name: "Fresia"
      },
      {
        id: "CL10105",
        code: "10105",
        name: "Frutillar"
      },
      {
        id: "CL10402",
        code: "10402",
        name: "Futaleufú"
      },
      {
        id: "CL14202",
        code: "14202",
        name: "Futrono"
      },
      {
        id: "CL09106",
        code: "09106",
        name: "Galvarino"
      },
      {
        id: "CL15202",
        code: "15202",
        name: "General Lagos"
      },
      {
        id: "CL09107",
        code: "09107",
        name: "Gorbea"
      },
      {
        id: "CL06106",
        code: "06106",
        name: "Graneros"
      },
      {
        id: "CL11203",
        code: "11203",
        name: "Guaitecas"
      },
      {
        id: "CL05503",
        code: "05503",
        name: "Hijuelas"
      },
      {
        id: "CL10403",
        code: "10403",
        name: "Hualaihué"
      },
      {
        id: "CL07302",
        code: "07302",
        name: "Hualañé"
      },
      {
        id: "CL08112",
        code: "08112",
        name: "Hualpén"
      },
      {
        id: "CL08105",
        code: "08105",
        name: "Hualqui"
      },
      {
        id: "CL01404",
        code: "01404",
        name: "Huara"
      },
      {
        id: "CL03304",
        code: "03304",
        name: "Huasco"
      },
      {
        id: "CL13107",
        code: "13107",
        name: "Huechuraba"
      },
      {
        id: "CL04201",
        code: "04201",
        name: "Illapel"
      },
      {
        id: "CL13108",
        code: "13108",
        name: "Independencia"
      },
      {
        id: "CL01101",
        code: "01101",
        name: "Iquique"
      },
      {
        id: "CL13603",
        code: "13603",
        name: "Isla de Maipo"
      },
      {
        id: "CL05201",
        code: "05201",
        name: "Isla  de Pascua"
      },
      {
        id: "CL05104",
        code: "05104",
        name: "Juan Fernández"
      },
      {
        id: "CL13109",
        code: "13109",
        name: "La Cisterna"
      },
      {
        id: "CL05504",
        code: "05504",
        name: "La Cruz"
      },
      {
        id: "CL06202",
        code: "06202",
        name: "La Estrella"
      },
      {
        id: "CL13110",
        code: "13110",
        name: "La Florida"
      },
      {
        id: "CL14203",
        code: "14203",
        name: "Lago Ranco"
      },
      {
        id: "CL11102",
        code: "11102",
        name: "Lago Verde"
      },
      {
        id: "CL13111",
        code: "13111",
        name: "La Granja"
      },
      {
        id: "CL12102",
        code: "12102",
        name: "Laguna Blanca"
      },
      {
        id: "CL04104",
        code: "04104",
        name: "La Higuera"
      },
      {
        id: "CL08304",
        code: "08304",
        name: "Laja"
      },
      {
        id: "CL05401",
        code: "05401",
        name: "La Ligua"
      },
      {
        id: "CL13302",
        code: "13302",
        name: "Lampa"
      },
      {
        id: "CL14103",
        code: "14103",
        name: "Lanco"
      },
      {
        id: "CL13112",
        code: "13112",
        name: "La Pintana"
      },
      {
        id: "CL13113",
        code: "13113",
        name: "La Reina"
      },
      {
        id: "CL06107",
        code: "06107",
        name: "Las Cabras"
      },
      {
        id: "CL13114",
        code: "13114",
        name: "Las Condes"
      },
      {
        id: "CL04101",
        code: "04101",
        name: "La Serena"
      },
      {
        id: "CL14201",
        code: "14201",
        name: "La Unión"
      },
      {
        id: "CL09108",
        code: "09108",
        name: "Lautaro"
      },
      {
        id: "CL08201",
        code: "08201",
        name: "Lebu"
      },
      {
        id: "CL07303",
        code: "07303",
        name: "Licantén"
      },
      {
        id: "CL05802",
        code: "05802",
        name: "Limache"
      },
      {
        id: "CL07401",
        code: "07401",
        name: "Linares"
      },
      {
        id: "CL06203",
        code: "06203",
        name: "Litueche"
      },
      {
        id: "CL05703",
        code: "05703",
        name: "Llaillay"
      },
      {
        id: "CL10107",
        code: "10107",
        name: "Llanquihue"
      },
      {
        id: "CL13115",
        code: "13115",
        name: "Lo Barnechea"
      },
      {
        id: "CL13116",
        code: "13116",
        name: "Lo Espejo"
      },
      {
        id: "CL06304",
        code: "06304",
        name: "Lolol"
      },
      {
        id: "CL09109",
        code: "09109",
        name: "Loncoche"
      },
      {
        id: "CL07403",
        code: "07403",
        name: "Longaví"
      },
      {
        id: "CL09205",
        code: "09205",
        name: "Lonquimay"
      },
      {
        id: "CL13117",
        code: "13117",
        name: "Lo Prado"
      },
      {
        id: "CL08206",
        code: "08206",
        name: "Los Álamos"
      },
      {
        id: "CL05301",
        code: "05301",
        name: "Los Andes"
      },
      {
        id: "CL08301",
        code: "08301",
        name: "Los Ángeles"
      },
      {
        id: "CL14104",
        code: "14104",
        name: "Los Lagos"
      },
      {
        id: "CL10106",
        code: "10106",
        name: "Los Muermos"
      },
      {
        id: "CL09206",
        code: "09206",
        name: "Los Sauces"
      },
      {
        id: "CL04203",
        code: "04203",
        name: "Los Vilos"
      },
      {
        id: "CL08106",
        code: "08106",
        name: "Lota"
      },
      {
        id: "CL09207",
        code: "09207",
        name: "Lumaco"
      },
      {
        id: "CL06108",
        code: "06108",
        name: "Machalí"
      },
      {
        id: "CL13118",
        code: "13118",
        name: "Macul"
      },
      {
        id: "CL14105",
        code: "14105",
        name: "Máfil"
      },
      {
        id: "CL13119",
        code: "13119",
        name: "Maipú"
      },
      {
        id: "CL06109",
        code: "06109",
        name: "Malloa"
      },
      {
        id: "CL06204",
        code: "06204",
        name: "Marchihue"
      },
      {
        id: "CL02302",
        code: "02302",
        name: "María Elena"
      },
      {
        id: "CL13504",
        code: "13504",
        name: "María Pinto"
      },
      {
        id: "CL14106",
        code: "14106",
        name: "Mariquina"
      },
      {
        id: "CL07105",
        code: "07105",
        name: "Maule"
      },
      {
        id: "CL10108",
        code: "10108",
        name: "Maullín"
      },
      {
        id: "CL02102",
        code: "02102",
        name: "Mejillones"
      },
      {
        id: "CL09110",
        code: "09110",
        name: "Melipeuco"
      },
      {
        id: "CL13501",
        code: "13501",
        name: "Melipilla"
      },
      {
        id: "CL07304",
        code: "07304",
        name: "Molina"
      },
      {
        id: "CL04303",
        code: "04303",
        name: "Monte Patria"
      },
      {
        id: "CL06110",
        code: "06110",
        name: "Mostazal"
      },
      {
        id: "CL08305",
        code: "08305",
        name: "Mulchén"
      },
      {
        id: "CL08306",
        code: "08306",
        name: "Nacimiento"
      },
      {
        id: "CL06305",
        code: "06305",
        name: "Nancagua"
      },
      {
        id: "CL12401",
        code: "12401",
        name: "Natales"
      },
      {
        id: "CL06205",
        code: "06205",
        name: "Navidad"
      },
      {
        id: "CL08307",
        code: "08307",
        name: "Negrete"
      },
      {
        id: "CL16204",
        code: "16204",
        name: "Ninhue"
      },
      {
        id: "CL16303",
        code: "16303",
        name: "Ñiquén"
      },
      {
        id: "CL05506",
        code: "05506",
        name: "Nogales"
      },
      {
        id: "CL09111",
        code: "09111",
        name: "Nueva Imperial"
      },
      {
        id: "CL13120",
        code: "13120",
        name: "Ñuñoa"
      },
      {
        id: "CL11302",
        code: "11302",
        name: "O'Higgins"
      },
      {
        id: "CL06111",
        code: "06111",
        name: "Olivar"
      },
      {
        id: "CL02202",
        code: "02202",
        name: "Ollagüe"
      },
      {
        id: "CL05803",
        code: "05803",
        name: "Olmué"
      },
      {
        id: "CL10301",
        code: "10301",
        name: "Osorno"
      },
      {
        id: "CL04301",
        code: "04301",
        name: "Ovalle"
      },
      {
        id: "CL13604",
        code: "13604",
        name: "Padre Hurtado"
      },
      {
        id: "CL09112",
        code: "09112",
        name: "Padre Las Casas"
      },
      {
        id: "CL04105",
        code: "04105",
        name: "Paiguano"
      },
      {
        id: "CL14107",
        code: "14107",
        name: "Paillaco"
      },
      {
        id: "CL13404",
        code: "13404",
        name: "Paine"
      },
      {
        id: "CL10404",
        code: "10404",
        name: "Palena"
      },
      {
        id: "CL06306",
        code: "06306",
        name: "Palmilla"
      },
      {
        id: "CL14108",
        code: "14108",
        name: "Panguipulli"
      },
      {
        id: "CL05704",
        code: "05704",
        name: "Panquehue"
      },
      {
        id: "CL05403",
        code: "05403",
        name: "Papudo"
      },
      {
        id: "CL06206",
        code: "06206",
        name: "Paredones"
      },
      {
        id: "CL07404",
        code: "07404",
        name: "Parral"
      },
      {
        id: "CL13121",
        code: "13121",
        name: "Pedro Aguirre Cerda"
      },
      {
        id: "CL07106",
        code: "07106",
        name: "Pelarco"
      },
      {
        id: "CL07203",
        code: "07203",
        name: "Pelluhue"
      },
      {
        id: "CL16105",
        code: "16105",
        name: "Pemuco"
      },
      {
        id: "CL13605",
        code: "13605",
        name: "Peñaflor"
      },
      {
        id: "CL13122",
        code: "13122",
        name: "Peñalolén"
      },
      {
        id: "CL07107",
        code: "07107",
        name: "Pencahue"
      },
      {
        id: "CL08107",
        code: "08107",
        name: "Penco"
      },
      {
        id: "CL06307",
        code: "06307",
        name: "Peralillo"
      },
      {
        id: "CL09113",
        code: "09113",
        name: "Perquenco"
      },
      {
        id: "CL05404",
        code: "05404",
        name: "Petorca"
      },
      {
        id: "CL06112",
        code: "06112",
        name: "Peumo"
      },
      {
        id: "CL01405",
        code: "01405",
        name: "Pica"
      },
      {
        id: "CL06113",
        code: "06113",
        name: "Pichidegua"
      },
      {
        id: "CL06201",
        code: "06201",
        name: "Pichilemu"
      },
      {
        id: "CL16106",
        code: "16106",
        name: "Pinto"
      },
      {
        id: "CL13202",
        code: "13202",
        name: "Pirque"
      },
      {
        id: "CL09114",
        code: "09114",
        name: "Pitrufquén"
      },
      {
        id: "CL06308",
        code: "06308",
        name: "Placilla"
      },
      {
        id: "CL16205",
        code: "16205",
        name: "Portezuelo"
      },
      {
        id: "CL12301",
        code: "12301",
        name: "Porvenir"
      },
      {
        id: "CL01401",
        code: "01401",
        name: "Pozo Almonte"
      },
      {
        id: "CL12302",
        code: "12302",
        name: "Primavera"
      },
      {
        id: "CL13123",
        code: "13123",
        name: "Providencia"
      },
      {
        id: "CL05105",
        code: "05105",
        name: "Puchuncaví"
      },
      {
        id: "CL09115",
        code: "09115",
        name: "Pucón"
      },
      {
        id: "CL13124",
        code: "13124",
        name: "Pudahuel"
      },
      {
        id: "CL13201",
        code: "13201",
        name: "Puente Alto"
      },
      {
        id: "CL10101",
        code: "10101",
        name: "Puerto Montt"
      },
      {
        id: "CL10302",
        code: "10302",
        name: "Puerto Octay"
      },
      {
        id: "CL10109",
        code: "10109",
        name: "Puerto Varas"
      },
      {
        id: "CL06309",
        code: "06309",
        name: "Pumanque"
      },
      {
        id: "CL04304",
        code: "04304",
        name: "Punitaqui"
      },
      {
        id: "CL12101",
        code: "12101",
        name: "Punta Arenas"
      },
      {
        id: "CL10206",
        code: "10206",
        name: "Puqueldón"
      },
      {
        id: "CL09208",
        code: "09208",
        name: "Purén"
      },
      {
        id: "CL10303",
        code: "10303",
        name: "Purranque"
      },
      {
        id: "CL05705",
        code: "05705",
        name: "Putaendo"
      },
      {
        id: "CL15201",
        code: "15201",
        name: "Putre"
      },
      {
        id: "CL10304",
        code: "10304",
        name: "Puyehue"
      },
      {
        id: "CL10207",
        code: "10207",
        name: "Queilén"
      },
      {
        id: "CL10208",
        code: "10208",
        name: "Quellón"
      },
      {
        id: "CL10209",
        code: "10209",
        name: "Quemchi"
      },
      {
        id: "CL08308",
        code: "08308",
        name: "Quilaco"
      },
      {
        id: "CL13125",
        code: "13125",
        name: "Quilicura"
      },
      {
        id: "CL08309",
        code: "08309",
        name: "Quilleco"
      },
      {
        id: "CL16107",
        code: "16107",
        name: "Quillón"
      },
      {
        id: "CL05501",
        code: "05501",
        name: "Quillota"
      },
      {
        id: "CL05801",
        code: "05801",
        name: "Quilpué"
      },
      {
        id: "CL10210",
        code: "10210",
        name: "Quinchao"
      },
      {
        id: "CL06114",
        code: "06114",
        name: "Quinta de Tilcoco"
      },
      {
        id: "CL13126",
        code: "13126",
        name: "Quinta Normal"
      },
      {
        id: "CL05107",
        code: "05107",
        name: "Quintero"
      },
      {
        id: "CL16201",
        code: "16201",
        name: "Quirihue"
      },
      {
        id: "CL06101",
        code: "06101",
        name: "Rancagua"
      },
      {
        id: "CL16206",
        code: "16206",
        name: "Ránquil"
      },
      {
        id: "CL07305",
        code: "07305",
        name: "Rauco"
      },
      {
        id: "CL13127",
        code: "13127",
        name: "Recoleta"
      },
      {
        id: "CL09209",
        code: "09209",
        name: "Renaico"
      },
      {
        id: "CL13128",
        code: "13128",
        name: "Renca"
      },
      {
        id: "CL06115",
        code: "06115",
        name: "Rengo"
      },
      {
        id: "CL06116",
        code: "06116",
        name: "Requínoa"
      },
      {
        id: "CL07405",
        code: "07405",
        name: "Retiro"
      },
      {
        id: "CL05303",
        code: "05303",
        name: "Rinconada"
      },
      {
        id: "CL14204",
        code: "14204",
        name: "Río Bueno"
      },
      {
        id: "CL07108",
        code: "07108",
        name: "Río Claro"
      },
      {
        id: "CL04305",
        code: "04305",
        name: "Río Hurtado"
      },
      {
        id: "CL11402",
        code: "11402",
        name: "Río Ibáñez"
      },
      {
        id: "CL10305",
        code: "10305",
        name: "Río Negro"
      },
      {
        id: "CL12103",
        code: "12103",
        name: "Río Verde"
      },
      {
        id: "CL07306",
        code: "07306",
        name: "Romeral"
      },
      {
        id: "CL09116",
        code: "09116",
        name: "Saavedra"
      },
      {
        id: "CL07307",
        code: "07307",
        name: "Sagrada Familia"
      },
      {
        id: "CL04204",
        code: "04204",
        name: "Salamanca"
      },
      {
        id: "CL05601",
        code: "05601",
        name: "San Antonio"
      },
      {
        id: "CL13401",
        code: "13401",
        name: "San Bernardo"
      },
      {
        id: "CL16301",
        code: "16301",
        name: "San Carlos"
      },
      {
        id: "CL07109",
        code: "07109",
        name: "San Clemente"
      },
      {
        id: "CL05304",
        code: "05304",
        name: "San Esteban"
      },
      {
        id: "CL16304",
        code: "16304",
        name: "San Fabián"
      },
      {
        id: "CL05701",
        code: "05701",
        name: "San Felipe"
      },
      {
        id: "CL06301",
        code: "06301",
        name: "San Fernando"
      },
      {
        id: "CL12104",
        code: "12104",
        name: "San Gregorio"
      },
      {
        id: "CL16108",
        code: "16108",
        name: "San Ignacio"
      },
      {
        id: "CL07406",
        code: "07406",
        name: "San Javier"
      },
      {
        id: "CL13129",
        code: "13129",
        name: "San Joaquín"
      },
      {
        id: "CL13203",
        code: "13203",
        name: "San José de Maipo"
      },
      {
        id: "CL10306",
        code: "10306",
        name: "San Juan de la Costa"
      },
      {
        id: "CL13130",
        code: "13130",
        name: "San Miguel"
      },
      {
        id: "CL16305",
        code: "16305",
        name: "San Nicolás"
      },
      {
        id: "CL10307",
        code: "10307",
        name: "San Pablo"
      },
      {
        id: "CL13505",
        code: "13505",
        name: "San Pedro"
      },
      {
        id: "CL02203",
        code: "02203",
        name: "San Pedro de Atacama"
      },
      {
        id: "CL08108",
        code: "08108",
        name: "San Pedro de la Paz"
      },
      {
        id: "CL07110",
        code: "07110",
        name: "San Rafael"
      },
      {
        id: "CL13131",
        code: "13131",
        name: "San Ramón"
      },
      {
        id: "CL08310",
        code: "08310",
        name: "San Rosendo"
      },
      {
        id: "CL08311",
        code: "08311",
        name: "Santa Bárbara"
      },
      {
        id: "CL06310",
        code: "06310",
        name: "Santa Cruz"
      },
      {
        id: "CL08109",
        code: "08109",
        name: "Santa Juana"
      },
      {
        id: "CL05706",
        code: "05706",
        name: "Santa María"
      },
      {
        id: "CL13101",
        code: "13101",
        name: "Santiago"
      },
      {
        id: "CL05606",
        code: "05606",
        name: "Santo Domingo"
      },
      {
        id: "CL06117",
        code: "06117",
        name: "San Vicente"
      },
      {
        id: "CL02103",
        code: "02103",
        name: "Sierra Gorda"
      },
      {
        id: "CL13601",
        code: "13601",
        name: "Talagante"
      },
      {
        id: "CL07101",
        code: "07101",
        name: "Talca"
      },
      {
        id: "CL08110",
        code: "08110",
        name: "Talcahuano"
      },
      {
        id: "CL02104",
        code: "02104",
        name: "Taltal"
      },
      {
        id: "CL09101",
        code: "09101",
        name: "Temuco"
      },
      {
        id: "CL07308",
        code: "07308",
        name: "Teno"
      },
      {
        id: "CL09117",
        code: "09117",
        name: "Teodoro Schmidt"
      },
      {
        id: "CL03103",
        code: "03103",
        name: "Tierra Amarilla"
      },
      {
        id: "CL13303",
        code: "13303",
        name: "Tiltil"
      },
      {
        id: "CL12303",
        code: "12303",
        name: "Timaukel"
      },
      {
        id: "CL08207",
        code: "08207",
        name: "Tirúa"
      },
      {
        id: "CL02301",
        code: "02301",
        name: "Tocopilla"
      },
      {
        id: "CL09118",
        code: "09118",
        name: "Toltén"
      },
      {
        id: "CL08111",
        code: "08111",
        name: "Tomé"
      },
      {
        id: "CL12402",
        code: "12402",
        name: "Torres del Paine"
      },
      {
        id: "CL11303",
        code: "11303",
        name: "Tortel"
      },
      {
        id: "CL09210",
        code: "09210",
        name: "Traiguén"
      },
      {
        id: "CL16207",
        code: "16207",
        name: "Treguaco"
      },
      {
        id: "CL08312",
        code: "08312",
        name: "Tucapel"
      },
      {
        id: "CL14101",
        code: "14101",
        name: "Valdivia"
      },
      {
        id: "CL03301",
        code: "03301",
        name: "Vallenar"
      },
      {
        id: "CL05101",
        code: "05101",
        name: "Valparaíso"
      },
      {
        id: "CL07309",
        code: "07309",
        name: "Vichuquén"
      },
      {
        id: "CL09211",
        code: "09211",
        name: "Victoria"
      },
      {
        id: "CL04106",
        code: "04106",
        name: "Vicuña"
      },
      {
        id: "CL09119",
        code: "09119",
        name: "Vilcún"
      },
      {
        id: "CL07407",
        code: "07407",
        name: "Villa Alegre"
      },
      {
        id: "CL05804",
        code: "05804",
        name: "Villa Alemana"
      },
      {
        id: "CL09120",
        code: "09120",
        name: "Villarrica"
      },
      {
        id: "CL05109",
        code: "05109",
        name: "Viña del Mar"
      },
      {
        id: "CL13132",
        code: "13132",
        name: "Vitacura"
      },
      {
        id: "CL07408",
        code: "07408",
        name: "Yerbas Buenas"
      },
      {
        id: "CL08313",
        code: "08313",
        name: "Yumbel"
      },
      {
        id: "CL16109",
        code: "16109",
        name: "Yungay"
      },
      {
        id: "CL05405",
        code: "05405",
        name: "Zapallar"
      }
    ],
    name: "Chile",
    name_default: "Chile",
    phone_code: "+56",
    has_regulation: true,
    tax_percent: 19
  },
  {
    id: "PE",
    states: [
      {
        code: "010101",
        id: "PE010101",
        name: "CHACHAPOYAS"
      },
      {
        code: "010102",
        id: "PE010102",
        name: "ASUNCION"
      },
      {
        code: "010103",
        id: "PE010103",
        name: "BALSAS"
      },
      {
        code: "010104",
        id: "PE010104",
        name: "CHETO"
      },
      {
        code: "010105",
        id: "PE010105",
        name: "CHILIQUIN"
      },
      {
        code: "010106",
        id: "PE010106",
        name: "CHUQUIBAMBA"
      },
      {
        code: "010107",
        id: "PE010107",
        name: "GRANADA"
      },
      {
        code: "010108",
        id: "PE010108",
        name: "HUANCAS"
      },
      {
        code: "010109",
        id: "PE010109",
        name: "LA JALCA"
      },
      {
        code: "010110",
        id: "PE010110",
        name: "LEIMEBAMBA"
      },
      {
        code: "010111",
        id: "PE010111",
        name: "LEVANTO"
      },
      {
        code: "010112",
        id: "PE010112",
        name: "MAGDALENA"
      },
      {
        code: "010113",
        id: "PE010113",
        name: "MARISCAL CASTILLA"
      },
      {
        code: "010114",
        id: "PE010114",
        name: "MOLINOPAMPA"
      },
      {
        code: "010115",
        id: "PE010115",
        name: "MONTEVIDEO"
      },
      {
        code: "010116",
        id: "PE010116",
        name: "OLLEROS"
      },
      {
        code: "010117",
        id: "PE010117",
        name: "QUINJALCA"
      },
      {
        code: "010118",
        id: "PE010118",
        name: "SAN FRANCISCO DE DAGUAS"
      },
      {
        code: "010119",
        id: "PE010119",
        name: "SAN ISIDRO DE MAINO"
      },
      {
        code: "010120",
        id: "PE010120",
        name: "SOLOCO"
      },
      {
        code: "010121",
        id: "PE010121",
        name: "SONCHE"
      },
      {
        code: "010201",
        id: "PE010201",
        name: "BAGUA"
      },
      {
        code: "010202",
        id: "PE010202",
        name: "ARAMANGO"
      },
      {
        code: "010203",
        id: "PE010203",
        name: "COPALLIN"
      },
      {
        code: "010204",
        id: "PE010204",
        name: "EL PARCO"
      },
      {
        code: "010205",
        id: "PE010205",
        name: "IMAZA"
      },
      {
        code: "010206",
        id: "PE010206",
        name: "LA PECA"
      },
      {
        code: "010301",
        id: "PE010301",
        name: "JUMBILLA"
      },
      {
        code: "010302",
        id: "PE010302",
        name: "CHISQUILLA"
      },
      {
        code: "010303",
        id: "PE010303",
        name: "CHURUJA"
      },
      {
        code: "010304",
        id: "PE010304",
        name: "COROSHA"
      },
      {
        code: "010305",
        id: "PE010305",
        name: "CUISPES"
      },
      {
        code: "010306",
        id: "PE010306",
        name: "FLORIDA"
      },
      {
        code: "010307",
        id: "PE010307",
        name: "JAZAN"
      },
      {
        code: "010308",
        id: "PE010308",
        name: "RECTA"
      },
      {
        code: "010309",
        id: "PE010309",
        name: "SAN CARLOS"
      },
      {
        code: "010310",
        id: "PE010310",
        name: "SHIPASBAMBA"
      },
      {
        code: "010311",
        id: "PE010311",
        name: "VALERA"
      },
      {
        code: "010312",
        id: "PE010312",
        name: "YAMBRASBAMBA"
      },
      {
        code: "010401",
        id: "PE010401",
        name: "NIEVA"
      },
      {
        code: "010402",
        id: "PE010402",
        name: "EL CENEPA"
      },
      {
        code: "010403",
        id: "PE010403",
        name: "RIO SANTIAGO"
      },
      {
        code: "010501",
        id: "PE010501",
        name: "LAMUD"
      },
      {
        code: "010502",
        id: "PE010502",
        name: "CAMPORREDONDO"
      },
      {
        code: "010503",
        id: "PE010503",
        name: "COCABAMBA"
      },
      {
        code: "010504",
        id: "PE010504",
        name: "COLCAMAR"
      },
      {
        code: "010505",
        id: "PE010505",
        name: "CONILA"
      },
      {
        code: "010506",
        id: "PE010506",
        name: "INGUILPATA"
      },
      {
        code: "010507",
        id: "PE010507",
        name: "LONGUITA"
      },
      {
        code: "010508",
        id: "PE010508",
        name: "LONYA CHICO"
      },
      {
        code: "010509",
        id: "PE010509",
        name: "LUYA"
      },
      {
        code: "010510",
        id: "PE010510",
        name: "LUYA VIEJO"
      },
      {
        code: "010511",
        id: "PE010511",
        name: "MARIA"
      },
      {
        code: "010512",
        id: "PE010512",
        name: "OCALLI"
      },
      {
        code: "010513",
        id: "PE010513",
        name: "OCUMAL"
      },
      {
        code: "010514",
        id: "PE010514",
        name: "PISUQUIA"
      },
      {
        code: "010515",
        id: "PE010515",
        name: "PROVIDENCIA"
      },
      {
        code: "010516",
        id: "PE010516",
        name: "SAN CRISTOBAL"
      },
      {
        code: "010517",
        id: "PE010517",
        name: "SAN FRANCISCO DEL YESO"
      },
      {
        code: "010518",
        id: "PE010518",
        name: "SAN JERONIMO"
      },
      {
        code: "010519",
        id: "PE010519",
        name: "SAN JUAN DE LOPECANCHA"
      },
      {
        code: "010520",
        id: "PE010520",
        name: "SANTA CATALINA"
      },
      {
        code: "010521",
        id: "PE010521",
        name: "SANTO TOMAS"
      },
      {
        code: "010522",
        id: "PE010522",
        name: "TINGO"
      },
      {
        code: "010523",
        id: "PE010523",
        name: "TRITA"
      },
      {
        code: "010601",
        id: "PE010601",
        name: "SAN NICOLAS"
      },
      {
        code: "010602",
        id: "PE010602",
        name: "CHIRIMOTO"
      },
      {
        code: "010603",
        id: "PE010603",
        name: "COCHAMAL"
      },
      {
        code: "010604",
        id: "PE010604",
        name: "HUAMBO"
      },
      {
        code: "010605",
        id: "PE010605",
        name: "LIMABAMBA"
      },
      {
        code: "010606",
        id: "PE010606",
        name: "LONGAR"
      },
      {
        code: "010607",
        id: "PE010607",
        name: "MARISCAL BENAVIDES"
      },
      {
        code: "010608",
        id: "PE010608",
        name: "MILPUC"
      },
      {
        code: "010609",
        id: "PE010609",
        name: "OMIA"
      },
      {
        code: "010610",
        id: "PE010610",
        name: "SANTA ROSA"
      },
      {
        code: "010611",
        id: "PE010611",
        name: "TOTORA"
      },
      {
        code: "010612",
        id: "PE010612",
        name: "VISTA ALEGRE"
      },
      {
        code: "010701",
        id: "PE010701",
        name: "BAGUA GRANDE"
      },
      {
        code: "010702",
        id: "PE010702",
        name: "CAJARURO"
      },
      {
        code: "010703",
        id: "PE010703",
        name: "CUMBA"
      },
      {
        code: "010704",
        id: "PE010704",
        name: "EL MILAGRO"
      },
      {
        code: "010705",
        id: "PE010705",
        name: "JAMALCA"
      },
      {
        code: "010706",
        id: "PE010706",
        name: "LONYA GRANDE"
      },
      {
        code: "010707",
        id: "PE010707",
        name: "YAMON"
      },
      {
        code: "020101",
        id: "PE020101",
        name: "HUARAZ"
      },
      {
        code: "020102",
        id: "PE020102",
        name: "COCHABAMBA"
      },
      {
        code: "020103",
        id: "PE020103",
        name: "COLCABAMBA"
      },
      {
        code: "020104",
        id: "PE020104",
        name: "HUANCHAY"
      },
      {
        code: "020105",
        id: "PE020105",
        name: "INDEPENDENCIA"
      },
      {
        code: "020106",
        id: "PE020106",
        name: "JANGAS"
      },
      {
        code: "020107",
        id: "PE020107",
        name: "LA LIBERTAD"
      },
      {
        code: "020108",
        id: "PE020108",
        name: "OLLEROS"
      },
      {
        code: "020109",
        id: "PE020109",
        name: "PAMPAS GRANDE"
      },
      {
        code: "020110",
        id: "PE020110",
        name: "PARIACOTO"
      },
      {
        code: "020111",
        id: "PE020111",
        name: "PIRA"
      },
      {
        code: "020112",
        id: "PE020112",
        name: "TARICA"
      },
      {
        code: "020201",
        id: "PE020201",
        name: "AIJA"
      },
      {
        code: "020202",
        id: "PE020202",
        name: "CORIS"
      },
      {
        code: "020203",
        id: "PE020203",
        name: "HUACLLAN"
      },
      {
        code: "020204",
        id: "PE020204",
        name: "LA MERCED"
      },
      {
        code: "020205",
        id: "PE020205",
        name: "SUCCHA"
      },
      {
        code: "020301",
        id: "PE020301",
        name: "LLAMELLIN"
      },
      {
        code: "020302",
        id: "PE020302",
        name: "ACZO"
      },
      {
        code: "020303",
        id: "PE020303",
        name: "CHACCHO"
      },
      {
        code: "020304",
        id: "PE020304",
        name: "CHINGAS"
      },
      {
        code: "020305",
        id: "PE020305",
        name: "MIRGAS"
      },
      {
        code: "020306",
        id: "PE020306",
        name: "SAN JUAN DE RONTOY"
      },
      {
        code: "020401",
        id: "PE020401",
        name: "CHACAS"
      },
      {
        code: "020402",
        id: "PE020402",
        name: "ACOCHACA"
      },
      {
        code: "020501",
        id: "PE020501",
        name: "CHIQUIAN"
      },
      {
        code: "020502",
        id: "PE020502",
        name: "ABELARDO PARDO LEZAMETA"
      },
      {
        code: "020503",
        id: "PE020503",
        name: "ANTONIO RAYMONDI"
      },
      {
        code: "020504",
        id: "PE020504",
        name: "AQUIA"
      },
      {
        code: "020505",
        id: "PE020505",
        name: "CAJACAY"
      },
      {
        code: "020506",
        id: "PE020506",
        name: "CANIS"
      },
      {
        code: "020507",
        id: "PE020507",
        name: "COLQUIOC"
      },
      {
        code: "020508",
        id: "PE020508",
        name: "HUALLANCA"
      },
      {
        code: "020509",
        id: "PE020509",
        name: "HUASTA"
      },
      {
        code: "020510",
        id: "PE020510",
        name: "HUAYLLACAYAN"
      },
      {
        code: "020511",
        id: "PE020511",
        name: "LA PRIMAVERA"
      },
      {
        code: "020512",
        id: "PE020512",
        name: "MANGAS"
      },
      {
        code: "020513",
        id: "PE020513",
        name: "PACLLON"
      },
      {
        code: "020514",
        id: "PE020514",
        name: "SAN MIGUEL DE CORPANQUI"
      },
      {
        code: "020515",
        id: "PE020515",
        name: "TICLLOS"
      },
      {
        code: "020601",
        id: "PE020601",
        name: "CARHUAZ"
      },
      {
        code: "020602",
        id: "PE020602",
        name: "ACOPAMPA"
      },
      {
        code: "020603",
        id: "PE020603",
        name: "AMASHCA"
      },
      {
        code: "020604",
        id: "PE020604",
        name: "ANTA"
      },
      {
        code: "020605",
        id: "PE020605",
        name: "ATAQUERO"
      },
      {
        code: "020606",
        id: "PE020606",
        name: "MARCARA"
      },
      {
        code: "020607",
        id: "PE020607",
        name: "PARIAHUANCA"
      },
      {
        code: "020608",
        id: "PE020608",
        name: "SAN MIGUEL DE ACO"
      },
      {
        code: "020609",
        id: "PE020609",
        name: "SHILLA"
      },
      {
        code: "020610",
        id: "PE020610",
        name: "TINCO"
      },
      {
        code: "020611",
        id: "PE020611",
        name: "YUNGAR"
      },
      {
        code: "020701",
        id: "PE020701",
        name: "SAN LUIS"
      },
      {
        code: "020702",
        id: "PE020702",
        name: "SAN NICOLAS"
      },
      {
        code: "020703",
        id: "PE020703",
        name: "YAUYA"
      },
      {
        code: "020801",
        id: "PE020801",
        name: "CASMA"
      },
      {
        code: "020802",
        id: "PE020802",
        name: "BUENA VISTA ALTA"
      },
      {
        code: "020803",
        id: "PE020803",
        name: "COMANDANTE NOEL"
      },
      {
        code: "020804",
        id: "PE020804",
        name: "YAUTAN"
      },
      {
        code: "020901",
        id: "PE020901",
        name: "CORONGO"
      },
      {
        code: "020902",
        id: "PE020902",
        name: "ACO"
      },
      {
        code: "020903",
        id: "PE020903",
        name: "BAMBAS"
      },
      {
        code: "020904",
        id: "PE020904",
        name: "CUSCA"
      },
      {
        code: "020905",
        id: "PE020905",
        name: "LA PAMPA"
      },
      {
        code: "020906",
        id: "PE020906",
        name: "YANAC"
      },
      {
        code: "020907",
        id: "PE020907",
        name: "YUPAN"
      },
      {
        code: "021001",
        id: "PE021001",
        name: "HUARI"
      },
      {
        code: "021002",
        id: "PE021002",
        name: "ANRA"
      },
      {
        code: "021003",
        id: "PE021003",
        name: "CAJAY"
      },
      {
        code: "021004",
        id: "PE021004",
        name: "CHAVIN DE HUANTAR"
      },
      {
        code: "021005",
        id: "PE021005",
        name: "HUACACHI"
      },
      {
        code: "021006",
        id: "PE021006",
        name: "HUACCHIS"
      },
      {
        code: "021007",
        id: "PE021007",
        name: "HUACHIS"
      },
      {
        code: "021008",
        id: "PE021008",
        name: "HUANTAR"
      },
      {
        code: "021009",
        id: "PE021009",
        name: "MASIN"
      },
      {
        code: "021010",
        id: "PE021010",
        name: "PAUCAS"
      },
      {
        code: "021011",
        id: "PE021011",
        name: "PONTO"
      },
      {
        code: "021012",
        id: "PE021012",
        name: "RAHUAPAMPA"
      },
      {
        code: "021013",
        id: "PE021013",
        name: "RAPAYAN"
      },
      {
        code: "021014",
        id: "PE021014",
        name: "SAN MARCOS"
      },
      {
        code: "021015",
        id: "PE021015",
        name: "SAN PEDRO DE CHANA"
      },
      {
        code: "021016",
        id: "PE021016",
        name: "UCO"
      },
      {
        code: "021101",
        id: "PE021101",
        name: "HUARMEY"
      },
      {
        code: "021102",
        id: "PE021102",
        name: "COCHAPETI"
      },
      {
        code: "021103",
        id: "PE021103",
        name: "CULEBRAS"
      },
      {
        code: "021104",
        id: "PE021104",
        name: "HUAYAN"
      },
      {
        code: "021105",
        id: "PE021105",
        name: "MALVAS"
      },
      {
        code: "021201",
        id: "PE021201",
        name: "CARAZ"
      },
      {
        code: "021202",
        id: "PE021202",
        name: "HUALLANCA"
      },
      {
        code: "021203",
        id: "PE021203",
        name: "HUATA"
      },
      {
        code: "021204",
        id: "PE021204",
        name: "HUAYLAS"
      },
      {
        code: "021205",
        id: "PE021205",
        name: "MATO"
      },
      {
        code: "021206",
        id: "PE021206",
        name: "PAMPAROMAS"
      },
      {
        code: "021207",
        id: "PE021207",
        name: "PUEBLO LIBRE"
      },
      {
        code: "021208",
        id: "PE021208",
        name: "SANTA CRUZ"
      },
      {
        code: "021209",
        id: "PE021209",
        name: "SANTO TORIBIO"
      },
      {
        code: "021210",
        id: "PE021210",
        name: "YURACMARCA"
      },
      {
        code: "021301",
        id: "PE021301",
        name: "PISCOBAMBA"
      },
      {
        code: "021302",
        id: "PE021302",
        name: "CASCA"
      },
      {
        code: "021303",
        id: "PE021303",
        name: "ELEAZAR GUZMAN BARRON"
      },
      {
        code: "021304",
        id: "PE021304",
        name: "FIDEL OLIVAS ESCUDERO"
      },
      {
        code: "021305",
        id: "PE021305",
        name: "LLAMA"
      },
      {
        code: "021306",
        id: "PE021306",
        name: "LLUMPA"
      },
      {
        code: "021307",
        id: "PE021307",
        name: "LUCMA"
      },
      {
        code: "021308",
        id: "PE021308",
        name: "MUSGA"
      },
      {
        code: "021401",
        id: "PE021401",
        name: "OCROS"
      },
      {
        code: "021402",
        id: "PE021402",
        name: "ACAS"
      },
      {
        code: "021403",
        id: "PE021403",
        name: "CAJAMARQUILLA"
      },
      {
        code: "021404",
        id: "PE021404",
        name: "CARHUAPAMPA"
      },
      {
        code: "021405",
        id: "PE021405",
        name: "COCHAS"
      },
      {
        code: "021406",
        id: "PE021406",
        name: "CONGAS"
      },
      {
        code: "021407",
        id: "PE021407",
        name: "LLIPA"
      },
      {
        code: "021408",
        id: "PE021408",
        name: "SAN CRISTOBAL DE RAJAN"
      },
      {
        code: "021409",
        id: "PE021409",
        name: "SAN PEDRO"
      },
      {
        code: "021410",
        id: "PE021410",
        name: "SANTIAGO DE CHILCAS"
      },
      {
        code: "021501",
        id: "PE021501",
        name: "CABANA"
      },
      {
        code: "021502",
        id: "PE021502",
        name: "BOLOGNESI"
      },
      {
        code: "021503",
        id: "PE021503",
        name: "CONCHUCOS"
      },
      {
        code: "021504",
        id: "PE021504",
        name: "HUACASCHUQUE"
      },
      {
        code: "021505",
        id: "PE021505",
        name: "HUANDOVAL"
      },
      {
        code: "021506",
        id: "PE021506",
        name: "LACABAMBA"
      },
      {
        code: "021507",
        id: "PE021507",
        name: "LLAPO"
      },
      {
        code: "021508",
        id: "PE021508",
        name: "PALLASCA"
      },
      {
        code: "021509",
        id: "PE021509",
        name: "PAMPAS"
      },
      {
        code: "021510",
        id: "PE021510",
        name: "SANTA ROSA"
      },
      {
        code: "021511",
        id: "PE021511",
        name: "TAUCA"
      },
      {
        code: "021601",
        id: "PE021601",
        name: "POMABAMBA"
      },
      {
        code: "021602",
        id: "PE021602",
        name: "HUAYLLAN"
      },
      {
        code: "021603",
        id: "PE021603",
        name: "PAROBAMBA"
      },
      {
        code: "021604",
        id: "PE021604",
        name: "QUINUABAMBA"
      },
      {
        code: "021701",
        id: "PE021701",
        name: "RECUAY"
      },
      {
        code: "021702",
        id: "PE021702",
        name: "CATAC"
      },
      {
        code: "021703",
        id: "PE021703",
        name: "COTAPARACO"
      },
      {
        code: "021704",
        id: "PE021704",
        name: "HUAYLLAPAMPA"
      },
      {
        code: "021705",
        id: "PE021705",
        name: "LLACLLIN"
      },
      {
        code: "021706",
        id: "PE021706",
        name: "MARCA"
      },
      {
        code: "021707",
        id: "PE021707",
        name: "PAMPAS CHICO"
      },
      {
        code: "021708",
        id: "PE021708",
        name: "PARARIN"
      },
      {
        code: "021709",
        id: "PE021709",
        name: "TAPACOCHA"
      },
      {
        code: "021710",
        id: "PE021710",
        name: "TICAPAMPA"
      },
      {
        code: "021801",
        id: "PE021801",
        name: "CHIMBOTE"
      },
      {
        code: "021802",
        id: "PE021802",
        name: "CACERES DEL PERU"
      },
      {
        code: "021803",
        id: "PE021803",
        name: "COISHCO"
      },
      {
        code: "021804",
        id: "PE021804",
        name: "MACATE"
      },
      {
        code: "021805",
        id: "PE021805",
        name: "MORO"
      },
      {
        code: "021806",
        id: "PE021806",
        name: "NEPEÑA"
      },
      {
        code: "021807",
        id: "PE021807",
        name: "SAMANCO"
      },
      {
        code: "021808",
        id: "PE021808",
        name: "SANTA"
      },
      {
        code: "021809",
        id: "PE021809",
        name: "NUEVO CHIMBOTE"
      },
      {
        code: "021901",
        id: "PE021901",
        name: "SIHUAS"
      },
      {
        code: "021902",
        id: "PE021902",
        name: "ACOBAMBA"
      },
      {
        code: "021903",
        id: "PE021903",
        name: "ALFONSO UGARTE"
      },
      {
        code: "021904",
        id: "PE021904",
        name: "CASHAPAMPA"
      },
      {
        code: "021905",
        id: "PE021905",
        name: "CHINGALPO"
      },
      {
        code: "021906",
        id: "PE021906",
        name: "HUAYLLABAMBA"
      },
      {
        code: "021907",
        id: "PE021907",
        name: "QUICHES"
      },
      {
        code: "021908",
        id: "PE021908",
        name: "RAGASH"
      },
      {
        code: "021909",
        id: "PE021909",
        name: "SAN JUAN"
      },
      {
        code: "021910",
        id: "PE021910",
        name: "SICSIBAMBA"
      },
      {
        code: "022001",
        id: "PE022001",
        name: "YUNGAY"
      },
      {
        code: "022002",
        id: "PE022002",
        name: "CASCAPARA"
      },
      {
        code: "022003",
        id: "PE022003",
        name: "MANCOS"
      },
      {
        code: "022004",
        id: "PE022004",
        name: "MATACOTO"
      },
      {
        code: "022005",
        id: "PE022005",
        name: "QUILLO"
      },
      {
        code: "022006",
        id: "PE022006",
        name: "RANRAHIRCA"
      },
      {
        code: "022007",
        id: "PE022007",
        name: "SHUPLUY"
      },
      {
        code: "022008",
        id: "PE022008",
        name: "YANAMA"
      },
      {
        code: "030101",
        id: "PE030101",
        name: "ABANCAY"
      },
      {
        code: "030102",
        id: "PE030102",
        name: "CHACOCHE"
      },
      {
        code: "030103",
        id: "PE030103",
        name: "CIRCA"
      },
      {
        code: "030104",
        id: "PE030104",
        name: "CURAHUASI"
      },
      {
        code: "030105",
        id: "PE030105",
        name: "HUANIPACA"
      },
      {
        code: "030106",
        id: "PE030106",
        name: "LAMBRAMA"
      },
      {
        code: "030107",
        id: "PE030107",
        name: "PICHIRHUA"
      },
      {
        code: "030108",
        id: "PE030108",
        name: "SAN PEDRO DE CACHORA"
      },
      {
        code: "030109",
        id: "PE030109",
        name: "TAMBURCO"
      },
      {
        code: "030201",
        id: "PE030201",
        name: "ANDAHUAYLAS"
      },
      {
        code: "030202",
        id: "PE030202",
        name: "ANDARAPA"
      },
      {
        code: "030203",
        id: "PE030203",
        name: "CHIARA"
      },
      {
        code: "030204",
        id: "PE030204",
        name: "HUANCARAMA"
      },
      {
        code: "030205",
        id: "PE030205",
        name: "HUANCARAY"
      },
      {
        code: "030206",
        id: "PE030206",
        name: "HUAYANA"
      },
      {
        code: "030207",
        id: "PE030207",
        name: "KISHUARA"
      },
      {
        code: "030208",
        id: "PE030208",
        name: "PACOBAMBA"
      },
      {
        code: "030209",
        id: "PE030209",
        name: "PACUCHA"
      },
      {
        code: "030210",
        id: "PE030210",
        name: "PAMPACHIRI"
      },
      {
        code: "030211",
        id: "PE030211",
        name: "POMACOCHA"
      },
      {
        code: "030212",
        id: "PE030212",
        name: "SAN ANTONIO DE CACHI"
      },
      {
        code: "030213",
        id: "PE030213",
        name: "SAN JERONIMO"
      },
      {
        code: "030214",
        id: "PE030214",
        name: "SAN MIGUEL DE CHACCRAMPA"
      },
      {
        code: "030215",
        id: "PE030215",
        name: "SANTA MARIA DE CHICMO"
      },
      {
        code: "030216",
        id: "PE030216",
        name: "TALAVERA"
      },
      {
        code: "030217",
        id: "PE030217",
        name: "TUMAY HUARACA"
      },
      {
        code: "030218",
        id: "PE030218",
        name: "TURPO"
      },
      {
        code: "030219",
        id: "PE030219",
        name: "KAQUIABAMBA"
      },
      {
        code: "030220",
        id: "PE030220",
        name: "JOSE MARIA ARGUEDAS"
      },
      {
        code: "030301",
        id: "PE030301",
        name: "ANTABAMBA"
      },
      {
        code: "030302",
        id: "PE030302",
        name: "EL ORO"
      },
      {
        code: "030303",
        id: "PE030303",
        name: "HUAQUIRCA"
      },
      {
        code: "030304",
        id: "PE030304",
        name: "JUAN ESPINOZA MEDRANO"
      },
      {
        code: "030305",
        id: "PE030305",
        name: "OROPESA"
      },
      {
        code: "030306",
        id: "PE030306",
        name: "PACHACONAS"
      },
      {
        code: "030307",
        id: "PE030307",
        name: "SABAINO"
      },
      {
        code: "030401",
        id: "PE030401",
        name: "CHALHUANCA"
      },
      {
        code: "030402",
        id: "PE030402",
        name: "CAPAYA"
      },
      {
        code: "030403",
        id: "PE030403",
        name: "CARAYBAMBA"
      },
      {
        code: "030404",
        id: "PE030404",
        name: "CHAPIMARCA"
      },
      {
        code: "030405",
        id: "PE030405",
        name: "COLCABAMBA"
      },
      {
        code: "030406",
        id: "PE030406",
        name: "COTARUSE"
      },
      {
        code: "030407",
        id: "PE030407",
        name: "IHUAYLLO"
      },
      {
        code: "030408",
        id: "PE030408",
        name: "JUSTO APU SAHUARAURA"
      },
      {
        code: "030409",
        id: "PE030409",
        name: "LUCRE"
      },
      {
        code: "030410",
        id: "PE030410",
        name: "POCOHUANCA"
      },
      {
        code: "030411",
        id: "PE030411",
        name: "SAN JUAN DE CHACÑA"
      },
      {
        code: "030412",
        id: "PE030412",
        name: "SAÑAYCA"
      },
      {
        code: "030413",
        id: "PE030413",
        name: "SORAYA"
      },
      {
        code: "030414",
        id: "PE030414",
        name: "TAPAIRIHUA"
      },
      {
        code: "030415",
        id: "PE030415",
        name: "TINTAY"
      },
      {
        code: "030416",
        id: "PE030416",
        name: "TORAYA"
      },
      {
        code: "030417",
        id: "PE030417",
        name: "YANACA"
      },
      {
        code: "030501",
        id: "PE030501",
        name: "TAMBOBAMBA"
      },
      {
        code: "030502",
        id: "PE030502",
        name: "COTABAMBAS"
      },
      {
        code: "030503",
        id: "PE030503",
        name: "COYLLURQUI"
      },
      {
        code: "030504",
        id: "PE030504",
        name: "HAQUIRA"
      },
      {
        code: "030505",
        id: "PE030505",
        name: "MARA"
      },
      {
        code: "030506",
        id: "PE030506",
        name: "CHALLHUAHUACHO"
      },
      {
        code: "030601",
        id: "PE030601",
        name: "CHINCHEROS"
      },
      {
        code: "030602",
        id: "PE030602",
        name: "ANCO_HUALLO"
      },
      {
        code: "030603",
        id: "PE030603",
        name: "COCHARCAS"
      },
      {
        code: "030604",
        id: "PE030604",
        name: "HUACCANA"
      },
      {
        code: "030605",
        id: "PE030605",
        name: "OCOBAMBA"
      },
      {
        code: "030606",
        id: "PE030606",
        name: "ONGOY"
      },
      {
        code: "030607",
        id: "PE030607",
        name: "URANMARCA"
      },
      {
        code: "030608",
        id: "PE030608",
        name: "RANRACANCHA"
      },
      {
        code: "030609",
        id: "PE030609",
        name: "ROCCHACC"
      },
      {
        code: "030610",
        id: "PE030610",
        name: "EL PORVENIR"
      },
      {
        code: "030611",
        id: "PE030611",
        name: "LOS CHANKAS"
      },
      {
        code: "030701",
        id: "PE030701",
        name: "CHUQUIBAMBILLA"
      },
      {
        code: "030702",
        id: "PE030702",
        name: "CURPAHUASI"
      },
      {
        code: "030703",
        id: "PE030703",
        name: "GAMARRA"
      },
      {
        code: "030704",
        id: "PE030704",
        name: "HUAYLLATI"
      },
      {
        code: "030705",
        id: "PE030705",
        name: "MAMARA"
      },
      {
        code: "030706",
        id: "PE030706",
        name: "MICAELA BASTIDAS"
      },
      {
        code: "030707",
        id: "PE030707",
        name: "PATAYPAMPA"
      },
      {
        code: "030708",
        id: "PE030708",
        name: "PROGRESO"
      },
      {
        code: "030709",
        id: "PE030709",
        name: "SAN ANTONIO"
      },
      {
        code: "030710",
        id: "PE030710",
        name: "SANTA ROSA"
      },
      {
        code: "030711",
        id: "PE030711",
        name: "TURPAY"
      },
      {
        code: "030712",
        id: "PE030712",
        name: "VILCABAMBA"
      },
      {
        code: "030713",
        id: "PE030713",
        name: "VIRUNDO"
      },
      {
        code: "030714",
        id: "PE030714",
        name: "CURASCO"
      },
      {
        code: "040101",
        id: "PE040101",
        name: "AREQUIPA"
      },
      {
        code: "040102",
        id: "PE040102",
        name: "ALTO SELVA ALEGRE"
      },
      {
        code: "040103",
        id: "PE040103",
        name: "CAYMA"
      },
      {
        code: "040104",
        id: "PE040104",
        name: "CERRO COLORADO"
      },
      {
        code: "040105",
        id: "PE040105",
        name: "CHARACATO"
      },
      {
        code: "040106",
        id: "PE040106",
        name: "CHIGUATA"
      },
      {
        code: "040107",
        id: "PE040107",
        name: "JACOBO HUNTER"
      },
      {
        code: "040108",
        id: "PE040108",
        name: "LA JOYA"
      },
      {
        code: "040109",
        id: "PE040109",
        name: "MARIANO MELGAR"
      },
      {
        code: "040110",
        id: "PE040110",
        name: "MIRAFLORES"
      },
      {
        code: "040111",
        id: "PE040111",
        name: "MOLLEBAYA"
      },
      {
        code: "040112",
        id: "PE040112",
        name: "PAUCARPATA"
      },
      {
        code: "040113",
        id: "PE040113",
        name: "POCSI"
      },
      {
        code: "040114",
        id: "PE040114",
        name: "POLOBAYA"
      },
      {
        code: "040115",
        id: "PE040115",
        name: "QUEQUEÑA"
      },
      {
        code: "040116",
        id: "PE040116",
        name: "SABANDIA"
      },
      {
        code: "040117",
        id: "PE040117",
        name: "SACHACA"
      },
      {
        code: "040118",
        id: "PE040118",
        name: "SAN JUAN DE SIGUAS"
      },
      {
        code: "040119",
        id: "PE040119",
        name: "SAN JUAN DE TARUCANI"
      },
      {
        code: "040120",
        id: "PE040120",
        name: "SANTA ISABEL DE SIGUAS"
      },
      {
        code: "040121",
        id: "PE040121",
        name: "SANTA RITA DE SIGUAS"
      },
      {
        code: "040122",
        id: "PE040122",
        name: "SOCABAYA"
      },
      {
        code: "040123",
        id: "PE040123",
        name: "TIABAYA"
      },
      {
        code: "040124",
        id: "PE040124",
        name: "UCHUMAYO"
      },
      {
        code: "040125",
        id: "PE040125",
        name: "VITOR"
      },
      {
        code: "040126",
        id: "PE040126",
        name: "YANAHUARA"
      },
      {
        code: "040127",
        id: "PE040127",
        name: "YARABAMBA"
      },
      {
        code: "040128",
        id: "PE040128",
        name: "YURA"
      },
      {
        code: "040129",
        id: "PE040129",
        name: "JOSE LUIS BUSTAMANTE Y RIVERO"
      },
      {
        code: "040201",
        id: "PE040201",
        name: "CAMANA"
      },
      {
        code: "040202",
        id: "PE040202",
        name: "JOSE MARIA QUIMPER"
      },
      {
        code: "040203",
        id: "PE040203",
        name: "MARIANO NICOLAS VALCARCEL"
      },
      {
        code: "040204",
        id: "PE040204",
        name: "MARISCAL CACERES"
      },
      {
        code: "040205",
        id: "PE040205",
        name: "NICOLAS DE PIEROLA"
      },
      {
        code: "040206",
        id: "PE040206",
        name: "OCOÑA"
      },
      {
        code: "040207",
        id: "PE040207",
        name: "QUILCA"
      },
      {
        code: "040208",
        id: "PE040208",
        name: "SAMUEL PASTOR"
      },
      {
        code: "040301",
        id: "PE040301",
        name: "CARAVELI"
      },
      {
        code: "040302",
        id: "PE040302",
        name: "ACARI"
      },
      {
        code: "040303",
        id: "PE040303",
        name: "ATICO"
      },
      {
        code: "040304",
        id: "PE040304",
        name: "ATIQUIPA"
      },
      {
        code: "040305",
        id: "PE040305",
        name: "BELLA UNION"
      },
      {
        code: "040306",
        id: "PE040306",
        name: "CAHUACHO"
      },
      {
        code: "040307",
        id: "PE040307",
        name: "CHALA"
      },
      {
        code: "040308",
        id: "PE040308",
        name: "CHAPARRA"
      },
      {
        code: "040309",
        id: "PE040309",
        name: "HUANUHUANU"
      },
      {
        code: "040310",
        id: "PE040310",
        name: "JAQUI"
      },
      {
        code: "040311",
        id: "PE040311",
        name: "LOMAS"
      },
      {
        code: "040312",
        id: "PE040312",
        name: "QUICACHA"
      },
      {
        code: "040313",
        id: "PE040313",
        name: "YAUCA"
      },
      {
        code: "040401",
        id: "PE040401",
        name: "APLAO"
      },
      {
        code: "040402",
        id: "PE040402",
        name: "ANDAGUA"
      },
      {
        code: "040403",
        id: "PE040403",
        name: "AYO"
      },
      {
        code: "040404",
        id: "PE040404",
        name: "CHACHAS"
      },
      {
        code: "040405",
        id: "PE040405",
        name: "CHILCAYMARCA"
      },
      {
        code: "040406",
        id: "PE040406",
        name: "CHOCO"
      },
      {
        code: "040407",
        id: "PE040407",
        name: "HUANCARQUI"
      },
      {
        code: "040408",
        id: "PE040408",
        name: "MACHAGUAY"
      },
      {
        code: "040409",
        id: "PE040409",
        name: "ORCOPAMPA"
      },
      {
        code: "040410",
        id: "PE040410",
        name: "PAMPACOLCA"
      },
      {
        code: "040411",
        id: "PE040411",
        name: "TIPAN"
      },
      {
        code: "040412",
        id: "PE040412",
        name: "UÑON"
      },
      {
        code: "040413",
        id: "PE040413",
        name: "URACA"
      },
      {
        code: "040414",
        id: "PE040414",
        name: "VIRACO"
      },
      {
        code: "040501",
        id: "PE040501",
        name: "CHIVAY"
      },
      {
        code: "040502",
        id: "PE040502",
        name: "ACHOMA"
      },
      {
        code: "040503",
        id: "PE040503",
        name: "CABANACONDE"
      },
      {
        code: "040504",
        id: "PE040504",
        name: "CALLALLI"
      },
      {
        code: "040505",
        id: "PE040505",
        name: "CAYLLOMA"
      },
      {
        code: "040506",
        id: "PE040506",
        name: "COPORAQUE"
      },
      {
        code: "040507",
        id: "PE040507",
        name: "HUAMBO"
      },
      {
        code: "040508",
        id: "PE040508",
        name: "HUANCA"
      },
      {
        code: "040509",
        id: "PE040509",
        name: "ICHUPAMPA"
      },
      {
        code: "040510",
        id: "PE040510",
        name: "LARI"
      },
      {
        code: "040511",
        id: "PE040511",
        name: "LLUTA"
      },
      {
        code: "040512",
        id: "PE040512",
        name: "MACA"
      },
      {
        code: "040513",
        id: "PE040513",
        name: "MADRIGAL"
      },
      {
        code: "040514",
        id: "PE040514",
        name: "SAN ANTONIO DE CHUCA"
      },
      {
        code: "040515",
        id: "PE040515",
        name: "SIBAYO"
      },
      {
        code: "040516",
        id: "PE040516",
        name: "TAPAY"
      },
      {
        code: "040517",
        id: "PE040517",
        name: "TISCO"
      },
      {
        code: "040518",
        id: "PE040518",
        name: "TUTI"
      },
      {
        code: "040519",
        id: "PE040519",
        name: "YANQUE"
      },
      {
        code: "040520",
        id: "PE040520",
        name: "MAJES"
      },
      {
        code: "040601",
        id: "PE040601",
        name: "CHUQUIBAMBA"
      },
      {
        code: "040602",
        id: "PE040602",
        name: "ANDARAY"
      },
      {
        code: "040603",
        id: "PE040603",
        name: "CAYARANI"
      },
      {
        code: "040604",
        id: "PE040604",
        name: "CHICHAS"
      },
      {
        code: "040605",
        id: "PE040605",
        name: "IRAY"
      },
      {
        code: "040606",
        id: "PE040606",
        name: "RIO GRANDE"
      },
      {
        code: "040607",
        id: "PE040607",
        name: "SALAMANCA"
      },
      {
        code: "040608",
        id: "PE040608",
        name: "YANAQUIHUA"
      },
      {
        code: "040701",
        id: "PE040701",
        name: "MOLLENDO"
      },
      {
        code: "040702",
        id: "PE040702",
        name: "COCACHACRA"
      },
      {
        code: "040703",
        id: "PE040703",
        name: "DEAN VALDIVIA"
      },
      {
        code: "040704",
        id: "PE040704",
        name: "ISLAY"
      },
      {
        code: "040705",
        id: "PE040705",
        name: "MEJIA"
      },
      {
        code: "040706",
        id: "PE040706",
        name: "PUNTA DE BOMBON"
      },
      {
        code: "040801",
        id: "PE040801",
        name: "COTAHUASI"
      },
      {
        code: "040802",
        id: "PE040802",
        name: "ALCA"
      },
      {
        code: "040803",
        id: "PE040803",
        name: "CHARCANA"
      },
      {
        code: "040804",
        id: "PE040804",
        name: "HUAYNACOTAS"
      },
      {
        code: "040805",
        id: "PE040805",
        name: "PAMPAMARCA"
      },
      {
        code: "040806",
        id: "PE040806",
        name: "PUYCA"
      },
      {
        code: "040807",
        id: "PE040807",
        name: "QUECHUALLA"
      },
      {
        code: "040808",
        id: "PE040808",
        name: "SAYLA"
      },
      {
        code: "040809",
        id: "PE040809",
        name: "TAURIA"
      },
      {
        code: "040810",
        id: "PE040810",
        name: "TOMEPAMPA"
      },
      {
        code: "040811",
        id: "PE040811",
        name: "TORO"
      },
      {
        code: "050101",
        id: "PE050101",
        name: "AYACUCHO"
      },
      {
        code: "050102",
        id: "PE050102",
        name: "ACOCRO"
      },
      {
        code: "050103",
        id: "PE050103",
        name: "ACOS VINCHOS"
      },
      {
        code: "050104",
        id: "PE050104",
        name: "CARMEN ALTO"
      },
      {
        code: "050105",
        id: "PE050105",
        name: "CHIARA"
      },
      {
        code: "050106",
        id: "PE050106",
        name: "OCROS"
      },
      {
        code: "050107",
        id: "PE050107",
        name: "PACAYCASA"
      },
      {
        code: "050108",
        id: "PE050108",
        name: "QUINUA"
      },
      {
        code: "050109",
        id: "PE050109",
        name: "SAN JOSE DE TICLLAS"
      },
      {
        code: "050110",
        id: "PE050110",
        name: "SAN JUAN BAUTISTA"
      },
      {
        code: "050111",
        id: "PE050111",
        name: "SANTIAGO DE PISCHA"
      },
      {
        code: "050112",
        id: "PE050112",
        name: "SOCOS"
      },
      {
        code: "050113",
        id: "PE050113",
        name: "TAMBILLO"
      },
      {
        code: "050114",
        id: "PE050114",
        name: "VINCHOS"
      },
      {
        code: "050115",
        id: "PE050115",
        name: "JESUS NAZARENO"
      },
      {
        code: "050116",
        id: "PE050116",
        name: "ANDRES AVELINO CACERES DORREGARAY"
      },
      {
        code: "050201",
        id: "PE050201",
        name: "CANGALLO"
      },
      {
        code: "050202",
        id: "PE050202",
        name: "CHUSCHI"
      },
      {
        code: "050203",
        id: "PE050203",
        name: "LOS MOROCHUCOS"
      },
      {
        code: "050204",
        id: "PE050204",
        name: "MARIA PARADO DE BELLIDO"
      },
      {
        code: "050205",
        id: "PE050205",
        name: "PARAS"
      },
      {
        code: "050206",
        id: "PE050206",
        name: "TOTOS"
      },
      {
        code: "050301",
        id: "PE050301",
        name: "SANCOS"
      },
      {
        code: "050302",
        id: "PE050302",
        name: "CARAPO"
      },
      {
        code: "050303",
        id: "PE050303",
        name: "SACSAMARCA"
      },
      {
        code: "050304",
        id: "PE050304",
        name: "SANTIAGO DE LUCANAMARCA"
      },
      {
        code: "050401",
        id: "PE050401",
        name: "HUANTA"
      },
      {
        code: "050402",
        id: "PE050402",
        name: "AYAHUANCO"
      },
      {
        code: "050403",
        id: "PE050403",
        name: "HUAMANGUILLA"
      },
      {
        code: "050404",
        id: "PE050404",
        name: "IGUAIN"
      },
      {
        code: "050405",
        id: "PE050405",
        name: "LURICOCHA"
      },
      {
        code: "050406",
        id: "PE050406",
        name: "SANTILLANA"
      },
      {
        code: "050407",
        id: "PE050407",
        name: "SIVIA"
      },
      {
        code: "050408",
        id: "PE050408",
        name: "LLOCHEGUA"
      },
      {
        code: "050409",
        id: "PE050409",
        name: "CANAYRE"
      },
      {
        code: "050410",
        id: "PE050410",
        name: "UCHURACCAY"
      },
      {
        code: "050411",
        id: "PE050411",
        name: "PUCACOLPA"
      },
      {
        code: "050412",
        id: "PE050412",
        name: "CHACA"
      },
      {
        code: "050501",
        id: "PE050501",
        name: "SAN MIGUEL"
      },
      {
        code: "050502",
        id: "PE050502",
        name: "ANCO"
      },
      {
        code: "050503",
        id: "PE050503",
        name: "AYNA"
      },
      {
        code: "050504",
        id: "PE050504",
        name: "CHILCAS"
      },
      {
        code: "050505",
        id: "PE050505",
        name: "CHUNGUI"
      },
      {
        code: "050506",
        id: "PE050506",
        name: "LUIS CARRANZA"
      },
      {
        code: "050507",
        id: "PE050507",
        name: "SANTA ROSA"
      },
      {
        code: "050508",
        id: "PE050508",
        name: "TAMBO"
      },
      {
        code: "050509",
        id: "PE050509",
        name: "SAMUGARI"
      },
      {
        code: "050510",
        id: "PE050510",
        name: "ANCHIHUAY"
      },
      {
        code: "050511",
        id: "PE050511",
        name: "ORONCCOY"
      },
      {
        code: "050601",
        id: "PE050601",
        name: "PUQUIO"
      },
      {
        code: "050602",
        id: "PE050602",
        name: "AUCARA"
      },
      {
        code: "050603",
        id: "PE050603",
        name: "CABANA"
      },
      {
        code: "050604",
        id: "PE050604",
        name: "CARMEN SALCEDO"
      },
      {
        code: "050605",
        id: "PE050605",
        name: "CHAVIÑA"
      },
      {
        code: "050606",
        id: "PE050606",
        name: "CHIPAO"
      },
      {
        code: "050607",
        id: "PE050607",
        name: "HUAC-HUAS"
      },
      {
        code: "050608",
        id: "PE050608",
        name: "LARAMATE"
      },
      {
        code: "050609",
        id: "PE050609",
        name: "LEONCIO PRADO"
      },
      {
        code: "050610",
        id: "PE050610",
        name: "LLAUTA"
      },
      {
        code: "050611",
        id: "PE050611",
        name: "LUCANAS"
      },
      {
        code: "050612",
        id: "PE050612",
        name: "OCAÑA"
      },
      {
        code: "050613",
        id: "PE050613",
        name: "OTOCA"
      },
      {
        code: "050614",
        id: "PE050614",
        name: "SAISA"
      },
      {
        code: "050615",
        id: "PE050615",
        name: "SAN CRISTOBAL"
      },
      {
        code: "050616",
        id: "PE050616",
        name: "SAN JUAN"
      },
      {
        code: "050617",
        id: "PE050617",
        name: "SAN PEDRO"
      },
      {
        code: "050618",
        id: "PE050618",
        name: "SAN PEDRO DE PALCO"
      },
      {
        code: "050619",
        id: "PE050619",
        name: "SANCOS"
      },
      {
        code: "050620",
        id: "PE050620",
        name: "SANTA ANA DE HUAYCAHUACHO"
      },
      {
        code: "050621",
        id: "PE050621",
        name: "SANTA LUCIA"
      },
      {
        code: "050701",
        id: "PE050701",
        name: "CORACORA"
      },
      {
        code: "050702",
        id: "PE050702",
        name: "CHUMPI"
      },
      {
        code: "050703",
        id: "PE050703",
        name: "CORONEL CASTAÑEDA"
      },
      {
        code: "050704",
        id: "PE050704",
        name: "PACAPAUSA"
      },
      {
        code: "050705",
        id: "PE050705",
        name: "PULLO"
      },
      {
        code: "050706",
        id: "PE050706",
        name: "PUYUSCA"
      },
      {
        code: "050707",
        id: "PE050707",
        name: "SAN FRANCISCO DE RAVACAYCO"
      },
      {
        code: "050708",
        id: "PE050708",
        name: "UPAHUACHO"
      },
      {
        code: "050801",
        id: "PE050801",
        name: "PAUSA"
      },
      {
        code: "050802",
        id: "PE050802",
        name: "COLTA"
      },
      {
        code: "050803",
        id: "PE050803",
        name: "CORCULLA"
      },
      {
        code: "050804",
        id: "PE050804",
        name: "LAMPA"
      },
      {
        code: "050805",
        id: "PE050805",
        name: "MARCABAMBA"
      },
      {
        code: "050806",
        id: "PE050806",
        name: "OYOLO"
      },
      {
        code: "050807",
        id: "PE050807",
        name: "PARARCA"
      },
      {
        code: "050808",
        id: "PE050808",
        name: "SAN JAVIER DE ALPABAMBA"
      },
      {
        code: "050809",
        id: "PE050809",
        name: "SAN JOSE DE USHUA"
      },
      {
        code: "050810",
        id: "PE050810",
        name: "SARA SARA"
      },
      {
        code: "050901",
        id: "PE050901",
        name: "QUEROBAMBA"
      },
      {
        code: "050902",
        id: "PE050902",
        name: "BELEN"
      },
      {
        code: "050903",
        id: "PE050903",
        name: "CHALCOS"
      },
      {
        code: "050904",
        id: "PE050904",
        name: "CHILCAYOC"
      },
      {
        code: "050905",
        id: "PE050905",
        name: "HUACAÑA"
      },
      {
        code: "050906",
        id: "PE050906",
        name: "MORCOLLA"
      },
      {
        code: "050907",
        id: "PE050907",
        name: "PAICO"
      },
      {
        code: "050908",
        id: "PE050908",
        name: "SAN PEDRO DE LARCAY"
      },
      {
        code: "050909",
        id: "PE050909",
        name: "SAN SALVADOR DE QUIJE"
      },
      {
        code: "050910",
        id: "PE050910",
        name: "SANTIAGO DE PAUCARAY"
      },
      {
        code: "050911",
        id: "PE050911",
        name: "SORAS"
      },
      {
        code: "051001",
        id: "PE051001",
        name: "HUANCAPI"
      },
      {
        code: "051002",
        id: "PE051002",
        name: "ALCAMENCA"
      },
      {
        code: "051003",
        id: "PE051003",
        name: "APONGO"
      },
      {
        code: "051004",
        id: "PE051004",
        name: "ASQUIPATA"
      },
      {
        code: "051005",
        id: "PE051005",
        name: "CANARIA"
      },
      {
        code: "051006",
        id: "PE051006",
        name: "CAYARA"
      },
      {
        code: "051007",
        id: "PE051007",
        name: "COLCA"
      },
      {
        code: "051008",
        id: "PE051008",
        name: "HUAMANQUIQUIA"
      },
      {
        code: "051009",
        id: "PE051009",
        name: "HUANCARAYLLA"
      },
      {
        code: "051010",
        id: "PE051010",
        name: "HUALLA"
      },
      {
        code: "051011",
        id: "PE051011",
        name: "SARHUA"
      },
      {
        code: "051012",
        id: "PE051012",
        name: "VILCANCHOS"
      },
      {
        code: "051101",
        id: "PE051101",
        name: "VILCAS HUAMAN"
      },
      {
        code: "051102",
        id: "PE051102",
        name: "ACCOMARCA"
      },
      {
        code: "051103",
        id: "PE051103",
        name: "CARHUANCA"
      },
      {
        code: "051104",
        id: "PE051104",
        name: "CONCEPCION"
      },
      {
        code: "051105",
        id: "PE051105",
        name: "HUAMBALPA"
      },
      {
        code: "051106",
        id: "PE051106",
        name: "INDEPENDENCIA"
      },
      {
        code: "051107",
        id: "PE051107",
        name: "SAURAMA"
      },
      {
        code: "051108",
        id: "PE051108",
        name: "VISCHONGO"
      },
      {
        code: "060101",
        id: "PE060101",
        name: "CAJAMARCA"
      },
      {
        code: "060102",
        id: "PE060102",
        name: "ASUNCION"
      },
      {
        code: "060103",
        id: "PE060103",
        name: "CHETILLA"
      },
      {
        code: "060104",
        id: "PE060104",
        name: "COSPAN"
      },
      {
        code: "060105",
        id: "PE060105",
        name: "ENCAÑADA"
      },
      {
        code: "060106",
        id: "PE060106",
        name: "JESUS"
      },
      {
        code: "060107",
        id: "PE060107",
        name: "LLACANORA"
      },
      {
        code: "060108",
        id: "PE060108",
        name: "LOS BAÑOS DEL INCA"
      },
      {
        code: "060109",
        id: "PE060109",
        name: "MAGDALENA"
      },
      {
        code: "060110",
        id: "PE060110",
        name: "MATARA"
      },
      {
        code: "060111",
        id: "PE060111",
        name: "NAMORA"
      },
      {
        code: "060112",
        id: "PE060112",
        name: "SAN JUAN"
      },
      {
        code: "060201",
        id: "PE060201",
        name: "CAJABAMBA"
      },
      {
        code: "060202",
        id: "PE060202",
        name: "CACHACHI"
      },
      {
        code: "060203",
        id: "PE060203",
        name: "CONDEBAMBA"
      },
      {
        code: "060204",
        id: "PE060204",
        name: "SITACOCHA"
      },
      {
        code: "060301",
        id: "PE060301",
        name: "CELENDIN"
      },
      {
        code: "060302",
        id: "PE060302",
        name: "CHUMUCH"
      },
      {
        code: "060303",
        id: "PE060303",
        name: "CORTEGANA"
      },
      {
        code: "060304",
        id: "PE060304",
        name: "HUASMIN"
      },
      {
        code: "060305",
        id: "PE060305",
        name: "JORGE CHAVEZ"
      },
      {
        code: "060306",
        id: "PE060306",
        name: "JOSE GALVEZ"
      },
      {
        code: "060307",
        id: "PE060307",
        name: "MIGUEL IGLESIAS"
      },
      {
        code: "060308",
        id: "PE060308",
        name: "OXAMARCA"
      },
      {
        code: "060309",
        id: "PE060309",
        name: "SOROCHUCO"
      },
      {
        code: "060310",
        id: "PE060310",
        name: "SUCRE"
      },
      {
        code: "060311",
        id: "PE060311",
        name: "UTCO"
      },
      {
        code: "060312",
        id: "PE060312",
        name: "LA LIBERTAD DE PALLAN"
      },
      {
        code: "060401",
        id: "PE060401",
        name: "CHOTA"
      },
      {
        code: "060402",
        id: "PE060402",
        name: "ANGUIA"
      },
      {
        code: "060403",
        id: "PE060403",
        name: "CHADIN"
      },
      {
        code: "060404",
        id: "PE060404",
        name: "CHIGUIRIP"
      },
      {
        code: "060405",
        id: "PE060405",
        name: "CHIMBAN"
      },
      {
        code: "060406",
        id: "PE060406",
        name: "CHOROPAMPA"
      },
      {
        code: "060407",
        id: "PE060407",
        name: "COCHABAMBA"
      },
      {
        code: "060408",
        id: "PE060408",
        name: "CONCHAN"
      },
      {
        code: "060409",
        id: "PE060409",
        name: "HUAMBOS"
      },
      {
        code: "060410",
        id: "PE060410",
        name: "LAJAS"
      },
      {
        code: "060411",
        id: "PE060411",
        name: "LLAMA"
      },
      {
        code: "060412",
        id: "PE060412",
        name: "MIRACOSTA"
      },
      {
        code: "060413",
        id: "PE060413",
        name: "PACCHA"
      },
      {
        code: "060414",
        id: "PE060414",
        name: "PION"
      },
      {
        code: "060415",
        id: "PE060415",
        name: "QUEROCOTO"
      },
      {
        code: "060416",
        id: "PE060416",
        name: "SAN JUAN DE LICUPIS"
      },
      {
        code: "060417",
        id: "PE060417",
        name: "TACABAMBA"
      },
      {
        code: "060418",
        id: "PE060418",
        name: "TOCMOCHE"
      },
      {
        code: "060419",
        id: "PE060419",
        name: "CHALAMARCA"
      },
      {
        code: "060501",
        id: "PE060501",
        name: "CONTUMAZA"
      },
      {
        code: "060502",
        id: "PE060502",
        name: "CHILETE"
      },
      {
        code: "060503",
        id: "PE060503",
        name: "CUPISNIQUE"
      },
      {
        code: "060504",
        id: "PE060504",
        name: "GUZMANGO"
      },
      {
        code: "060505",
        id: "PE060505",
        name: "SAN BENITO"
      },
      {
        code: "060506",
        id: "PE060506",
        name: "SANTA CRUZ DE TOLED"
      },
      {
        code: "060507",
        id: "PE060507",
        name: "TANTARICA"
      },
      {
        code: "060508",
        id: "PE060508",
        name: "YONAN"
      },
      {
        code: "060601",
        id: "PE060601",
        name: "CUTERVO"
      },
      {
        code: "060602",
        id: "PE060602",
        name: "CALLAYUC"
      },
      {
        code: "060603",
        id: "PE060603",
        name: "CHOROS"
      },
      {
        code: "060604",
        id: "PE060604",
        name: "CUJILLO"
      },
      {
        code: "060605",
        id: "PE060605",
        name: "LA RAMADA"
      },
      {
        code: "060606",
        id: "PE060606",
        name: "PIMPINGOS"
      },
      {
        code: "060607",
        id: "PE060607",
        name: "QUEROCOTILLO"
      },
      {
        code: "060608",
        id: "PE060608",
        name: "SAN ANDRES DE CUTERVO"
      },
      {
        code: "060609",
        id: "PE060609",
        name: "SAN JUAN DE CUTERVO"
      },
      {
        code: "060610",
        id: "PE060610",
        name: "SAN LUIS DE LUCMA"
      },
      {
        code: "060611",
        id: "PE060611",
        name: "SANTA CRUZ"
      },
      {
        code: "060612",
        id: "PE060612",
        name: "SANTO DOMINGO DE LA CAPILLA"
      },
      {
        code: "060613",
        id: "PE060613",
        name: "SANTO TOMAS"
      },
      {
        code: "060614",
        id: "PE060614",
        name: "SOCOTA"
      },
      {
        code: "060615",
        id: "PE060615",
        name: "TORIBIO CASANOVA"
      },
      {
        code: "060701",
        id: "PE060701",
        name: "BAMBAMARCA"
      },
      {
        code: "060702",
        id: "PE060702",
        name: "CHUGUR"
      },
      {
        code: "060703",
        id: "PE060703",
        name: "HUALGAYOC"
      },
      {
        code: "060801",
        id: "PE060801",
        name: "JAEN"
      },
      {
        code: "060802",
        id: "PE060802",
        name: "BELLAVISTA"
      },
      {
        code: "060803",
        id: "PE060803",
        name: "CHONTALI"
      },
      {
        code: "060804",
        id: "PE060804",
        name: "COLASAY"
      },
      {
        code: "060805",
        id: "PE060805",
        name: "HUABAL"
      },
      {
        code: "060806",
        id: "PE060806",
        name: "LAS PIRIAS"
      },
      {
        code: "060807",
        id: "PE060807",
        name: "POMAHUACA"
      },
      {
        code: "060808",
        id: "PE060808",
        name: "PUCARA"
      },
      {
        code: "060809",
        id: "PE060809",
        name: "SALLIQUE"
      },
      {
        code: "060810",
        id: "PE060810",
        name: "SAN FELIPE"
      },
      {
        code: "060811",
        id: "PE060811",
        name: "SAN JOSE DEL ALTO"
      },
      {
        code: "060812",
        id: "PE060812",
        name: "SANTA ROSA"
      },
      {
        code: "060901",
        id: "PE060901",
        name: "SAN IGNACIO"
      },
      {
        code: "060902",
        id: "PE060902",
        name: "CHIRINOS"
      },
      {
        code: "060903",
        id: "PE060903",
        name: "HUARANGO"
      },
      {
        code: "060904",
        id: "PE060904",
        name: "LA COIPA"
      },
      {
        code: "060905",
        id: "PE060905",
        name: "NAMBALLE"
      },
      {
        code: "060906",
        id: "PE060906",
        name: "SAN JOSE DE LOURDES"
      },
      {
        code: "060907",
        id: "PE060907",
        name: "TABACONAS"
      },
      {
        code: "061001",
        id: "PE061001",
        name: "PEDRO GALVEZ"
      },
      {
        code: "061002",
        id: "PE061002",
        name: "CHANCAY"
      },
      {
        code: "061003",
        id: "PE061003",
        name: "EDUARDO VILLANUEVA"
      },
      {
        code: "061004",
        id: "PE061004",
        name: "GREGORIO PITA"
      },
      {
        code: "061005",
        id: "PE061005",
        name: "ICHOCAN"
      },
      {
        code: "061006",
        id: "PE061006",
        name: "JOSE MANUEL QUIROZ"
      },
      {
        code: "061007",
        id: "PE061007",
        name: "JOSE SABOGAL"
      },
      {
        code: "061101",
        id: "PE061101",
        name: "SAN MIGUEL"
      },
      {
        code: "061102",
        id: "PE061102",
        name: "BOLIVAR"
      },
      {
        code: "061103",
        id: "PE061103",
        name: "CALQUIS"
      },
      {
        code: "061104",
        id: "PE061104",
        name: "CATILLUC"
      },
      {
        code: "061105",
        id: "PE061105",
        name: "EL PRADO"
      },
      {
        code: "061106",
        id: "PE061106",
        name: "LA FLORIDA"
      },
      {
        code: "061107",
        id: "PE061107",
        name: "LLAPA"
      },
      {
        code: "061108",
        id: "PE061108",
        name: "NANCHOC"
      },
      {
        code: "061109",
        id: "PE061109",
        name: "NIEPOS"
      },
      {
        code: "061110",
        id: "PE061110",
        name: "SAN GREGORIO"
      },
      {
        code: "061111",
        id: "PE061111",
        name: "SAN SILVESTRE DE COCHAN"
      },
      {
        code: "061112",
        id: "PE061112",
        name: "TONGOD"
      },
      {
        code: "061113",
        id: "PE061113",
        name: "UNION AGUA BLANCA"
      },
      {
        code: "061201",
        id: "PE061201",
        name: "SAN PABLO"
      },
      {
        code: "061202",
        id: "PE061202",
        name: "SAN BERNARDINO"
      },
      {
        code: "061203",
        id: "PE061203",
        name: "SAN LUIS"
      },
      {
        code: "061204",
        id: "PE061204",
        name: "TUMBADEN"
      },
      {
        code: "061301",
        id: "PE061301",
        name: "SANTA CRUZ"
      },
      {
        code: "061302",
        id: "PE061302",
        name: "ANDABAMBA"
      },
      {
        code: "061303",
        id: "PE061303",
        name: "CATACHE"
      },
      {
        code: "061304",
        id: "PE061304",
        name: "CHANCAYBAÑOS"
      },
      {
        code: "061305",
        id: "PE061305",
        name: "LA ESPERANZA"
      },
      {
        code: "061306",
        id: "PE061306",
        name: "NINABAMBA"
      },
      {
        code: "061307",
        id: "PE061307",
        name: "PULAN"
      },
      {
        code: "061308",
        id: "PE061308",
        name: "SAUCEPAMPA"
      },
      {
        code: "061309",
        id: "PE061309",
        name: "SEXI"
      },
      {
        code: "061310",
        id: "PE061310",
        name: "UTICYACU"
      },
      {
        code: "061311",
        id: "PE061311",
        name: "YAUYUCAN"
      },
      {
        code: "070101",
        id: "PE070101",
        name: "CALLAO"
      },
      {
        code: "070102",
        id: "PE070102",
        name: "BELLAVISTA"
      },
      {
        code: "070103",
        id: "PE070103",
        name: "CARMEN DE LA LEGUA REYNOSO"
      },
      {
        code: "070104",
        id: "PE070104",
        name: "LA PERLA"
      },
      {
        code: "070105",
        id: "PE070105",
        name: "LA PUNTA"
      },
      {
        code: "070106",
        id: "PE070106",
        name: "VENTANILLA"
      },
      {
        code: "070107",
        id: "PE070107",
        name: "MI PERU"
      },
      {
        code: "080101",
        id: "PE080101",
        name: "CUSCO"
      },
      {
        code: "080102",
        id: "PE080102",
        name: "CCORCA"
      },
      {
        code: "080103",
        id: "PE080103",
        name: "POROY"
      },
      {
        code: "080104",
        id: "PE080104",
        name: "SAN JERONIMO"
      },
      {
        code: "080105",
        id: "PE080105",
        name: "SAN SEBASTIAN"
      },
      {
        code: "080106",
        id: "PE080106",
        name: "SANTIAGO"
      },
      {
        code: "080107",
        id: "PE080107",
        name: "SAYLLA"
      },
      {
        code: "080108",
        id: "PE080108",
        name: "WANCHAQ"
      },
      {
        code: "080201",
        id: "PE080201",
        name: "ACOMAYO"
      },
      {
        code: "080202",
        id: "PE080202",
        name: "ACOPIA"
      },
      {
        code: "080203",
        id: "PE080203",
        name: "ACOS"
      },
      {
        code: "080204",
        id: "PE080204",
        name: "MOSOC LLACTA"
      },
      {
        code: "080205",
        id: "PE080205",
        name: "POMACANCHI"
      },
      {
        code: "080206",
        id: "PE080206",
        name: "RONDOCAN"
      },
      {
        code: "080207",
        id: "PE080207",
        name: "SANGARARA"
      },
      {
        code: "080301",
        id: "PE080301",
        name: "ANTA"
      },
      {
        code: "080302",
        id: "PE080302",
        name: "ANCAHUASI"
      },
      {
        code: "080303",
        id: "PE080303",
        name: "CACHIMAYO"
      },
      {
        code: "080304",
        id: "PE080304",
        name: "CHINCHAYPUJIO"
      },
      {
        code: "080305",
        id: "PE080305",
        name: "HUAROCONDO"
      },
      {
        code: "080306",
        id: "PE080306",
        name: "LIMATAMBO"
      },
      {
        code: "080307",
        id: "PE080307",
        name: "MOLLEPATA"
      },
      {
        code: "080308",
        id: "PE080308",
        name: "PUCYURA"
      },
      {
        code: "080309",
        id: "PE080309",
        name: "ZURITE"
      },
      {
        code: "080401",
        id: "PE080401",
        name: "CALCA"
      },
      {
        code: "080402",
        id: "PE080402",
        name: "COYA"
      },
      {
        code: "080403",
        id: "PE080403",
        name: "LAMAY"
      },
      {
        code: "080404",
        id: "PE080404",
        name: "LARES"
      },
      {
        code: "080405",
        id: "PE080405",
        name: "PISAC"
      },
      {
        code: "080406",
        id: "PE080406",
        name: "SAN SALVADOR"
      },
      {
        code: "080407",
        id: "PE080407",
        name: "TARAY"
      },
      {
        code: "080408",
        id: "PE080408",
        name: "YANATILE"
      },
      {
        code: "080501",
        id: "PE080501",
        name: "YANAOCA"
      },
      {
        code: "080502",
        id: "PE080502",
        name: "CHECCA"
      },
      {
        code: "080503",
        id: "PE080503",
        name: "KUNTURKANKI"
      },
      {
        code: "080504",
        id: "PE080504",
        name: "LANGUI"
      },
      {
        code: "080505",
        id: "PE080505",
        name: "LAYO"
      },
      {
        code: "080506",
        id: "PE080506",
        name: "PAMPAMARCA"
      },
      {
        code: "080507",
        id: "PE080507",
        name: "QUEHUE"
      },
      {
        code: "080508",
        id: "PE080508",
        name: "TUPAC AMARU"
      },
      {
        code: "080601",
        id: "PE080601",
        name: "SICUANI"
      },
      {
        code: "080602",
        id: "PE080602",
        name: "CHECACUPE"
      },
      {
        code: "080603",
        id: "PE080603",
        name: "COMBAPATA"
      },
      {
        code: "080604",
        id: "PE080604",
        name: "MARANGANI"
      },
      {
        code: "080605",
        id: "PE080605",
        name: "PITUMARCA"
      },
      {
        code: "080606",
        id: "PE080606",
        name: "SAN PABLO"
      },
      {
        code: "080607",
        id: "PE080607",
        name: "SAN PEDRO"
      },
      {
        code: "080608",
        id: "PE080608",
        name: "TINTA"
      },
      {
        code: "080701",
        id: "PE080701",
        name: "SANTO TOMAS"
      },
      {
        code: "080702",
        id: "PE080702",
        name: "CAPACMARCA"
      },
      {
        code: "080703",
        id: "PE080703",
        name: "CHAMACA"
      },
      {
        code: "080704",
        id: "PE080704",
        name: "COLQUEMARCA"
      },
      {
        code: "080705",
        id: "PE080705",
        name: "LIVITACA"
      },
      {
        code: "080706",
        id: "PE080706",
        name: "LLUSCO"
      },
      {
        code: "080707",
        id: "PE080707",
        name: "QUIÑOTA"
      },
      {
        code: "080708",
        id: "PE080708",
        name: "VELILLE"
      },
      {
        code: "080801",
        id: "PE080801",
        name: "ESPINAR"
      },
      {
        code: "080802",
        id: "PE080802",
        name: "CONDOROMA"
      },
      {
        code: "080803",
        id: "PE080803",
        name: "COPORAQUE"
      },
      {
        code: "080804",
        id: "PE080804",
        name: "OCORURO"
      },
      {
        code: "080805",
        id: "PE080805",
        name: "PALLPATA"
      },
      {
        code: "080806",
        id: "PE080806",
        name: "PICHIGUA"
      },
      {
        code: "080807",
        id: "PE080807",
        name: "SUYCKUTAMBO"
      },
      {
        code: "080808",
        id: "PE080808",
        name: "ALTO PICHIGUA"
      },
      {
        code: "080901",
        id: "PE080901",
        name: "SANTA ANA"
      },
      {
        code: "080902",
        id: "PE080902",
        name: "ECHARATE"
      },
      {
        code: "080903",
        id: "PE080903",
        name: "HUAYOPATA"
      },
      {
        code: "080904",
        id: "PE080904",
        name: "MARANURA"
      },
      {
        code: "080905",
        id: "PE080905",
        name: "OCOBAMBA"
      },
      {
        code: "080906",
        id: "PE080906",
        name: "QUELLOUNO"
      },
      {
        code: "080907",
        id: "PE080907",
        name: "KIMBIRI"
      },
      {
        code: "080908",
        id: "PE080908",
        name: "SANTA TERESA"
      },
      {
        code: "080909",
        id: "PE080909",
        name: "VILCABAMBA"
      },
      {
        code: "080910",
        id: "PE080910",
        name: "PICHARI"
      },
      {
        code: "080911",
        id: "PE080911",
        name: "INKAWASI"
      },
      {
        code: "080912",
        id: "PE080912",
        name: "VILLA VIRGEN"
      },
      {
        code: "080913",
        id: "PE080913",
        name: "VILLA KINTIARINA"
      },
      {
        code: "080914",
        id: "PE080914",
        name: "MEGANTONI"
      },
      {
        code: "081001",
        id: "PE081001",
        name: "PARURO"
      },
      {
        code: "081002",
        id: "PE081002",
        name: "ACCHA"
      },
      {
        code: "081003",
        id: "PE081003",
        name: "CCAPI"
      },
      {
        code: "081004",
        id: "PE081004",
        name: "COLCHA"
      },
      {
        code: "081005",
        id: "PE081005",
        name: "HUANOQUITE"
      },
      {
        code: "081006",
        id: "PE081006",
        name: "OMACHA"
      },
      {
        code: "081007",
        id: "PE081007",
        name: "PACCARITAMBO"
      },
      {
        code: "081008",
        id: "PE081008",
        name: "PILLPINTO"
      },
      {
        code: "081009",
        id: "PE081009",
        name: "YAURISQUE"
      },
      {
        code: "081101",
        id: "PE081101",
        name: "PAUCARTAMBO"
      },
      {
        code: "081102",
        id: "PE081102",
        name: "CAICAY"
      },
      {
        code: "081103",
        id: "PE081103",
        name: "CHALLABAMBA"
      },
      {
        code: "081104",
        id: "PE081104",
        name: "COLQUEPATA"
      },
      {
        code: "081105",
        id: "PE081105",
        name: "HUANCARANI"
      },
      {
        code: "081106",
        id: "PE081106",
        name: "KOSÑIPATA"
      },
      {
        code: "081201",
        id: "PE081201",
        name: "URCOS"
      },
      {
        code: "081202",
        id: "PE081202",
        name: "ANDAHUAYLILLAS"
      },
      {
        code: "081203",
        id: "PE081203",
        name: "CAMANTI"
      },
      {
        code: "081204",
        id: "PE081204",
        name: "CCARHUAYO"
      },
      {
        code: "081205",
        id: "PE081205",
        name: "CCATCA"
      },
      {
        code: "081206",
        id: "PE081206",
        name: "CUSIPATA"
      },
      {
        code: "081207",
        id: "PE081207",
        name: "HUARO"
      },
      {
        code: "081208",
        id: "PE081208",
        name: "LUCRE"
      },
      {
        code: "081209",
        id: "PE081209",
        name: "MARCAPATA"
      },
      {
        code: "081210",
        id: "PE081210",
        name: "OCONGATE"
      },
      {
        code: "081211",
        id: "PE081211",
        name: "OROPESA"
      },
      {
        code: "081212",
        id: "PE081212",
        name: "QUIQUIJANA"
      },
      {
        code: "081301",
        id: "PE081301",
        name: "URUBAMBA"
      },
      {
        code: "081302",
        id: "PE081302",
        name: "CHINCHERO"
      },
      {
        code: "081303",
        id: "PE081303",
        name: "HUAYLLABAMBA"
      },
      {
        code: "081304",
        id: "PE081304",
        name: "MACHUPICCHU"
      },
      {
        code: "081305",
        id: "PE081305",
        name: "MARAS"
      },
      {
        code: "081306",
        id: "PE081306",
        name: "OLLANTAYTAMBO"
      },
      {
        code: "081307",
        id: "PE081307",
        name: "YUCAY"
      },
      {
        code: "090101",
        id: "PE090101",
        name: "HUANCAVELICA"
      },
      {
        code: "090102",
        id: "PE090102",
        name: "ACOBAMBILLA"
      },
      {
        code: "090103",
        id: "PE090103",
        name: "ACORIA"
      },
      {
        code: "090104",
        id: "PE090104",
        name: "CONAYCA"
      },
      {
        code: "090105",
        id: "PE090105",
        name: "CUENCA"
      },
      {
        code: "090106",
        id: "PE090106",
        name: "HUACHOCOLPA"
      },
      {
        code: "090107",
        id: "PE090107",
        name: "HUAYLLAHUARA"
      },
      {
        code: "090108",
        id: "PE090108",
        name: "IZCUCHACA"
      },
      {
        code: "090109",
        id: "PE090109",
        name: "LARIA"
      },
      {
        code: "090110",
        id: "PE090110",
        name: "MANTA"
      },
      {
        code: "090111",
        id: "PE090111",
        name: "MARISCAL CACERES"
      },
      {
        code: "090112",
        id: "PE090112",
        name: "MOYA"
      },
      {
        code: "090113",
        id: "PE090113",
        name: "NUEVO OCCORO"
      },
      {
        code: "090114",
        id: "PE090114",
        name: "PALCA"
      },
      {
        code: "090115",
        id: "PE090115",
        name: "PILCHACA"
      },
      {
        code: "090116",
        id: "PE090116",
        name: "VILCA"
      },
      {
        code: "090117",
        id: "PE090117",
        name: "YAULI"
      },
      {
        code: "090118",
        id: "PE090118",
        name: "ASCENSION"
      },
      {
        code: "090119",
        id: "PE090119",
        name: "HUANDO"
      },
      {
        code: "090201",
        id: "PE090201",
        name: "ACOBAMBA"
      },
      {
        code: "090202",
        id: "PE090202",
        name: "ANDABAMBA"
      },
      {
        code: "090203",
        id: "PE090203",
        name: "ANTA"
      },
      {
        code: "090204",
        id: "PE090204",
        name: "CAJA"
      },
      {
        code: "090205",
        id: "PE090205",
        name: "MARCAS"
      },
      {
        code: "090206",
        id: "PE090206",
        name: "PAUCARA"
      },
      {
        code: "090207",
        id: "PE090207",
        name: "POMACOCHA"
      },
      {
        code: "090208",
        id: "PE090208",
        name: "ROSARIO"
      },
      {
        code: "090301",
        id: "PE090301",
        name: "LIRCAY"
      },
      {
        code: "090302",
        id: "PE090302",
        name: "ANCHONGA"
      },
      {
        code: "090303",
        id: "PE090303",
        name: "CALLANMARCA"
      },
      {
        code: "090304",
        id: "PE090304",
        name: "CCOCHACCASA"
      },
      {
        code: "090305",
        id: "PE090305",
        name: "CHINCHO"
      },
      {
        code: "090306",
        id: "PE090306",
        name: "CONGALLA"
      },
      {
        code: "090307",
        id: "PE090307",
        name: "HUANCA-HUANCA"
      },
      {
        code: "090308",
        id: "PE090308",
        name: "HUAYLLAY GRANDE"
      },
      {
        code: "090309",
        id: "PE090309",
        name: "JULCAMARCA"
      },
      {
        code: "090310",
        id: "PE090310",
        name: "SAN ANTONIO DE ANTAPARCO"
      },
      {
        code: "090311",
        id: "PE090311",
        name: "SANTO TOMAS DE PATA"
      },
      {
        code: "090312",
        id: "PE090312",
        name: "SECCLLA"
      },
      {
        code: "090401",
        id: "PE090401",
        name: "CASTROVIRREYNA"
      },
      {
        code: "090402",
        id: "PE090402",
        name: "ARMA"
      },
      {
        code: "090403",
        id: "PE090403",
        name: "AURAHUA"
      },
      {
        code: "090404",
        id: "PE090404",
        name: "CAPILLAS"
      },
      {
        code: "090405",
        id: "PE090405",
        name: "CHUPAMARCA"
      },
      {
        code: "090406",
        id: "PE090406",
        name: "COCAS"
      },
      {
        code: "090407",
        id: "PE090407",
        name: "HUACHOS"
      },
      {
        code: "090408",
        id: "PE090408",
        name: "HUAMATAMBO"
      },
      {
        code: "090409",
        id: "PE090409",
        name: "MOLLEPAMPA"
      },
      {
        code: "090410",
        id: "PE090410",
        name: "SAN JUAN"
      },
      {
        code: "090411",
        id: "PE090411",
        name: "SANTA ANA"
      },
      {
        code: "090412",
        id: "PE090412",
        name: "TANTARA"
      },
      {
        code: "090413",
        id: "PE090413",
        name: "TICRAPO"
      },
      {
        code: "090501",
        id: "PE090501",
        name: "CHURCAMPA"
      },
      {
        code: "090502",
        id: "PE090502",
        name: "ANCO"
      },
      {
        code: "090503",
        id: "PE090503",
        name: "CHINCHIHUASI"
      },
      {
        code: "090504",
        id: "PE090504",
        name: "EL CARMEN"
      },
      {
        code: "090505",
        id: "PE090505",
        name: "LA MERCED"
      },
      {
        code: "090506",
        id: "PE090506",
        name: "LOCROJA"
      },
      {
        code: "090507",
        id: "PE090507",
        name: "PAUCARBAMBA"
      },
      {
        code: "090508",
        id: "PE090508",
        name: "SAN MIGUEL DE MAYOCC"
      },
      {
        code: "090509",
        id: "PE090509",
        name: "SAN PEDRO DE CORIS"
      },
      {
        code: "090510",
        id: "PE090510",
        name: "PACHAMARCA"
      },
      {
        code: "090511",
        id: "PE090511",
        name: "COSME"
      },
      {
        code: "090601",
        id: "PE090601",
        name: "HUAYTARA"
      },
      {
        code: "090602",
        id: "PE090602",
        name: "AYAVI"
      },
      {
        code: "090603",
        id: "PE090603",
        name: "CORDOVA"
      },
      {
        code: "090604",
        id: "PE090604",
        name: "HUAYACUNDO ARMA"
      },
      {
        code: "090605",
        id: "PE090605",
        name: "LARAMARCA"
      },
      {
        code: "090606",
        id: "PE090606",
        name: "OCOYO"
      },
      {
        code: "090607",
        id: "PE090607",
        name: "PILPICHACA"
      },
      {
        code: "090608",
        id: "PE090608",
        name: "QUERCO"
      },
      {
        code: "090609",
        id: "PE090609",
        name: "QUITO-ARMA"
      },
      {
        code: "090610",
        id: "PE090610",
        name: "SAN ANTONIO DE CUSICANCHA"
      },
      {
        code: "090611",
        id: "PE090611",
        name: "SAN FRANCISCO DE SANGAYAICO"
      },
      {
        code: "090612",
        id: "PE090612",
        name: "SAN ISIDRO"
      },
      {
        code: "090613",
        id: "PE090613",
        name: "SANTIAGO DE CHOCORVOS"
      },
      {
        code: "090614",
        id: "PE090614",
        name: "SANTIAGO DE QUIRAHUARA"
      },
      {
        code: "090615",
        id: "PE090615",
        name: "SANTO DOMINGO DE CAPILLAS"
      },
      {
        code: "090616",
        id: "PE090616",
        name: "TAMBO"
      },
      {
        code: "090701",
        id: "PE090701",
        name: "PAMPAS"
      },
      {
        code: "090702",
        id: "PE090702",
        name: "ACOSTAMBO"
      },
      {
        code: "090703",
        id: "PE090703",
        name: "ACRAQUIA"
      },
      {
        code: "090704",
        id: "PE090704",
        name: "AHUAYCHA"
      },
      {
        code: "090705",
        id: "PE090705",
        name: "COLCABAMBA"
      },
      {
        code: "090706",
        id: "PE090706",
        name: "DANIEL HERNANDEZ"
      },
      {
        code: "090707",
        id: "PE090707",
        name: "HUACHOCOLPA"
      },
      {
        code: "090709",
        id: "PE090709",
        name: "HUARIBAMBA"
      },
      {
        code: "090710",
        id: "PE090710",
        name: "ÑAHUIMPUQUIO"
      },
      {
        code: "090711",
        id: "PE090711",
        name: "PAZOS"
      },
      {
        code: "090713",
        id: "PE090713",
        name: "QUISHUAR"
      },
      {
        code: "090714",
        id: "PE090714",
        name: "SALCABAMBA"
      },
      {
        code: "090715",
        id: "PE090715",
        name: "SALCAHUASI"
      },
      {
        code: "090716",
        id: "PE090716",
        name: "SAN MARCOS DE ROCCHAC"
      },
      {
        code: "090717",
        id: "PE090717",
        name: "SURCUBAMBA"
      },
      {
        code: "090718",
        id: "PE090718",
        name: "TINTAY PUNCU"
      },
      {
        code: "090719",
        id: "PE090719",
        name: "QUICHUAS"
      },
      {
        code: "090720",
        id: "PE090720",
        name: "ANDAYMARCA"
      },
      {
        code: "090721",
        id: "PE090721",
        name: "ROBLE"
      },
      {
        code: "090722",
        id: "PE090722",
        name: "PICHOS"
      },
      {
        code: "090723",
        id: "PE090723",
        name: "SANTIAGO DE TUCUMA"
      },
      {
        code: "100101",
        id: "PE100101",
        name: "HUANUCO"
      },
      {
        code: "100102",
        id: "PE100102",
        name: "AMARILIS"
      },
      {
        code: "100103",
        id: "PE100103",
        name: "CHINCHAO"
      },
      {
        code: "100104",
        id: "PE100104",
        name: "CHURUBAMBA"
      },
      {
        code: "100105",
        id: "PE100105",
        name: "MARGOS"
      },
      {
        code: "100106",
        id: "PE100106",
        name: "QUISQUI (KICHKI)"
      },
      {
        code: "100107",
        id: "PE100107",
        name: "SAN FRANCISCO DE CAYRAN"
      },
      {
        code: "100108",
        id: "PE100108",
        name: "SAN PEDRO DE CHAULAN"
      },
      {
        code: "100109",
        id: "PE100109",
        name: "SANTA MARIA DEL VALLE"
      },
      {
        code: "100110",
        id: "PE100110",
        name: "YARUMAYO"
      },
      {
        code: "100111",
        id: "PE100111",
        name: "PILLCO MARCA"
      },
      {
        code: "100112",
        id: "PE100112",
        name: "YACUS"
      },
      {
        code: "100113",
        id: "PE100113",
        name: "SAN PABLO DE PILLAO"
      },
      {
        code: "100201",
        id: "PE100201",
        name: "AMBO"
      },
      {
        code: "100202",
        id: "PE100202",
        name: "CAYNA"
      },
      {
        code: "100203",
        id: "PE100203",
        name: "COLPAS"
      },
      {
        code: "100204",
        id: "PE100204",
        name: "CONCHAMARCA"
      },
      {
        code: "100205",
        id: "PE100205",
        name: "HUACAR"
      },
      {
        code: "100206",
        id: "PE100206",
        name: "SAN FRANCISCO"
      },
      {
        code: "100207",
        id: "PE100207",
        name: "SAN RAFAEL"
      },
      {
        code: "100208",
        id: "PE100208",
        name: "TOMAY KICHWA"
      },
      {
        code: "100301",
        id: "PE100301",
        name: "LA UNION"
      },
      {
        code: "100307",
        id: "PE100307",
        name: "CHUQUIS"
      },
      {
        code: "100311",
        id: "PE100311",
        name: "MARIAS"
      },
      {
        code: "100313",
        id: "PE100313",
        name: "PACHAS"
      },
      {
        code: "100316",
        id: "PE100316",
        name: "QUIVILLA"
      },
      {
        code: "100317",
        id: "PE100317",
        name: "RIPAN"
      },
      {
        code: "100321",
        id: "PE100321",
        name: "SHUNQUI"
      },
      {
        code: "100322",
        id: "PE100322",
        name: "SILLAPATA"
      },
      {
        code: "100323",
        id: "PE100323",
        name: "YANAS"
      },
      {
        code: "100401",
        id: "PE100401",
        name: "HUACAYBAMBA"
      },
      {
        code: "100402",
        id: "PE100402",
        name: "CANCHABAMBA"
      },
      {
        code: "100403",
        id: "PE100403",
        name: "COCHABAMBA"
      },
      {
        code: "100404",
        id: "PE100404",
        name: "PINRA"
      },
      {
        code: "100501",
        id: "PE100501",
        name: "LLATA"
      },
      {
        code: "100502",
        id: "PE100502",
        name: "ARANCAY"
      },
      {
        code: "100503",
        id: "PE100503",
        name: "CHAVIN DE PARIARCA"
      },
      {
        code: "100504",
        id: "PE100504",
        name: "JACAS GRANDE"
      },
      {
        code: "100505",
        id: "PE100505",
        name: "JIRCAN"
      },
      {
        code: "100506",
        id: "PE100506",
        name: "MIRAFLORES"
      },
      {
        code: "100507",
        id: "PE100507",
        name: "MONZON"
      },
      {
        code: "100508",
        id: "PE100508",
        name: "PUNCHAO"
      },
      {
        code: "100509",
        id: "PE100509",
        name: "PUÑOS"
      },
      {
        code: "100510",
        id: "PE100510",
        name: "SINGA"
      },
      {
        code: "100511",
        id: "PE100511",
        name: "TANTAMAYO"
      },
      {
        code: "100601",
        id: "PE100601",
        name: "RUPA-RUPA"
      },
      {
        code: "100602",
        id: "PE100602",
        name: "DANIEL ALOMIA ROBLES"
      },
      {
        code: "100603",
        id: "PE100603",
        name: "HERMILIO VALDIZAN"
      },
      {
        code: "100604",
        id: "PE100604",
        name: "JOSE CRESPO Y CASTILLO"
      },
      {
        code: "100605",
        id: "PE100605",
        name: "LUYANDO"
      },
      {
        code: "100606",
        id: "PE100606",
        name: "MARIANO DAMASO BERAUN"
      },
      {
        code: "100607",
        id: "PE100607",
        name: "PUCAYACU"
      },
      {
        code: "100608",
        id: "PE100608",
        name: "CASTILLO GRANDE"
      },
      {
        code: "100609",
        id: "PE100609",
        name: "PUEBLO NUEVO"
      },
      {
        code: "100610",
        id: "PE100610",
        name: "SANTO DOMINGO DE ANDIA"
      },
      {
        code: "100701",
        id: "PE100701",
        name: "HUACRACHUCO"
      },
      {
        code: "100702",
        id: "PE100702",
        name: "CHOLON"
      },
      {
        code: "100703",
        id: "PE100703",
        name: "SAN BUENAVENTURA"
      },
      {
        code: "100704",
        id: "PE100704",
        name: "LA MORADA"
      },
      {
        code: "100705",
        id: "PE100705",
        name: "SANTA ROSA DE ALTO YANAJANCA"
      },
      {
        code: "100801",
        id: "PE100801",
        name: "PANAO"
      },
      {
        code: "100802",
        id: "PE100802",
        name: "CHAGLLA"
      },
      {
        code: "100803",
        id: "PE100803",
        name: "MOLINO"
      },
      {
        code: "100804",
        id: "PE100804",
        name: "UMARI"
      },
      {
        code: "100901",
        id: "PE100901",
        name: "PUERTO INCA"
      },
      {
        code: "100902",
        id: "PE100902",
        name: "CODO DEL POZUZO"
      },
      {
        code: "100903",
        id: "PE100903",
        name: "HONORIA"
      },
      {
        code: "100904",
        id: "PE100904",
        name: "TOURNAVISTA"
      },
      {
        code: "100905",
        id: "PE100905",
        name: "YUYAPICHIS"
      },
      {
        code: "101001",
        id: "PE101001",
        name: "JESUS"
      },
      {
        code: "101002",
        id: "PE101002",
        name: "BAÑOS"
      },
      {
        code: "101003",
        id: "PE101003",
        name: "JIVIA"
      },
      {
        code: "101004",
        id: "PE101004",
        name: "QUEROPALCA"
      },
      {
        code: "101005",
        id: "PE101005",
        name: "RONDOS"
      },
      {
        code: "101006",
        id: "PE101006",
        name: "SAN FRANCISCO DE ASIS"
      },
      {
        code: "101007",
        id: "PE101007",
        name: "SAN MIGUEL DE CAURI"
      },
      {
        code: "101101",
        id: "PE101101",
        name: "CHAVINILLO"
      },
      {
        code: "101102",
        id: "PE101102",
        name: "CAHUAC"
      },
      {
        code: "101103",
        id: "PE101103",
        name: "CHACABAMBA"
      },
      {
        code: "101104",
        id: "PE101104",
        name: "APARICIO POMARES"
      },
      {
        code: "101105",
        id: "PE101105",
        name: "JACAS CHICO"
      },
      {
        code: "101106",
        id: "PE101106",
        name: "OBAS"
      },
      {
        code: "101107",
        id: "PE101107",
        name: "PAMPAMARCA"
      },
      {
        code: "101108",
        id: "PE101108",
        name: "CHORAS"
      },
      {
        code: "110101",
        id: "PE110101",
        name: "ICA"
      },
      {
        code: "110102",
        id: "PE110102",
        name: "LA TINGUIÑA"
      },
      {
        code: "110103",
        id: "PE110103",
        name: "LOS AQUIJES"
      },
      {
        code: "110104",
        id: "PE110104",
        name: "OCUCAJE"
      },
      {
        code: "110105",
        id: "PE110105",
        name: "PACHACUTEC"
      },
      {
        code: "110106",
        id: "PE110106",
        name: "PARCONA"
      },
      {
        code: "110107",
        id: "PE110107",
        name: "PUEBLO NUEVO"
      },
      {
        code: "110108",
        id: "PE110108",
        name: "SALAS"
      },
      {
        code: "110109",
        id: "PE110109",
        name: "SAN JOSE DE LOS MOLINOS"
      },
      {
        code: "110110",
        id: "PE110110",
        name: "SAN JUAN BAUTISTA"
      },
      {
        code: "110111",
        id: "PE110111",
        name: "SANTIAGO"
      },
      {
        code: "110112",
        id: "PE110112",
        name: "SUBTANJALLA"
      },
      {
        code: "110113",
        id: "PE110113",
        name: "TATE"
      },
      {
        code: "110114",
        id: "PE110114",
        name: "YAUCA DEL ROSARIO"
      },
      {
        code: "110201",
        id: "PE110201",
        name: "CHINCHA ALTA"
      },
      {
        code: "110202",
        id: "PE110202",
        name: "ALTO LARAN"
      },
      {
        code: "110203",
        id: "PE110203",
        name: "CHAVIN"
      },
      {
        code: "110204",
        id: "PE110204",
        name: "CHINCHA BAJA"
      },
      {
        code: "110205",
        id: "PE110205",
        name: "EL CARMEN"
      },
      {
        code: "110206",
        id: "PE110206",
        name: "GROCIO PRADO"
      },
      {
        code: "110207",
        id: "PE110207",
        name: "PUEBLO NUEVO"
      },
      {
        code: "110208",
        id: "PE110208",
        name: "SAN JUAN DE YANAC"
      },
      {
        code: "110209",
        id: "PE110209",
        name: "SAN PEDRO DE HUACARPANA"
      },
      {
        code: "110210",
        id: "PE110210",
        name: "SUNAMPE"
      },
      {
        code: "110211",
        id: "PE110211",
        name: "TAMBO DE MORA"
      },
      {
        code: "110301",
        id: "PE110301",
        name: "NASCA"
      },
      {
        code: "110302",
        id: "PE110302",
        name: "CHANGUILLO"
      },
      {
        code: "110303",
        id: "PE110303",
        name: "EL INGENIO"
      },
      {
        code: "110304",
        id: "PE110304",
        name: "MARCONA"
      },
      {
        code: "110305",
        id: "PE110305",
        name: "VISTA ALEGRE"
      },
      {
        code: "110401",
        id: "PE110401",
        name: "PALPA"
      },
      {
        code: "110402",
        id: "PE110402",
        name: "LLIPATA"
      },
      {
        code: "110403",
        id: "PE110403",
        name: "RIO GRANDE"
      },
      {
        code: "110404",
        id: "PE110404",
        name: "SANTA CRUZ"
      },
      {
        code: "110405",
        id: "PE110405",
        name: "TIBILLO"
      },
      {
        code: "110501",
        id: "PE110501",
        name: "PISCO"
      },
      {
        code: "110502",
        id: "PE110502",
        name: "HUANCANO"
      },
      {
        code: "110503",
        id: "PE110503",
        name: "HUMAY"
      },
      {
        code: "110504",
        id: "PE110504",
        name: "INDEPENDENCIA"
      },
      {
        code: "110505",
        id: "PE110505",
        name: "PARACAS"
      },
      {
        code: "110506",
        id: "PE110506",
        name: "SAN ANDRES"
      },
      {
        code: "110507",
        id: "PE110507",
        name: "SAN CLEMENTE"
      },
      {
        code: "110508",
        id: "PE110508",
        name: "TUPAC AMARU INCA"
      },
      {
        code: "120101",
        id: "PE120101",
        name: "HUANCAYO"
      },
      {
        code: "120104",
        id: "PE120104",
        name: "CARHUACALLANGA"
      },
      {
        code: "120105",
        id: "PE120105",
        name: "CHACAPAMPA"
      },
      {
        code: "120106",
        id: "PE120106",
        name: "CHICCHE"
      },
      {
        code: "120107",
        id: "PE120107",
        name: "CHILCA"
      },
      {
        code: "120108",
        id: "PE120108",
        name: "CHONGOS ALTO"
      },
      {
        code: "120111",
        id: "PE120111",
        name: "CHUPURO"
      },
      {
        code: "120112",
        id: "PE120112",
        name: "COLCA"
      },
      {
        code: "120113",
        id: "PE120113",
        name: "CULLHUAS"
      },
      {
        code: "120114",
        id: "PE120114",
        name: "EL TAMBO"
      },
      {
        code: "120116",
        id: "PE120116",
        name: "HUACRAPUQUIO"
      },
      {
        code: "120117",
        id: "PE120117",
        name: "HUALHUAS"
      },
      {
        code: "120119",
        id: "PE120119",
        name: "HUANCAN"
      },
      {
        code: "120120",
        id: "PE120120",
        name: "HUASICANCHA"
      },
      {
        code: "120121",
        id: "PE120121",
        name: "HUAYUCACHI"
      },
      {
        code: "120122",
        id: "PE120122",
        name: "INGENIO"
      },
      {
        code: "120124",
        id: "PE120124",
        name: "PARIAHUANCA"
      },
      {
        code: "120125",
        id: "PE120125",
        name: "PILCOMAYO"
      },
      {
        code: "120126",
        id: "PE120126",
        name: "PUCARA"
      },
      {
        code: "120127",
        id: "PE120127",
        name: "QUICHUAY"
      },
      {
        code: "120128",
        id: "PE120128",
        name: "QUILCAS"
      },
      {
        code: "120129",
        id: "PE120129",
        name: "SAN AGUSTIN"
      },
      {
        code: "120130",
        id: "PE120130",
        name: "SAN JERONIMO DE TUNAN"
      },
      {
        code: "120132",
        id: "PE120132",
        name: "SAÑO"
      },
      {
        code: "120133",
        id: "PE120133",
        name: "SAPALLANGA"
      },
      {
        code: "120134",
        id: "PE120134",
        name: "SICAYA"
      },
      {
        code: "120135",
        id: "PE120135",
        name: "SANTO DOMINGO DE ACOBAMBA"
      },
      {
        code: "120136",
        id: "PE120136",
        name: "VIQUES"
      },
      {
        code: "120201",
        id: "PE120201",
        name: "CONCEPCION"
      },
      {
        code: "120202",
        id: "PE120202",
        name: "ACO"
      },
      {
        code: "120203",
        id: "PE120203",
        name: "ANDAMARCA"
      },
      {
        code: "120204",
        id: "PE120204",
        name: "CHAMBARA"
      },
      {
        code: "120205",
        id: "PE120205",
        name: "COCHAS"
      },
      {
        code: "120206",
        id: "PE120206",
        name: "COMAS"
      },
      {
        code: "120207",
        id: "PE120207",
        name: "HEROINAS TOLEDO"
      },
      {
        code: "120208",
        id: "PE120208",
        name: "MANZANARES"
      },
      {
        code: "120209",
        id: "PE120209",
        name: "MARISCAL CASTILLA"
      },
      {
        code: "120210",
        id: "PE120210",
        name: "MATAHUASI"
      },
      {
        code: "120211",
        id: "PE120211",
        name: "MITO"
      },
      {
        code: "120212",
        id: "PE120212",
        name: "NUEVE DE JULIO"
      },
      {
        code: "120213",
        id: "PE120213",
        name: "ORCOTUNA"
      },
      {
        code: "120214",
        id: "PE120214",
        name: "SAN JOSE DE QUERO"
      },
      {
        code: "120215",
        id: "PE120215",
        name: "SANTA ROSA DE OCOPA"
      },
      {
        code: "120301",
        id: "PE120301",
        name: "CHANCHAMAYO"
      },
      {
        code: "120302",
        id: "PE120302",
        name: "PERENE"
      },
      {
        code: "120303",
        id: "PE120303",
        name: "PICHANAQUI"
      },
      {
        code: "120304",
        id: "PE120304",
        name: "SAN LUIS DE SHUARO"
      },
      {
        code: "120305",
        id: "PE120305",
        name: "SAN RAMON"
      },
      {
        code: "120306",
        id: "PE120306",
        name: "VITOC"
      },
      {
        code: "120401",
        id: "PE120401",
        name: "JAUJA"
      },
      {
        code: "120402",
        id: "PE120402",
        name: "ACOLLA"
      },
      {
        code: "120403",
        id: "PE120403",
        name: "APATA"
      },
      {
        code: "120404",
        id: "PE120404",
        name: "ATAURA"
      },
      {
        code: "120405",
        id: "PE120405",
        name: "CANCHAYLLO"
      },
      {
        code: "120406",
        id: "PE120406",
        name: "CURICACA"
      },
      {
        code: "120407",
        id: "PE120407",
        name: "EL MANTARO"
      },
      {
        code: "120408",
        id: "PE120408",
        name: "HUAMALI"
      },
      {
        code: "120409",
        id: "PE120409",
        name: "HUARIPAMPA"
      },
      {
        code: "120410",
        id: "PE120410",
        name: "HUERTAS"
      },
      {
        code: "120411",
        id: "PE120411",
        name: "JANJAILLO"
      },
      {
        code: "120412",
        id: "PE120412",
        name: "JULCAN"
      },
      {
        code: "120413",
        id: "PE120413",
        name: "LEONOR ORDOÑEZ"
      },
      {
        code: "120414",
        id: "PE120414",
        name: "LLOCLLAPAMPA"
      },
      {
        code: "120415",
        id: "PE120415",
        name: "MARCO"
      },
      {
        code: "120416",
        id: "PE120416",
        name: "MASMA"
      },
      {
        code: "120417",
        id: "PE120417",
        name: "MASMA CHICCHE"
      },
      {
        code: "120418",
        id: "PE120418",
        name: "MOLINOS"
      },
      {
        code: "120419",
        id: "PE120419",
        name: "MONOBAMBA"
      },
      {
        code: "120420",
        id: "PE120420",
        name: "MUQUI"
      },
      {
        code: "120421",
        id: "PE120421",
        name: "MUQUIYAUYO"
      },
      {
        code: "120422",
        id: "PE120422",
        name: "PACA"
      },
      {
        code: "120423",
        id: "PE120423",
        name: "PACCHA"
      },
      {
        code: "120424",
        id: "PE120424",
        name: "PANCAN"
      },
      {
        code: "120425",
        id: "PE120425",
        name: "PARCO"
      },
      {
        code: "120426",
        id: "PE120426",
        name: "POMACANCHA"
      },
      {
        code: "120427",
        id: "PE120427",
        name: "RICRAN"
      },
      {
        code: "120428",
        id: "PE120428",
        name: "SAN LORENZO"
      },
      {
        code: "120429",
        id: "PE120429",
        name: "SAN PEDRO DE CHUNAN"
      },
      {
        code: "120430",
        id: "PE120430",
        name: "SAUSA"
      },
      {
        code: "120431",
        id: "PE120431",
        name: "SINCOS"
      },
      {
        code: "120432",
        id: "PE120432",
        name: "TUNAN MARCA"
      },
      {
        code: "120433",
        id: "PE120433",
        name: "YAULI"
      },
      {
        code: "120434",
        id: "PE120434",
        name: "YAUYOS"
      },
      {
        code: "120501",
        id: "PE120501",
        name: "JUNIN"
      },
      {
        code: "120502",
        id: "PE120502",
        name: "CARHUAMAYO"
      },
      {
        code: "120503",
        id: "PE120503",
        name: "ONDORES"
      },
      {
        code: "120504",
        id: "PE120504",
        name: "ULCUMAYO"
      },
      {
        code: "120601",
        id: "PE120601",
        name: "SATIPO"
      },
      {
        code: "120602",
        id: "PE120602",
        name: "COVIRIALI"
      },
      {
        code: "120603",
        id: "PE120603",
        name: "LLAYLLA"
      },
      {
        code: "120604",
        id: "PE120604",
        name: "MAZAMARI"
      },
      {
        code: "120605",
        id: "PE120605",
        name: "PAMPA HERMOSA"
      },
      {
        code: "120606",
        id: "PE120606",
        name: "PANGOA"
      },
      {
        code: "120607",
        id: "PE120607",
        name: "RIO NEGRO"
      },
      {
        code: "120608",
        id: "PE120608",
        name: "RIO TAMBO"
      },
      {
        code: "120609",
        id: "PE120609",
        name: "VIZCATAN DEL ENE"
      },
      {
        code: "120701",
        id: "PE120701",
        name: "TARMA"
      },
      {
        code: "120702",
        id: "PE120702",
        name: "ACOBAMBA"
      },
      {
        code: "120703",
        id: "PE120703",
        name: "HUARICOLCA"
      },
      {
        code: "120704",
        id: "PE120704",
        name: "HUASAHUASI"
      },
      {
        code: "120705",
        id: "PE120705",
        name: "LA UNION"
      },
      {
        code: "120706",
        id: "PE120706",
        name: "PALCA"
      },
      {
        code: "120707",
        id: "PE120707",
        name: "PALCAMAYO"
      },
      {
        code: "120708",
        id: "PE120708",
        name: "SAN PEDRO DE CAJAS"
      },
      {
        code: "120709",
        id: "PE120709",
        name: "TAPO"
      },
      {
        code: "120801",
        id: "PE120801",
        name: "LA OROYA"
      },
      {
        code: "120802",
        id: "PE120802",
        name: "CHACAPALPA"
      },
      {
        code: "120803",
        id: "PE120803",
        name: "HUAY-HUAY"
      },
      {
        code: "120804",
        id: "PE120804",
        name: "MARCAPOMACOCHA"
      },
      {
        code: "120805",
        id: "PE120805",
        name: "MOROCOCHA"
      },
      {
        code: "120806",
        id: "PE120806",
        name: "PACCHA"
      },
      {
        code: "120807",
        id: "PE120807",
        name: "SANTA BARBARA DE CARHUACAYAN"
      },
      {
        code: "120808",
        id: "PE120808",
        name: "SANTA ROSA DE SACCO"
      },
      {
        code: "120809",
        id: "PE120809",
        name: "SUITUCANCHA"
      },
      {
        code: "120810",
        id: "PE120810",
        name: "YAULI"
      },
      {
        code: "120901",
        id: "PE120901",
        name: "CHUPACA"
      },
      {
        code: "120902",
        id: "PE120902",
        name: "AHUAC"
      },
      {
        code: "120903",
        id: "PE120903",
        name: "CHONGOS BAJO"
      },
      {
        code: "120904",
        id: "PE120904",
        name: "HUACHAC"
      },
      {
        code: "120905",
        id: "PE120905",
        name: "HUAMANCACA CHICO"
      },
      {
        code: "120906",
        id: "PE120906",
        name: "SAN JUAN DE ISCOS"
      },
      {
        code: "120907",
        id: "PE120907",
        name: "SAN JUAN DE JARPA"
      },
      {
        code: "120908",
        id: "PE120908",
        name: "TRES DE DICIEMBRE"
      },
      {
        code: "120909",
        id: "PE120909",
        name: "YANACANCHA"
      },
      {
        code: "130101",
        id: "PE130101",
        name: "TRUJILLO"
      },
      {
        code: "130102",
        id: "PE130102",
        name: "EL PORVENIR"
      },
      {
        code: "130103",
        id: "PE130103",
        name: "FLORENCIA DE MORA"
      },
      {
        code: "130104",
        id: "PE130104",
        name: "HUANCHACO"
      },
      {
        code: "130105",
        id: "PE130105",
        name: "LA ESPERANZA"
      },
      {
        code: "130106",
        id: "PE130106",
        name: "LAREDO"
      },
      {
        code: "130107",
        id: "PE130107",
        name: "MOCHE"
      },
      {
        code: "130108",
        id: "PE130108",
        name: "POROTO"
      },
      {
        code: "130109",
        id: "PE130109",
        name: "SALAVERRY"
      },
      {
        code: "130110",
        id: "PE130110",
        name: "SIMBAL"
      },
      {
        code: "130111",
        id: "PE130111",
        name: "VICTOR LARCO HERRERA"
      },
      {
        code: "130201",
        id: "PE130201",
        name: "ASCOPE"
      },
      {
        code: "130202",
        id: "PE130202",
        name: "CHICAMA"
      },
      {
        code: "130203",
        id: "PE130203",
        name: "CHOCOPE"
      },
      {
        code: "130204",
        id: "PE130204",
        name: "MAGDALENA DE CAO"
      },
      {
        code: "130205",
        id: "PE130205",
        name: "PAIJAN"
      },
      {
        code: "130206",
        id: "PE130206",
        name: "RAZURI"
      },
      {
        code: "130207",
        id: "PE130207",
        name: "SANTIAGO DE CAO"
      },
      {
        code: "130208",
        id: "PE130208",
        name: "CASA GRANDE"
      },
      {
        code: "130301",
        id: "PE130301",
        name: "BOLIVAR"
      },
      {
        code: "130302",
        id: "PE130302",
        name: "BAMBAMARCA"
      },
      {
        code: "130303",
        id: "PE130303",
        name: "CONDORMARCA"
      },
      {
        code: "130304",
        id: "PE130304",
        name: "LONGOTEA"
      },
      {
        code: "130305",
        id: "PE130305",
        name: "UCHUMARCA"
      },
      {
        code: "130306",
        id: "PE130306",
        name: "UCUNCHA"
      },
      {
        code: "130401",
        id: "PE130401",
        name: "CHEPEN"
      },
      {
        code: "130402",
        id: "PE130402",
        name: "PACANGA"
      },
      {
        code: "130403",
        id: "PE130403",
        name: "PUEBLO NUEVO"
      },
      {
        code: "130501",
        id: "PE130501",
        name: "JULCAN"
      },
      {
        code: "130502",
        id: "PE130502",
        name: "CALAMARCA"
      },
      {
        code: "130503",
        id: "PE130503",
        name: "CARABAMBA"
      },
      {
        code: "130504",
        id: "PE130504",
        name: "HUASO"
      },
      {
        code: "130601",
        id: "PE130601",
        name: "OTUZCO"
      },
      {
        code: "130602",
        id: "PE130602",
        name: "AGALLPAMPA"
      },
      {
        code: "130604",
        id: "PE130604",
        name: "CHARAT"
      },
      {
        code: "130605",
        id: "PE130605",
        name: "HUARANCHAL"
      },
      {
        code: "130606",
        id: "PE130606",
        name: "LA CUESTA"
      },
      {
        code: "130608",
        id: "PE130608",
        name: "MACHE"
      },
      {
        code: "130610",
        id: "PE130610",
        name: "PARANDAY"
      },
      {
        code: "130611",
        id: "PE130611",
        name: "SALPO"
      },
      {
        code: "130613",
        id: "PE130613",
        name: "SINSICAP"
      },
      {
        code: "130614",
        id: "PE130614",
        name: "USQUIL"
      },
      {
        code: "130701",
        id: "PE130701",
        name: "SAN PEDRO DE LLOC"
      },
      {
        code: "130702",
        id: "PE130702",
        name: "GUADALUPE"
      },
      {
        code: "130703",
        id: "PE130703",
        name: "JEQUETEPEQUE"
      },
      {
        code: "130704",
        id: "PE130704",
        name: "PACASMAYO"
      },
      {
        code: "130705",
        id: "PE130705",
        name: "SAN JOSE"
      },
      {
        code: "130801",
        id: "PE130801",
        name: "TAYABAMBA"
      },
      {
        code: "130802",
        id: "PE130802",
        name: "BULDIBUYO"
      },
      {
        code: "130803",
        id: "PE130803",
        name: "CHILLIA"
      },
      {
        code: "130804",
        id: "PE130804",
        name: "HUANCASPATA"
      },
      {
        code: "130805",
        id: "PE130805",
        name: "HUAYLILLAS"
      },
      {
        code: "130806",
        id: "PE130806",
        name: "HUAYO"
      },
      {
        code: "130807",
        id: "PE130807",
        name: "ONGON"
      },
      {
        code: "130808",
        id: "PE130808",
        name: "PARCOY"
      },
      {
        code: "130809",
        id: "PE130809",
        name: "PATAZ"
      },
      {
        code: "130810",
        id: "PE130810",
        name: "PIAS"
      },
      {
        code: "130811",
        id: "PE130811",
        name: "SANTIAGO DE CHALLAS"
      },
      {
        code: "130812",
        id: "PE130812",
        name: "TAURIJA"
      },
      {
        code: "130813",
        id: "PE130813",
        name: "URPAY"
      },
      {
        code: "130901",
        id: "PE130901",
        name: "HUAMACHUCO"
      },
      {
        code: "130902",
        id: "PE130902",
        name: "CHUGAY"
      },
      {
        code: "130903",
        id: "PE130903",
        name: "COCHORCO"
      },
      {
        code: "130904",
        id: "PE130904",
        name: "CURGOS"
      },
      {
        code: "130905",
        id: "PE130905",
        name: "MARCABAL"
      },
      {
        code: "130906",
        id: "PE130906",
        name: "SANAGORAN"
      },
      {
        code: "130907",
        id: "PE130907",
        name: "SARIN"
      },
      {
        code: "130908",
        id: "PE130908",
        name: "SARTIMBAMBA"
      },
      {
        code: "131001",
        id: "PE131001",
        name: "SANTIAGO DE CHUCO"
      },
      {
        code: "131002",
        id: "PE131002",
        name: "ANGASMARCA"
      },
      {
        code: "131003",
        id: "PE131003",
        name: "CACHICADAN"
      },
      {
        code: "131004",
        id: "PE131004",
        name: "MOLLEBAMBA"
      },
      {
        code: "131005",
        id: "PE131005",
        name: "MOLLEPATA"
      },
      {
        code: "131006",
        id: "PE131006",
        name: "QUIRUVILCA"
      },
      {
        code: "131007",
        id: "PE131007",
        name: "SANTA CRUZ DE CHUCA"
      },
      {
        code: "131008",
        id: "PE131008",
        name: "SITABAMBA"
      },
      {
        code: "131101",
        id: "PE131101",
        name: "CASCAS"
      },
      {
        code: "131102",
        id: "PE131102",
        name: "LUCMA"
      },
      {
        code: "131103",
        id: "PE131103",
        name: "MARMOT"
      },
      {
        code: "131104",
        id: "PE131104",
        name: "SAYAPULLO"
      },
      {
        code: "131201",
        id: "PE131201",
        name: "VIRU"
      },
      {
        code: "131202",
        id: "PE131202",
        name: "CHAO"
      },
      {
        code: "131203",
        id: "PE131203",
        name: "GUADALUPITO"
      },
      {
        code: "140101",
        id: "PE140101",
        name: "CHICLAYO"
      },
      {
        code: "140102",
        id: "PE140102",
        name: "CHONGOYAPE"
      },
      {
        code: "140103",
        id: "PE140103",
        name: "ETEN"
      },
      {
        code: "140104",
        id: "PE140104",
        name: "ETEN PUERTO"
      },
      {
        code: "140105",
        id: "PE140105",
        name: "JOSE LEONARDO ORTIZ"
      },
      {
        code: "140106",
        id: "PE140106",
        name: "LA VICTORIA"
      },
      {
        code: "140107",
        id: "PE140107",
        name: "LAGUNAS"
      },
      {
        code: "140108",
        id: "PE140108",
        name: "MONSEFU"
      },
      {
        code: "140109",
        id: "PE140109",
        name: "NUEVA ARICA"
      },
      {
        code: "140110",
        id: "PE140110",
        name: "OYOTUN"
      },
      {
        code: "140111",
        id: "PE140111",
        name: "PICSI"
      },
      {
        code: "140112",
        id: "PE140112",
        name: "PIMENTEL"
      },
      {
        code: "140113",
        id: "PE140113",
        name: "REQUE"
      },
      {
        code: "140114",
        id: "PE140114",
        name: "SANTA ROSA"
      },
      {
        code: "140115",
        id: "PE140115",
        name: "SAÑA"
      },
      {
        code: "140116",
        id: "PE140116",
        name: "CAYALTI"
      },
      {
        code: "140117",
        id: "PE140117",
        name: "PATAPO"
      },
      {
        code: "140118",
        id: "PE140118",
        name: "POMALCA"
      },
      {
        code: "140119",
        id: "PE140119",
        name: "PUCALA"
      },
      {
        code: "140120",
        id: "PE140120",
        name: "TUMAN"
      },
      {
        code: "140201",
        id: "PE140201",
        name: "FERREÑAFE"
      },
      {
        code: "140202",
        id: "PE140202",
        name: "CAÑARIS"
      },
      {
        code: "140203",
        id: "PE140203",
        name: "INCAHUASI"
      },
      {
        code: "140204",
        id: "PE140204",
        name: "MANUEL ANTONIO MESONES MURO"
      },
      {
        code: "140205",
        id: "PE140205",
        name: "PITIPO"
      },
      {
        code: "140206",
        id: "PE140206",
        name: "PUEBLO NUEVO"
      },
      {
        code: "140301",
        id: "PE140301",
        name: "LAMBAYEQUE"
      },
      {
        code: "140302",
        id: "PE140302",
        name: "CHOCHOPE"
      },
      {
        code: "140303",
        id: "PE140303",
        name: "ILLIMO"
      },
      {
        code: "140304",
        id: "PE140304",
        name: "JAYANCA"
      },
      {
        code: "140305",
        id: "PE140305",
        name: "MOCHUMI"
      },
      {
        code: "140306",
        id: "PE140306",
        name: "MORROPE"
      },
      {
        code: "140307",
        id: "PE140307",
        name: "MOTUPE"
      },
      {
        code: "140308",
        id: "PE140308",
        name: "OLMOS"
      },
      {
        code: "140309",
        id: "PE140309",
        name: "PACORA"
      },
      {
        code: "140310",
        id: "PE140310",
        name: "SALAS"
      },
      {
        code: "140311",
        id: "PE140311",
        name: "SAN JOSE"
      },
      {
        code: "140312",
        id: "PE140312",
        name: "TUCUME"
      },
      {
        code: "150101",
        id: "PE150101",
        name: "LIMA"
      },
      {
        code: "150102",
        id: "PE150102",
        name: "ANCON"
      },
      {
        code: "150103",
        id: "PE150103",
        name: "ATE"
      },
      {
        code: "150104",
        id: "PE150104",
        name: "BARRANCO"
      },
      {
        code: "150105",
        id: "PE150105",
        name: "BREÑA"
      },
      {
        code: "150106",
        id: "PE150106",
        name: "CARABAYLLO"
      },
      {
        code: "150107",
        id: "PE150107",
        name: "CHACLACAYO"
      },
      {
        code: "150108",
        id: "PE150108",
        name: "CHORRILLOS"
      },
      {
        code: "150109",
        id: "PE150109",
        name: "CIENEGUILLA"
      },
      {
        code: "150110",
        id: "PE150110",
        name: "COMAS"
      },
      {
        code: "150111",
        id: "PE150111",
        name: "EL AGUSTINO"
      },
      {
        code: "150112",
        id: "PE150112",
        name: "INDEPENDENCIA"
      },
      {
        code: "150113",
        id: "PE150113",
        name: "JESUS MARIA"
      },
      {
        code: "150114",
        id: "PE150114",
        name: "LA MOLINA"
      },
      {
        code: "150115",
        id: "PE150115",
        name: "LA VICTORIA"
      },
      {
        code: "150116",
        id: "PE150116",
        name: "LINCE"
      },
      {
        code: "150117",
        id: "PE150117",
        name: "LOS OLIVOS"
      },
      {
        code: "150118",
        id: "PE150118",
        name: "LURIGANCHO"
      },
      {
        code: "150119",
        id: "PE150119",
        name: "LURIN"
      },
      {
        code: "150120",
        id: "PE150120",
        name: "MAGDALENA DEL MAR"
      },
      {
        code: "150121",
        id: "PE150121",
        name: "PUEBLO LIBRE"
      },
      {
        code: "150122",
        id: "PE150122",
        name: "MIRAFLORES"
      },
      {
        code: "150123",
        id: "PE150123",
        name: "PACHACAMAC"
      },
      {
        code: "150124",
        id: "PE150124",
        name: "PUCUSANA"
      },
      {
        code: "150125",
        id: "PE150125",
        name: "PUENTE PIEDRA"
      },
      {
        code: "150126",
        id: "PE150126",
        name: "PUNTA HERMOSA"
      },
      {
        code: "150127",
        id: "PE150127",
        name: "PUNTA NEGRA"
      },
      {
        code: "150128",
        id: "PE150128",
        name: "RIMAC"
      },
      {
        code: "150129",
        id: "PE150129",
        name: "SAN BARTOLO"
      },
      {
        code: "150130",
        id: "PE150130",
        name: "SAN BORJA"
      },
      {
        code: "150131",
        id: "PE150131",
        name: "SAN ISIDRO"
      },
      {
        code: "150132",
        id: "PE150132",
        name: "SAN JUAN DE LURIGANCHO"
      },
      {
        code: "150133",
        id: "PE150133",
        name: "SAN JUAN DE MIRAFLORES"
      },
      {
        code: "150134",
        id: "PE150134",
        name: "SAN LUIS"
      },
      {
        code: "150135",
        id: "PE150135",
        name: "SAN MARTIN DE PORRES"
      },
      {
        code: "150136",
        id: "PE150136",
        name: "SAN MIGUEL"
      },
      {
        code: "150137",
        id: "PE150137",
        name: "SANTA ANITA"
      },
      {
        code: "150138",
        id: "PE150138",
        name: "SANTA MARIA DEL MAR"
      },
      {
        code: "150139",
        id: "PE150139",
        name: "SANTA ROSA"
      },
      {
        code: "150140",
        id: "PE150140",
        name: "SANTIAGO DE SURCO"
      },
      {
        code: "150141",
        id: "PE150141",
        name: "SURQUILLO"
      },
      {
        code: "150142",
        id: "PE150142",
        name: "VILLA EL SALVADOR"
      },
      {
        code: "150143",
        id: "PE150143",
        name: "VILLA MARIA DEL TRIUNFO"
      },
      {
        code: "150201",
        id: "PE150201",
        name: "BARRANCA"
      },
      {
        code: "150202",
        id: "PE150202",
        name: "PARAMONGA"
      },
      {
        code: "150203",
        id: "PE150203",
        name: "PATIVILCA"
      },
      {
        code: "150204",
        id: "PE150204",
        name: "SUPE"
      },
      {
        code: "150205",
        id: "PE150205",
        name: "SUPE PUERTO"
      },
      {
        code: "150301",
        id: "PE150301",
        name: "CAJATAMBO"
      },
      {
        code: "150302",
        id: "PE150302",
        name: "COPA"
      },
      {
        code: "150303",
        id: "PE150303",
        name: "GORGOR"
      },
      {
        code: "150304",
        id: "PE150304",
        name: "HUANCAPON"
      },
      {
        code: "150305",
        id: "PE150305",
        name: "MANAS"
      },
      {
        code: "150401",
        id: "PE150401",
        name: "CANTA"
      },
      {
        code: "150402",
        id: "PE150402",
        name: "ARAHUAY"
      },
      {
        code: "150403",
        id: "PE150403",
        name: "HUAMANTANGA"
      },
      {
        code: "150404",
        id: "PE150404",
        name: "HUAROS"
      },
      {
        code: "150405",
        id: "PE150405",
        name: "LACHAQUI"
      },
      {
        code: "150406",
        id: "PE150406",
        name: "SAN BUENAVENTURA"
      },
      {
        code: "150407",
        id: "PE150407",
        name: "SANTA ROSA DE QUIVES"
      },
      {
        code: "150501",
        id: "PE150501",
        name: "SAN VICENTE DE CAÑETE"
      },
      {
        code: "150502",
        id: "PE150502",
        name: "ASIA"
      },
      {
        code: "150503",
        id: "PE150503",
        name: "CALANGO"
      },
      {
        code: "150504",
        id: "PE150504",
        name: "CERRO AZUL"
      },
      {
        code: "150505",
        id: "PE150505",
        name: "CHILCA"
      },
      {
        code: "150506",
        id: "PE150506",
        name: "COAYLLO"
      },
      {
        code: "150507",
        id: "PE150507",
        name: "IMPERIAL"
      },
      {
        code: "150508",
        id: "PE150508",
        name: "LUNAHUANA"
      },
      {
        code: "150509",
        id: "PE150509",
        name: "MALA"
      },
      {
        code: "150510",
        id: "PE150510",
        name: "NUEVO IMPERIAL"
      },
      {
        code: "150511",
        id: "PE150511",
        name: "PACARAN"
      },
      {
        code: "150512",
        id: "PE150512",
        name: "QUILMANA"
      },
      {
        code: "150513",
        id: "PE150513",
        name: "SAN ANTONIO"
      },
      {
        code: "150514",
        id: "PE150514",
        name: "SAN LUIS"
      },
      {
        code: "150515",
        id: "PE150515",
        name: "SANTA CRUZ DE FLORES"
      },
      {
        code: "150516",
        id: "PE150516",
        name: "ZUÑIGA"
      },
      {
        code: "150601",
        id: "PE150601",
        name: "HUARAL"
      },
      {
        code: "150602",
        id: "PE150602",
        name: "ATAVILLOS ALTO"
      },
      {
        code: "150603",
        id: "PE150603",
        name: "ATAVILLOS BAJO"
      },
      {
        code: "150604",
        id: "PE150604",
        name: "AUCALLAMA"
      },
      {
        code: "150605",
        id: "PE150605",
        name: "CHANCAY"
      },
      {
        code: "150606",
        id: "PE150606",
        name: "IHUARI"
      },
      {
        code: "150607",
        id: "PE150607",
        name: "LAMPIAN"
      },
      {
        code: "150608",
        id: "PE150608",
        name: "PACARAOS"
      },
      {
        code: "150609",
        id: "PE150609",
        name: "SAN MIGUEL DE ACOS"
      },
      {
        code: "150610",
        id: "PE150610",
        name: "SANTA CRUZ DE ANDAMARCA"
      },
      {
        code: "150611",
        id: "PE150611",
        name: "SUMBILCA"
      },
      {
        code: "150612",
        id: "PE150612",
        name: "VEINTISIETE DE NOVIEMBRE"
      },
      {
        code: "150701",
        id: "PE150701",
        name: "MATUCANA"
      },
      {
        code: "150702",
        id: "PE150702",
        name: "ANTIOQUIA"
      },
      {
        code: "150703",
        id: "PE150703",
        name: "CALLAHUANCA"
      },
      {
        code: "150704",
        id: "PE150704",
        name: "CARAMPOMA"
      },
      {
        code: "150705",
        id: "PE150705",
        name: "CHICLA"
      },
      {
        code: "150706",
        id: "PE150706",
        name: "CUENCA"
      },
      {
        code: "150707",
        id: "PE150707",
        name: "HUACHUPAMPA"
      },
      {
        code: "150708",
        id: "PE150708",
        name: "HUANZA"
      },
      {
        code: "150709",
        id: "PE150709",
        name: "HUAROCHIRI"
      },
      {
        code: "150710",
        id: "PE150710",
        name: "LAHUAYTAMBO"
      },
      {
        code: "150711",
        id: "PE150711",
        name: "LANGA"
      },
      {
        code: "150712",
        id: "PE150712",
        name: "SAN PEDRO DE LARAOS"
      },
      {
        code: "150713",
        id: "PE150713",
        name: "MARIATANA"
      },
      {
        code: "150714",
        id: "PE150714",
        name: "RICARDO PALMA"
      },
      {
        code: "150715",
        id: "PE150715",
        name: "SAN ANDRES DE TUPICOCHA"
      },
      {
        code: "150716",
        id: "PE150716",
        name: "SAN ANTONIO"
      },
      {
        code: "150717",
        id: "PE150717",
        name: "SAN BARTOLOME"
      },
      {
        code: "150718",
        id: "PE150718",
        name: "SAN DAMIAN"
      },
      {
        code: "150719",
        id: "PE150719",
        name: "SAN JUAN DE IRIS"
      },
      {
        code: "150720",
        id: "PE150720",
        name: "SAN JUAN DE TANTARANCHE"
      },
      {
        code: "150721",
        id: "PE150721",
        name: "SAN LORENZO DE QUINTI"
      },
      {
        code: "150722",
        id: "PE150722",
        name: "SAN MATEO"
      },
      {
        code: "150723",
        id: "PE150723",
        name: "SAN MATEO DE OTAO"
      },
      {
        code: "150724",
        id: "PE150724",
        name: "SAN PEDRO DE CASTA"
      },
      {
        code: "150725",
        id: "PE150725",
        name: "SAN PEDRO DE HUANCAYRE"
      },
      {
        code: "150726",
        id: "PE150726",
        name: "SANGALLAYA"
      },
      {
        code: "150727",
        id: "PE150727",
        name: "SANTA CRUZ DE COCACHACRA"
      },
      {
        code: "150728",
        id: "PE150728",
        name: "SANTA EULALIA"
      },
      {
        code: "150729",
        id: "PE150729",
        name: "SANTIAGO DE ANCHUCAYA"
      },
      {
        code: "150730",
        id: "PE150730",
        name: "SANTIAGO DE TUNA"
      },
      {
        code: "150731",
        id: "PE150731",
        name: "SANTO DOMINGO DE LOS OLLEROS"
      },
      {
        code: "150732",
        id: "PE150732",
        name: "SURCO"
      },
      {
        code: "150801",
        id: "PE150801",
        name: "HUACHO"
      },
      {
        code: "150802",
        id: "PE150802",
        name: "AMBAR"
      },
      {
        code: "150803",
        id: "PE150803",
        name: "CALETA DE CARQUIN"
      },
      {
        code: "150804",
        id: "PE150804",
        name: "CHECRAS"
      },
      {
        code: "150805",
        id: "PE150805",
        name: "HUALMAY"
      },
      {
        code: "150806",
        id: "PE150806",
        name: "HUAURA"
      },
      {
        code: "150807",
        id: "PE150807",
        name: "LEONCIO PRADO"
      },
      {
        code: "150808",
        id: "PE150808",
        name: "PACCHO"
      },
      {
        code: "150809",
        id: "PE150809",
        name: "SANTA LEONOR"
      },
      {
        code: "150810",
        id: "PE150810",
        name: "SANTA MARIA"
      },
      {
        code: "150811",
        id: "PE150811",
        name: "SAYAN"
      },
      {
        code: "150812",
        id: "PE150812",
        name: "VEGUETA"
      },
      {
        code: "150901",
        id: "PE150901",
        name: "OYON"
      },
      {
        code: "150902",
        id: "PE150902",
        name: "ANDAJES"
      },
      {
        code: "150903",
        id: "PE150903",
        name: "CAUJUL"
      },
      {
        code: "150904",
        id: "PE150904",
        name: "COCHAMARCA"
      },
      {
        code: "150905",
        id: "PE150905",
        name: "NAVAN"
      },
      {
        code: "150906",
        id: "PE150906",
        name: "PACHANGARA"
      },
      {
        code: "151001",
        id: "PE151001",
        name: "YAUYOS"
      },
      {
        code: "151002",
        id: "PE151002",
        name: "ALIS"
      },
      {
        code: "151003",
        id: "PE151003",
        name: "ALLAUCA"
      },
      {
        code: "151004",
        id: "PE151004",
        name: "AYAVIRI"
      },
      {
        code: "151005",
        id: "PE151005",
        name: "AZANGARO"
      },
      {
        code: "151006",
        id: "PE151006",
        name: "CACRA"
      },
      {
        code: "151007",
        id: "PE151007",
        name: "CARANIA"
      },
      {
        code: "151008",
        id: "PE151008",
        name: "CATAHUASI"
      },
      {
        code: "151009",
        id: "PE151009",
        name: "CHOCOS"
      },
      {
        code: "151010",
        id: "PE151010",
        name: "COCHAS"
      },
      {
        code: "151011",
        id: "PE151011",
        name: "COLONIA"
      },
      {
        code: "151012",
        id: "PE151012",
        name: "HONGOS"
      },
      {
        code: "151013",
        id: "PE151013",
        name: "HUAMPARA"
      },
      {
        code: "151014",
        id: "PE151014",
        name: "HUANCAYA"
      },
      {
        code: "151015",
        id: "PE151015",
        name: "HUANGASCAR"
      },
      {
        code: "151016",
        id: "PE151016",
        name: "HUANTAN"
      },
      {
        code: "151017",
        id: "PE151017",
        name: "HUAÑEC"
      },
      {
        code: "151018",
        id: "PE151018",
        name: "LARAOS"
      },
      {
        code: "151019",
        id: "PE151019",
        name: "LINCHA"
      },
      {
        code: "151020",
        id: "PE151020",
        name: "MADEAN"
      },
      {
        code: "151021",
        id: "PE151021",
        name: "MIRAFLORES"
      },
      {
        code: "151022",
        id: "PE151022",
        name: "OMAS"
      },
      {
        code: "151023",
        id: "PE151023",
        name: "PUTINZA"
      },
      {
        code: "151024",
        id: "PE151024",
        name: "QUINCHES"
      },
      {
        code: "151025",
        id: "PE151025",
        name: "QUINOCAY"
      },
      {
        code: "151026",
        id: "PE151026",
        name: "SAN JOAQUIN"
      },
      {
        code: "151027",
        id: "PE151027",
        name: "SAN PEDRO DE PILAS"
      },
      {
        code: "151028",
        id: "PE151028",
        name: "TANTA"
      },
      {
        code: "151029",
        id: "PE151029",
        name: "TAURIPAMPA"
      },
      {
        code: "151030",
        id: "PE151030",
        name: "TOMAS"
      },
      {
        code: "151031",
        id: "PE151031",
        name: "TUPE"
      },
      {
        code: "151032",
        id: "PE151032",
        name: "VIÑAC"
      },
      {
        code: "151033",
        id: "PE151033",
        name: "VITIS"
      },
      {
        code: "160101",
        id: "PE160101",
        name: "IQUITOS"
      },
      {
        code: "160102",
        id: "PE160102",
        name: "ALTO NANAY"
      },
      {
        code: "160103",
        id: "PE160103",
        name: "FERNANDO LORES"
      },
      {
        code: "160104",
        id: "PE160104",
        name: "INDIANA"
      },
      {
        code: "160105",
        id: "PE160105",
        name: "LAS AMAZONAS"
      },
      {
        code: "160106",
        id: "PE160106",
        name: "MAZAN"
      },
      {
        code: "160107",
        id: "PE160107",
        name: "NAPO"
      },
      {
        code: "160108",
        id: "PE160108",
        name: "PUNCHANA"
      },
      {
        code: "160110",
        id: "PE160110",
        name: "TORRES CAUSANA"
      },
      {
        code: "160112",
        id: "PE160112",
        name: "BELEN"
      },
      {
        code: "160113",
        id: "PE160113",
        name: "SAN JUAN BAUTISTA"
      },
      {
        code: "160201",
        id: "PE160201",
        name: "YURIMAGUAS"
      },
      {
        code: "160202",
        id: "PE160202",
        name: "BALSAPUERTO"
      },
      {
        code: "160205",
        id: "PE160205",
        name: "JEBEROS"
      },
      {
        code: "160206",
        id: "PE160206",
        name: "LAGUNAS"
      },
      {
        code: "160210",
        id: "PE160210",
        name: "SANTA CRUZ"
      },
      {
        code: "160211",
        id: "PE160211",
        name: "TENIENTE CESAR LOPEZ ROJAS"
      },
      {
        code: "160301",
        id: "PE160301",
        name: "NAUTA"
      },
      {
        code: "160302",
        id: "PE160302",
        name: "PARINARI"
      },
      {
        code: "160303",
        id: "PE160303",
        name: "TIGRE"
      },
      {
        code: "160304",
        id: "PE160304",
        name: "TROMPETEROS"
      },
      {
        code: "160305",
        id: "PE160305",
        name: "URARINAS"
      },
      {
        code: "160401",
        id: "PE160401",
        name: "RAMON CASTILLA"
      },
      {
        code: "160402",
        id: "PE160402",
        name: "PEBAS"
      },
      {
        code: "160403",
        id: "PE160403",
        name: "YAVARI"
      },
      {
        code: "160404",
        id: "PE160404",
        name: "SAN PABLO"
      },
      {
        code: "160501",
        id: "PE160501",
        name: "REQUENA"
      },
      {
        code: "160502",
        id: "PE160502",
        name: "ALTO TAPICHE"
      },
      {
        code: "160503",
        id: "PE160503",
        name: "CAPELO"
      },
      {
        code: "160504",
        id: "PE160504",
        name: "EMILIO SAN MARTIN"
      },
      {
        code: "160505",
        id: "PE160505",
        name: "MAQUIA"
      },
      {
        code: "160506",
        id: "PE160506",
        name: "PUINAHUA"
      },
      {
        code: "160507",
        id: "PE160507",
        name: "SAQUENA"
      },
      {
        code: "160508",
        id: "PE160508",
        name: "SOPLIN"
      },
      {
        code: "160509",
        id: "PE160509",
        name: "TAPICHE"
      },
      {
        code: "160510",
        id: "PE160510",
        name: "JENARO HERRERA"
      },
      {
        code: "160511",
        id: "PE160511",
        name: "YAQUERANA"
      },
      {
        code: "160601",
        id: "PE160601",
        name: "CONTAMANA"
      },
      {
        code: "160602",
        id: "PE160602",
        name: "INAHUAYA"
      },
      {
        code: "160603",
        id: "PE160603",
        name: "PADRE MARQUEZ"
      },
      {
        code: "160604",
        id: "PE160604",
        name: "PAMPA HERMOSA"
      },
      {
        code: "160605",
        id: "PE160605",
        name: "SARAYACU"
      },
      {
        code: "160606",
        id: "PE160606",
        name: "VARGAS GUERRA"
      },
      {
        code: "160701",
        id: "PE160701",
        name: "BARRANCA"
      },
      {
        code: "160702",
        id: "PE160702",
        name: "CAHUAPANAS"
      },
      {
        code: "160703",
        id: "PE160703",
        name: "MANSERICHE"
      },
      {
        code: "160704",
        id: "PE160704",
        name: "MORONA"
      },
      {
        code: "160705",
        id: "PE160705",
        name: "PASTAZA"
      },
      {
        code: "160706",
        id: "PE160706",
        name: "ANDOAS"
      },
      {
        code: "160801",
        id: "PE160801",
        name: "PUTUMAYO"
      },
      {
        code: "160802",
        id: "PE160802",
        name: "ROSA PANDURO"
      },
      {
        code: "160803",
        id: "PE160803",
        name: "TENIENTE MANUEL CLAVERO"
      },
      {
        code: "160804",
        id: "PE160804",
        name: "YAGUAS"
      },
      {
        code: "170101",
        id: "PE170101",
        name: "TAMBOPATA"
      },
      {
        code: "170102",
        id: "PE170102",
        name: "INAMBARI"
      },
      {
        code: "170103",
        id: "PE170103",
        name: "LAS PIEDRAS"
      },
      {
        code: "170104",
        id: "PE170104",
        name: "LABERINTO"
      },
      {
        code: "170201",
        id: "PE170201",
        name: "MANU"
      },
      {
        code: "170202",
        id: "PE170202",
        name: "FITZCARRALD"
      },
      {
        code: "170203",
        id: "PE170203",
        name: "MADRE DE DIOS"
      },
      {
        code: "170204",
        id: "PE170204",
        name: "HUEPETUHE"
      },
      {
        code: "170301",
        id: "PE170301",
        name: "IÑAPARI"
      },
      {
        code: "170302",
        id: "PE170302",
        name: "IBERIA"
      },
      {
        code: "170303",
        id: "PE170303",
        name: "TAHUAMANU"
      },
      {
        code: "180101",
        id: "PE180101",
        name: "MOQUEGUA"
      },
      {
        code: "180102",
        id: "PE180102",
        name: "CARUMAS"
      },
      {
        code: "180103",
        id: "PE180103",
        name: "CUCHUMBAYA"
      },
      {
        code: "180104",
        id: "PE180104",
        name: "SAMEGUA"
      },
      {
        code: "180105",
        id: "PE180105",
        name: "SAN CRISTOBAL"
      },
      {
        code: "180106",
        id: "PE180106",
        name: "TORATA"
      },
      {
        code: "180201",
        id: "PE180201",
        name: "OMATE"
      },
      {
        code: "180202",
        id: "PE180202",
        name: "CHOJATA"
      },
      {
        code: "180203",
        id: "PE180203",
        name: "COALAQUE"
      },
      {
        code: "180204",
        id: "PE180204",
        name: "ICHUÑA"
      },
      {
        code: "180205",
        id: "PE180205",
        name: "LA CAPILLA"
      },
      {
        code: "180206",
        id: "PE180206",
        name: "LLOQUE"
      },
      {
        code: "180207",
        id: "PE180207",
        name: "MATALAQUE"
      },
      {
        code: "180208",
        id: "PE180208",
        name: "PUQUINA"
      },
      {
        code: "180209",
        id: "PE180209",
        name: "QUINISTAQUILLAS"
      },
      {
        code: "180210",
        id: "PE180210",
        name: "UBINAS"
      },
      {
        code: "180211",
        id: "PE180211",
        name: "YUNGA"
      },
      {
        code: "180301",
        id: "PE180301",
        name: "ILO"
      },
      {
        code: "180302",
        id: "PE180302",
        name: "EL ALGARROBAL"
      },
      {
        code: "180303",
        id: "PE180303",
        name: "PACOCHA"
      },
      {
        code: "190101",
        id: "PE190101",
        name: "CHAUPIMARCA"
      },
      {
        code: "190102",
        id: "PE190102",
        name: "HUACHON"
      },
      {
        code: "190103",
        id: "PE190103",
        name: "HUARIACA"
      },
      {
        code: "190104",
        id: "PE190104",
        name: "HUAYLLAY"
      },
      {
        code: "190105",
        id: "PE190105",
        name: "NINACACA"
      },
      {
        code: "190106",
        id: "PE190106",
        name: "PALLANCHACRA"
      },
      {
        code: "190107",
        id: "PE190107",
        name: "PAUCARTAMBO"
      },
      {
        code: "190108",
        id: "PE190108",
        name: "SAN FRANCISCO DE ASIS DE YARUSYACAN"
      },
      {
        code: "190109",
        id: "PE190109",
        name: "SIMON BOLIVAR"
      },
      {
        code: "190110",
        id: "PE190110",
        name: "TICLACAYAN"
      },
      {
        code: "190111",
        id: "PE190111",
        name: "TINYAHUARCO"
      },
      {
        code: "190112",
        id: "PE190112",
        name: "VICCO"
      },
      {
        code: "190113",
        id: "PE190113",
        name: "YANACANCHA"
      },
      {
        code: "190201",
        id: "PE190201",
        name: "YANAHUANCA"
      },
      {
        code: "190202",
        id: "PE190202",
        name: "CHACAYAN"
      },
      {
        code: "190203",
        id: "PE190203",
        name: "GOYLLARISQUIZGA"
      },
      {
        code: "190204",
        id: "PE190204",
        name: "PAUCAR"
      },
      {
        code: "190205",
        id: "PE190205",
        name: "SAN PEDRO DE PILLAO"
      },
      {
        code: "190206",
        id: "PE190206",
        name: "SANTA ANA DE TUSI"
      },
      {
        code: "190207",
        id: "PE190207",
        name: "TAPUC"
      },
      {
        code: "190208",
        id: "PE190208",
        name: "VILCABAMBA"
      },
      {
        code: "190301",
        id: "PE190301",
        name: "OXAPAMPA"
      },
      {
        code: "190302",
        id: "PE190302",
        name: "CHONTABAMBA"
      },
      {
        code: "190303",
        id: "PE190303",
        name: "HUANCABAMBA"
      },
      {
        code: "190304",
        id: "PE190304",
        name: "PALCAZU"
      },
      {
        code: "190305",
        id: "PE190305",
        name: "POZUZO"
      },
      {
        code: "190306",
        id: "PE190306",
        name: "PUERTO BERMUDEZ"
      },
      {
        code: "190307",
        id: "PE190307",
        name: "VILLA RICA"
      },
      {
        code: "190308",
        id: "PE190308",
        name: "CONSTITUCION"
      },
      {
        code: "200101",
        id: "PE200101",
        name: "PIURA"
      },
      {
        code: "200104",
        id: "PE200104",
        name: "CASTILLA"
      },
      {
        code: "200105",
        id: "PE200105",
        name: "CATACAOS"
      },
      {
        code: "200107",
        id: "PE200107",
        name: "CURA MORI"
      },
      {
        code: "200108",
        id: "PE200108",
        name: "EL TALLAN"
      },
      {
        code: "200109",
        id: "PE200109",
        name: "LA ARENA"
      },
      {
        code: "200110",
        id: "PE200110",
        name: "LA UNION"
      },
      {
        code: "200111",
        id: "PE200111",
        name: "LAS LOMAS"
      },
      {
        code: "200114",
        id: "PE200114",
        name: "TAMBO GRANDE"
      },
      {
        code: "200115",
        id: "PE200115",
        name: "VEINTISEIS DE OCTUBRE"
      },
      {
        code: "200201",
        id: "PE200201",
        name: "AYABACA"
      },
      {
        code: "200202",
        id: "PE200202",
        name: "FRIAS"
      },
      {
        code: "200203",
        id: "PE200203",
        name: "JILILI"
      },
      {
        code: "200204",
        id: "PE200204",
        name: "LAGUNAS"
      },
      {
        code: "200205",
        id: "PE200205",
        name: "MONTERO"
      },
      {
        code: "200206",
        id: "PE200206",
        name: "PACAIPAMPA"
      },
      {
        code: "200207",
        id: "PE200207",
        name: "PAIMAS"
      },
      {
        code: "200208",
        id: "PE200208",
        name: "SAPILLICA"
      },
      {
        code: "200209",
        id: "PE200209",
        name: "SICCHEZ"
      },
      {
        code: "200210",
        id: "PE200210",
        name: "SUYO"
      },
      {
        code: "200301",
        id: "PE200301",
        name: "HUANCABAMBA"
      },
      {
        code: "200302",
        id: "PE200302",
        name: "CANCHAQUE"
      },
      {
        code: "200303",
        id: "PE200303",
        name: "EL CARMEN DE LA FRONTERA"
      },
      {
        code: "200304",
        id: "PE200304",
        name: "HUARMACA"
      },
      {
        code: "200305",
        id: "PE200305",
        name: "LALAQUIZ"
      },
      {
        code: "200306",
        id: "PE200306",
        name: "SAN MIGUEL DE EL FAIQUE"
      },
      {
        code: "200307",
        id: "PE200307",
        name: "SONDOR"
      },
      {
        code: "200308",
        id: "PE200308",
        name: "SONDORILLO"
      },
      {
        code: "200401",
        id: "PE200401",
        name: "CHULUCANAS"
      },
      {
        code: "200402",
        id: "PE200402",
        name: "BUENOS AIRES"
      },
      {
        code: "200403",
        id: "PE200403",
        name: "CHALACO"
      },
      {
        code: "200404",
        id: "PE200404",
        name: "LA MATANZA"
      },
      {
        code: "200405",
        id: "PE200405",
        name: "MORROPON"
      },
      {
        code: "200406",
        id: "PE200406",
        name: "SALITRAL"
      },
      {
        code: "200407",
        id: "PE200407",
        name: "SAN JUAN DE BIGOTE"
      },
      {
        code: "200408",
        id: "PE200408",
        name: "SANTA CATALINA DE MOSSA"
      },
      {
        code: "200409",
        id: "PE200409",
        name: "SANTO DOMINGO"
      },
      {
        code: "200410",
        id: "PE200410",
        name: "YAMANGO"
      },
      {
        code: "200501",
        id: "PE200501",
        name: "PAITA"
      },
      {
        code: "200502",
        id: "PE200502",
        name: "AMOTAPE"
      },
      {
        code: "200503",
        id: "PE200503",
        name: "ARENAL"
      },
      {
        code: "200504",
        id: "PE200504",
        name: "COLAN"
      },
      {
        code: "200505",
        id: "PE200505",
        name: "LA HUACA"
      },
      {
        code: "200506",
        id: "PE200506",
        name: "TAMARINDO"
      },
      {
        code: "200507",
        id: "PE200507",
        name: "VICHAYAL"
      },
      {
        code: "200601",
        id: "PE200601",
        name: "SULLANA"
      },
      {
        code: "200602",
        id: "PE200602",
        name: "BELLAVISTA"
      },
      {
        code: "200603",
        id: "PE200603",
        name: "IGNACIO ESCUDERO"
      },
      {
        code: "200604",
        id: "PE200604",
        name: "LANCONES"
      },
      {
        code: "200605",
        id: "PE200605",
        name: "MARCAVELICA"
      },
      {
        code: "200606",
        id: "PE200606",
        name: "MIGUEL CHECA"
      },
      {
        code: "200607",
        id: "PE200607",
        name: "QUERECOTILLO"
      },
      {
        code: "200608",
        id: "PE200608",
        name: "SALITRAL"
      },
      {
        code: "200701",
        id: "PE200701",
        name: "PARIÑAS"
      },
      {
        code: "200702",
        id: "PE200702",
        name: "EL ALTO"
      },
      {
        code: "200703",
        id: "PE200703",
        name: "LA BREA"
      },
      {
        code: "200704",
        id: "PE200704",
        name: "LOBITOS"
      },
      {
        code: "200705",
        id: "PE200705",
        name: "LOS ORGANOS"
      },
      {
        code: "200706",
        id: "PE200706",
        name: "MANCORA"
      },
      {
        code: "200801",
        id: "PE200801",
        name: "SECHURA"
      },
      {
        code: "200802",
        id: "PE200802",
        name: "BELLAVISTA DE LA UNION"
      },
      {
        code: "200803",
        id: "PE200803",
        name: "BERNAL"
      },
      {
        code: "200804",
        id: "PE200804",
        name: "CRISTO NOS VALGA"
      },
      {
        code: "200805",
        id: "PE200805",
        name: "VICE"
      },
      {
        code: "200806",
        id: "PE200806",
        name: "RINCONADA LLICUAR"
      },
      {
        code: "210101",
        id: "PE210101",
        name: "PUNO"
      },
      {
        code: "210102",
        id: "PE210102",
        name: "ACORA"
      },
      {
        code: "210103",
        id: "PE210103",
        name: "AMANTANI"
      },
      {
        code: "210104",
        id: "PE210104",
        name: "ATUNCOLLA"
      },
      {
        code: "210105",
        id: "PE210105",
        name: "CAPACHICA"
      },
      {
        code: "210106",
        id: "PE210106",
        name: "CHUCUITO"
      },
      {
        code: "210107",
        id: "PE210107",
        name: "COATA"
      },
      {
        code: "210108",
        id: "PE210108",
        name: "HUATA"
      },
      {
        code: "210109",
        id: "PE210109",
        name: "MAÑAZO"
      },
      {
        code: "210110",
        id: "PE210110",
        name: "PAUCARCOLLA"
      },
      {
        code: "210111",
        id: "PE210111",
        name: "PICHACANI"
      },
      {
        code: "210112",
        id: "PE210112",
        name: "PLATERIA"
      },
      {
        code: "210113",
        id: "PE210113",
        name: "SAN ANTONIO"
      },
      {
        code: "210114",
        id: "PE210114",
        name: "TIQUILLACA"
      },
      {
        code: "210115",
        id: "PE210115",
        name: "VILQUE"
      },
      {
        code: "210201",
        id: "PE210201",
        name: "AZANGARO"
      },
      {
        code: "210202",
        id: "PE210202",
        name: "ACHAYA"
      },
      {
        code: "210203",
        id: "PE210203",
        name: "ARAPA"
      },
      {
        code: "210204",
        id: "PE210204",
        name: "ASILLO"
      },
      {
        code: "210205",
        id: "PE210205",
        name: "CAMINACA"
      },
      {
        code: "210206",
        id: "PE210206",
        name: "CHUPA"
      },
      {
        code: "210207",
        id: "PE210207",
        name: "JOSE DOMINGO CHOQUEHUANCA"
      },
      {
        code: "210208",
        id: "PE210208",
        name: "MUÑANI"
      },
      {
        code: "210209",
        id: "PE210209",
        name: "POTONI"
      },
      {
        code: "210210",
        id: "PE210210",
        name: "SAMAN"
      },
      {
        code: "210211",
        id: "PE210211",
        name: "SAN ANTON"
      },
      {
        code: "210212",
        id: "PE210212",
        name: "SAN JOSE"
      },
      {
        code: "210213",
        id: "PE210213",
        name: "SAN JUAN DE SALINAS"
      },
      {
        code: "210214",
        id: "PE210214",
        name: "SANTIAGO DE PUPUJA"
      },
      {
        code: "210215",
        id: "PE210215",
        name: "TIRAPATA"
      },
      {
        code: "210301",
        id: "PE210301",
        name: "MACUSANI"
      },
      {
        code: "210302",
        id: "PE210302",
        name: "AJOYANI"
      },
      {
        code: "210303",
        id: "PE210303",
        name: "AYAPATA"
      },
      {
        code: "210304",
        id: "PE210304",
        name: "COASA"
      },
      {
        code: "210305",
        id: "PE210305",
        name: "CORANI"
      },
      {
        code: "210306",
        id: "PE210306",
        name: "CRUCERO"
      },
      {
        code: "210307",
        id: "PE210307",
        name: "ITUATA"
      },
      {
        code: "210308",
        id: "PE210308",
        name: "OLLACHEA"
      },
      {
        code: "210309",
        id: "PE210309",
        name: "SAN GABAN"
      },
      {
        code: "210310",
        id: "PE210310",
        name: "USICAYOS"
      },
      {
        code: "210401",
        id: "PE210401",
        name: "JULI"
      },
      {
        code: "210402",
        id: "PE210402",
        name: "DESAGUADERO"
      },
      {
        code: "210403",
        id: "PE210403",
        name: "HUACULLANI"
      },
      {
        code: "210404",
        id: "PE210404",
        name: "KELLUYO"
      },
      {
        code: "210405",
        id: "PE210405",
        name: "PISACOMA"
      },
      {
        code: "210406",
        id: "PE210406",
        name: "POMATA"
      },
      {
        code: "210407",
        id: "PE210407",
        name: "ZEPITA"
      },
      {
        code: "210501",
        id: "PE210501",
        name: "ILAVE"
      },
      {
        code: "210502",
        id: "PE210502",
        name: "CAPAZO"
      },
      {
        code: "210503",
        id: "PE210503",
        name: "PILCUYO"
      },
      {
        code: "210504",
        id: "PE210504",
        name: "SANTA ROSA"
      },
      {
        code: "210505",
        id: "PE210505",
        name: "CONDURIRI"
      },
      {
        code: "210601",
        id: "PE210601",
        name: "HUANCANE"
      },
      {
        code: "210602",
        id: "PE210602",
        name: "COJATA"
      },
      {
        code: "210603",
        id: "PE210603",
        name: "HUATASANI"
      },
      {
        code: "210604",
        id: "PE210604",
        name: "INCHUPALLA"
      },
      {
        code: "210605",
        id: "PE210605",
        name: "PUSI"
      },
      {
        code: "210606",
        id: "PE210606",
        name: "ROSASPATA"
      },
      {
        code: "210607",
        id: "PE210607",
        name: "TARACO"
      },
      {
        code: "210608",
        id: "PE210608",
        name: "VILQUE CHICO"
      },
      {
        code: "210701",
        id: "PE210701",
        name: "LAMPA"
      },
      {
        code: "210702",
        id: "PE210702",
        name: "CABANILLA"
      },
      {
        code: "210703",
        id: "PE210703",
        name: "CALAPUJA"
      },
      {
        code: "210704",
        id: "PE210704",
        name: "NICASIO"
      },
      {
        code: "210705",
        id: "PE210705",
        name: "OCUVIRI"
      },
      {
        code: "210706",
        id: "PE210706",
        name: "PALCA"
      },
      {
        code: "210707",
        id: "PE210707",
        name: "PARATIA"
      },
      {
        code: "210708",
        id: "PE210708",
        name: "PUCARA"
      },
      {
        code: "210709",
        id: "PE210709",
        name: "SANTA LUCIA"
      },
      {
        code: "210710",
        id: "PE210710",
        name: "VILAVILA"
      },
      {
        code: "210801",
        id: "PE210801",
        name: "AYAVIRI"
      },
      {
        code: "210802",
        id: "PE210802",
        name: "ANTAUTA"
      },
      {
        code: "210803",
        id: "PE210803",
        name: "CUPI"
      },
      {
        code: "210804",
        id: "PE210804",
        name: "LLALLI"
      },
      {
        code: "210805",
        id: "PE210805",
        name: "MACARI"
      },
      {
        code: "210806",
        id: "PE210806",
        name: "NUÑOA"
      },
      {
        code: "210807",
        id: "PE210807",
        name: "ORURILLO"
      },
      {
        code: "210808",
        id: "PE210808",
        name: "SANTA ROSA"
      },
      {
        code: "210809",
        id: "PE210809",
        name: "UMACHIRI"
      },
      {
        code: "210901",
        id: "PE210901",
        name: "MOHO"
      },
      {
        code: "210902",
        id: "PE210902",
        name: "CONIMA"
      },
      {
        code: "210903",
        id: "PE210903",
        name: "HUAYRAPATA"
      },
      {
        code: "210904",
        id: "PE210904",
        name: "TILALI"
      },
      {
        code: "211001",
        id: "PE211001",
        name: "PUTINA"
      },
      {
        code: "211002",
        id: "PE211002",
        name: "ANANEA"
      },
      {
        code: "211003",
        id: "PE211003",
        name: "PEDRO VILCA APAZA"
      },
      {
        code: "211004",
        id: "PE211004",
        name: "QUILCAPUNCU"
      },
      {
        code: "211005",
        id: "PE211005",
        name: "SINA"
      },
      {
        code: "211101",
        id: "PE211101",
        name: "JULIACA"
      },
      {
        code: "211102",
        id: "PE211102",
        name: "CABANA"
      },
      {
        code: "211103",
        id: "PE211103",
        name: "CABANILLAS"
      },
      {
        code: "211104",
        id: "PE211104",
        name: "CARACOTO"
      },
      {
        code: "211105",
        id: "PE211105",
        name: "SAN MIGUEL"
      },
      {
        code: "211201",
        id: "PE211201",
        name: "SANDIA"
      },
      {
        code: "211202",
        id: "PE211202",
        name: "CUYOCUYO"
      },
      {
        code: "211203",
        id: "PE211203",
        name: "LIMBANI"
      },
      {
        code: "211204",
        id: "PE211204",
        name: "PATAMBUCO"
      },
      {
        code: "211205",
        id: "PE211205",
        name: "PHARA"
      },
      {
        code: "211206",
        id: "PE211206",
        name: "QUIACA"
      },
      {
        code: "211207",
        id: "PE211207",
        name: "SAN JUAN DEL ORO"
      },
      {
        code: "211208",
        id: "PE211208",
        name: "YANAHUAYA"
      },
      {
        code: "211209",
        id: "PE211209",
        name: "ALTO INAMBARI"
      },
      {
        code: "211210",
        id: "PE211210",
        name: "SAN PEDRO DE PUTINA PUNCO"
      },
      {
        code: "211301",
        id: "PE211301",
        name: "YUNGUYO"
      },
      {
        code: "211302",
        id: "PE211302",
        name: "ANAPIA"
      },
      {
        code: "211303",
        id: "PE211303",
        name: "COPANI"
      },
      {
        code: "211304",
        id: "PE211304",
        name: "CUTURAPI"
      },
      {
        code: "211305",
        id: "PE211305",
        name: "OLLARAYA"
      },
      {
        code: "211306",
        id: "PE211306",
        name: "TINICACHI"
      },
      {
        code: "211307",
        id: "PE211307",
        name: "UNICACHI"
      },
      {
        code: "220101",
        id: "PE220101",
        name: "MOYOBAMBA"
      },
      {
        code: "220102",
        id: "PE220102",
        name: "CALZADA"
      },
      {
        code: "220103",
        id: "PE220103",
        name: "HABANA"
      },
      {
        code: "220104",
        id: "PE220104",
        name: "JEPELACIO"
      },
      {
        code: "220105",
        id: "PE220105",
        name: "SORITOR"
      },
      {
        code: "220106",
        id: "PE220106",
        name: "YANTALO"
      },
      {
        code: "220201",
        id: "PE220201",
        name: "BELLAVISTA"
      },
      {
        code: "220202",
        id: "PE220202",
        name: "ALTO BIAVO"
      },
      {
        code: "220203",
        id: "PE220203",
        name: "BAJO BIAVO"
      },
      {
        code: "220204",
        id: "PE220204",
        name: "HUALLAGA"
      },
      {
        code: "220205",
        id: "PE220205",
        name: "SAN PABLO"
      },
      {
        code: "220206",
        id: "PE220206",
        name: "SAN RAFAEL"
      },
      {
        code: "220301",
        id: "PE220301",
        name: "SAN JOSE DE SISA"
      },
      {
        code: "220302",
        id: "PE220302",
        name: "AGUA BLANCA"
      },
      {
        code: "220303",
        id: "PE220303",
        name: "SAN MARTIN"
      },
      {
        code: "220304",
        id: "PE220304",
        name: "SANTA ROSA"
      },
      {
        code: "220305",
        id: "PE220305",
        name: "SHATOJA"
      },
      {
        code: "220401",
        id: "PE220401",
        name: "SAPOSOA"
      },
      {
        code: "220402",
        id: "PE220402",
        name: "ALTO SAPOSOA"
      },
      {
        code: "220403",
        id: "PE220403",
        name: "EL ESLABON"
      },
      {
        code: "220404",
        id: "PE220404",
        name: "PISCOYACU"
      },
      {
        code: "220405",
        id: "PE220405",
        name: "SACANCHE"
      },
      {
        code: "220406",
        id: "PE220406",
        name: "TINGO DE SAPOSOA"
      },
      {
        code: "220501",
        id: "PE220501",
        name: "LAMAS"
      },
      {
        code: "220502",
        id: "PE220502",
        name: "ALONSO DE ALVARADO"
      },
      {
        code: "220503",
        id: "PE220503",
        name: "BARRANQUITA"
      },
      {
        code: "220504",
        id: "PE220504",
        name: "CAYNARACHI"
      },
      {
        code: "220505",
        id: "PE220505",
        name: "CUÑUMBUQUI"
      },
      {
        code: "220506",
        id: "PE220506",
        name: "PINTO RECODO"
      },
      {
        code: "220507",
        id: "PE220507",
        name: "RUMISAPA"
      },
      {
        code: "220508",
        id: "PE220508",
        name: "SAN ROQUE DE CUMBAZA"
      },
      {
        code: "220509",
        id: "PE220509",
        name: "SHANAO"
      },
      {
        code: "220510",
        id: "PE220510",
        name: "TABALOSOS"
      },
      {
        code: "220511",
        id: "PE220511",
        name: "ZAPATERO"
      },
      {
        code: "220601",
        id: "PE220601",
        name: "JUANJUI"
      },
      {
        code: "220602",
        id: "PE220602",
        name: "CAMPANILLA"
      },
      {
        code: "220603",
        id: "PE220603",
        name: "HUICUNGO"
      },
      {
        code: "220604",
        id: "PE220604",
        name: "PACHIZA"
      },
      {
        code: "220605",
        id: "PE220605",
        name: "PAJARILLO"
      },
      {
        code: "220701",
        id: "PE220701",
        name: "PICOTA"
      },
      {
        code: "220702",
        id: "PE220702",
        name: "BUENOS AIRES"
      },
      {
        code: "220703",
        id: "PE220703",
        name: "CASPISAPA"
      },
      {
        code: "220704",
        id: "PE220704",
        name: "PILLUANA"
      },
      {
        code: "220705",
        id: "PE220705",
        name: "PUCACACA"
      },
      {
        code: "220706",
        id: "PE220706",
        name: "SAN CRISTOBAL"
      },
      {
        code: "220707",
        id: "PE220707",
        name: "SAN HILARION"
      },
      {
        code: "220708",
        id: "PE220708",
        name: "SHAMBOYACU"
      },
      {
        code: "220709",
        id: "PE220709",
        name: "TINGO DE PONASA"
      },
      {
        code: "220710",
        id: "PE220710",
        name: "TRES UNIDOS"
      },
      {
        code: "220801",
        id: "PE220801",
        name: "RIOJA"
      },
      {
        code: "220802",
        id: "PE220802",
        name: "AWAJUN"
      },
      {
        code: "220803",
        id: "PE220803",
        name: "ELIAS SOPLIN VARGAS"
      },
      {
        code: "220804",
        id: "PE220804",
        name: "NUEVA CAJAMARCA"
      },
      {
        code: "220805",
        id: "PE220805",
        name: "PARDO MIGUEL"
      },
      {
        code: "220806",
        id: "PE220806",
        name: "POSIC"
      },
      {
        code: "220807",
        id: "PE220807",
        name: "SAN FERNANDO"
      },
      {
        code: "220808",
        id: "PE220808",
        name: "YORONGOS"
      },
      {
        code: "220809",
        id: "PE220809",
        name: "YURACYACU"
      },
      {
        code: "220901",
        id: "PE220901",
        name: "TARAPOTO"
      },
      {
        code: "220902",
        id: "PE220902",
        name: "ALBERTO LEVEAU"
      },
      {
        code: "220903",
        id: "PE220903",
        name: "CACATACHI"
      },
      {
        code: "220904",
        id: "PE220904",
        name: "CHAZUTA"
      },
      {
        code: "220905",
        id: "PE220905",
        name: "CHIPURANA"
      },
      {
        code: "220906",
        id: "PE220906",
        name: "EL PORVENIR"
      },
      {
        code: "220907",
        id: "PE220907",
        name: "HUIMBAYOC"
      },
      {
        code: "220908",
        id: "PE220908",
        name: "JUAN GUERRA"
      },
      {
        code: "220909",
        id: "PE220909",
        name: "LA BANDA DE SHILCAYO"
      },
      {
        code: "220910",
        id: "PE220910",
        name: "MORALES"
      },
      {
        code: "220911",
        id: "PE220911",
        name: "PAPAPLAYA"
      },
      {
        code: "220912",
        id: "PE220912",
        name: "SAN ANTONIO"
      },
      {
        code: "220913",
        id: "PE220913",
        name: "SAUCE"
      },
      {
        code: "220914",
        id: "PE220914",
        name: "SHAPAJA"
      },
      {
        code: "221001",
        id: "PE221001",
        name: "TOCACHE"
      },
      {
        code: "221002",
        id: "PE221002",
        name: "NUEVO PROGRESO"
      },
      {
        code: "221003",
        id: "PE221003",
        name: "POLVORA"
      },
      {
        code: "221004",
        id: "PE221004",
        name: "SHUNTE"
      },
      {
        code: "221005",
        id: "PE221005",
        name: "UCHIZA"
      },
      {
        code: "230101",
        id: "PE230101",
        name: "TACNA"
      },
      {
        code: "230102",
        id: "PE230102",
        name: "ALTO DE LA ALIANZA"
      },
      {
        code: "230103",
        id: "PE230103",
        name: "CALANA"
      },
      {
        code: "230104",
        id: "PE230104",
        name: "CIUDAD NUEVA"
      },
      {
        code: "230105",
        id: "PE230105",
        name: "INCLAN"
      },
      {
        code: "230106",
        id: "PE230106",
        name: "PACHIA"
      },
      {
        code: "230107",
        id: "PE230107",
        name: "PALCA"
      },
      {
        code: "230108",
        id: "PE230108",
        name: "POCOLLAY"
      },
      {
        code: "230109",
        id: "PE230109",
        name: "SAMA"
      },
      {
        code: "230110",
        id: "PE230110",
        name: "CORONEL GREGORIO ALBARRACIN LANCHIPA"
      },
      {
        code: "230111",
        id: "PE230111",
        name: "LA YARADA LOS PALOS"
      },
      {
        code: "230201",
        id: "PE230201",
        name: "CANDARAVE"
      },
      {
        code: "230202",
        id: "PE230202",
        name: "CAIRANI"
      },
      {
        code: "230203",
        id: "PE230203",
        name: "CAMILACA"
      },
      {
        code: "230204",
        id: "PE230204",
        name: "CURIBAYA"
      },
      {
        code: "230205",
        id: "PE230205",
        name: "HUANUARA"
      },
      {
        code: "230206",
        id: "PE230206",
        name: "QUILAHUANI"
      },
      {
        code: "230301",
        id: "PE230301",
        name: "LOCUMBA"
      },
      {
        code: "230302",
        id: "PE230302",
        name: "ILABAYA"
      },
      {
        code: "230303",
        id: "PE230303",
        name: "ITE"
      },
      {
        code: "230401",
        id: "PE230401",
        name: "TARATA"
      },
      {
        code: "230402",
        id: "PE230402",
        name: "HEROES ALBARRACIN"
      },
      {
        code: "230403",
        id: "PE230403",
        name: "ESTIQUE"
      },
      {
        code: "230404",
        id: "PE230404",
        name: "ESTIQUE-PAMPA"
      },
      {
        code: "230405",
        id: "PE230405",
        name: "SITAJARA"
      },
      {
        code: "230406",
        id: "PE230406",
        name: "SUSAPAYA"
      },
      {
        code: "230407",
        id: "PE230407",
        name: "TARUCACHI"
      },
      {
        code: "230408",
        id: "PE230408",
        name: "TICACO"
      },
      {
        code: "240101",
        id: "PE240101",
        name: "TUMBES"
      },
      {
        code: "240102",
        id: "PE240102",
        name: "CORRALES"
      },
      {
        code: "240103",
        id: "PE240103",
        name: "LA CRUZ"
      },
      {
        code: "240104",
        id: "PE240104",
        name: "PAMPAS DE HOSPITAL"
      },
      {
        code: "240105",
        id: "PE240105",
        name: "SAN JACINTO"
      },
      {
        code: "240106",
        id: "PE240106",
        name: "SAN JUAN DE LA VIRGEN"
      },
      {
        code: "240201",
        id: "PE240201",
        name: "ZORRITOS"
      },
      {
        code: "240202",
        id: "PE240202",
        name: "CASITAS"
      },
      {
        code: "240203",
        id: "PE240203",
        name: "CANOAS DE PUNTA SAL"
      },
      {
        code: "240301",
        id: "PE240301",
        name: "ZARUMILLA"
      },
      {
        code: "240302",
        id: "PE240302",
        name: "AGUAS VERDES"
      },
      {
        code: "240303",
        id: "PE240303",
        name: "MATAPALO"
      },
      {
        code: "240304",
        id: "PE240304",
        name: "PAPAYAL"
      },
      {
        code: "250101",
        id: "PE250101",
        name: "CALLERIA"
      },
      {
        code: "250102",
        id: "PE250102",
        name: "CAMPOVERDE"
      },
      {
        code: "250103",
        id: "PE250103",
        name: "IPARIA"
      },
      {
        code: "250104",
        id: "PE250104",
        name: "MASISEA"
      },
      {
        code: "250105",
        id: "PE250105",
        name: "YARINACOCHA"
      },
      {
        code: "250106",
        id: "PE250106",
        name: "NUEVA REQUENA"
      },
      {
        code: "250107",
        id: "PE250107",
        name: "MANANTAY"
      },
      {
        code: "250201",
        id: "PE250201",
        name: "RAYMONDI"
      },
      {
        code: "250202",
        id: "PE250202",
        name: "SEPAHUA"
      },
      {
        code: "250203",
        id: "PE250203",
        name: "TAHUANIA"
      },
      {
        code: "250204",
        id: "PE250204",
        name: "YURUA"
      },
      {
        code: "250301",
        id: "PE250301",
        name: "PADRE ABAD"
      },
      {
        code: "250302",
        id: "PE250302",
        name: "IRAZOLA"
      },
      {
        code: "250303",
        id: "PE250303",
        name: "CURIMANA"
      },
      {
        code: "250304",
        id: "PE250304",
        name: "NESHUYA"
      },
      {
        code: "250305",
        id: "PE250305",
        name: "ALEXANDER VON HUMBOLDT"
      },
      {
        code: "250401",
        id: "PE250401",
        name: "PURUS"
      }
    ],
    name: "Perú",
    name_default: "Perú",
    phone_code: "+51",
    has_regulation: false,
    tax_percent: 18
  },
  {
    id: "US",
    states: [],
    name: "Estados unidos",
    name_default: "Estados unidos",
    phone_code: "+1",
    has_regulation: false,
    tax_percent: 5
  }
]