import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

// HTTP
import httpRoutes from '@/modules/http/routes'

// AUTH
import authRoutes from '@/modules/auth/routes'

// DOCUMENTS
import documentRoutes from '@/modules/documents/routes'

// ACCOUNT
import accountRoutes from '@/modules/account/routes'

// CUSTOMERS
import customersRoutes from '@/modules/customer/routes'

// APPS
import Applications from '@/modules/apps/views/Main'
import purchaseRoutes from '@/modules/purchase/routes'

// POS
import posRoutes from '@/modules/pos/routes'

// FOLIOS
import foliosRoutes from '@/modules/folios/routes'

// SUPPLIERS
import supplierRoutes from '@/modules/supplier/routes'

// REPORT
import reportRoutes from '@/modules/reports/routes'

// DOWNLOAD CENTER
import downloadCenterRoutes from '@/modules/download-center/routes'

// COMPLIANCE
// import complianceRoutes from '@/modules/compliance/routes'

Vue.use(VueRouter)
const routes = [
  ...authRoutes,
  ...accountRoutes,
  ...customersRoutes,
  ...supplierRoutes,
  ...purchaseRoutes,
  ...httpRoutes,
  ...documentRoutes,
  ...posRoutes,
  ...foliosRoutes,
  ...reportRoutes,
  ...downloadCenterRoutes,
  // ...complianceRoutes,
  {
    path: '/applications',
    name: 'Applications',
    component: Applications,
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'Applications',
      requireAuth: true,
      requireAccount: true,
      title: 'Menú de aplicaciones'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('global/CANCEL_PENDING_REQUESTS')
  if (to.meta.requireAuth) {
    if (store.state.auth.token) {
      if (to.meta.requireAccount) {
        if (store.state.auth.account.id) {
          if (!store.state.auth.userAccount.is_staff) {
            if (store.state.accounts.me?.id === undefined) {
              store.dispatch('accounts/GET_ME', {
                resource: 'members/me/?expand=groups'
              })
              .then(() => {
                if (to.meta.permissionsRequired !== undefined && !store.state.accounts.me?.is_owner && !to.meta.permissionsRequired?.some((l) => store.state.accounts.me.permissions?.some((v) => v === l))) {
                  next({ name: 'PermissionDenied' })
                } else next()
              })
              .catch(() => {
                store.dispatch('auth/DELETE_AUTH')
                store.commit('accounts/SET_ME', {})
              })
            } else {
              if (to.meta.permissionsRequired !== undefined && !store.state.accounts.me?.is_owner && !to.meta.permissionsRequired?.some((l) => store.state.accounts.me.permissions?.some((v) => v === l))) {
                next({ name: 'PermissionDenied' })
              } else next()
            }
          } else next()
        } else {
          next({ path: '/account/choose' })
        }
      } else {
        next()
      }
    } else {
      next({
        path: '/auth/signin',
        query: { redirect: to.fullPath }
      })
      store.dispatch('auth/GET_USER')
    }
  } else {
    if (to.name === 'AuthSignUp') {
      if (to.query.invitation) {
        next()
      }
    }
    next()
  }
})

const updateFavicon = () => {
  let link = document.createElement('link')
  let oldLink = document.getElementById('favicon')

  link.id = 'favicon'
  link.rel = 'icon'
  link.href = `/static/assets/${process.env?.VUE_APP_MODE === 'ax' ? 'AppFavicon@3x.png' : 'favicon.ico'}`

  if (oldLink) {
    document.head.removeChild(oldLink)
  }
  document.head.appendChild(link)
}

// Call it only once, not on each router.beforeEach
updateFavicon()

router.afterEach((to, from) => {
  if (store.state.auth.account.id) {
    // track Segment page navigation
    if (process.env.NODE_ENV === 'production') {
      window.analytics.page(to.name)
    }
  }
})

router.beforeEach((to, from, next) => {
  const currentClient = process.env?.VUE_APP_MODE === 'ax' ? 'Axteroid' : 'Factura Chile'
  document.title = to.meta.title ? to.meta.title + ` - ${currentClient}` : currentClient
  next(true)
})

export default router
