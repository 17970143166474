import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import { rutFormat } from 'rut-helpers'
import moment from 'moment'
import numeral from 'numeral'
import { debounce, each, template, isEmpty, isArray, isString } from 'lodash'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import documentTypes from '@/collections/documentTypes.js'

moment.locale('es')

const currencies = [
  { id: 'USD', format: { precision: 2, thousand: ',', decimal: '.' }, country: 'US', name: 'Dólar estadounidense', conversion: null },
  { id: 'CLF', format: { precision: 2, thousand: ',', decimal: '.' }, country: 'CL', name: 'Unidad de fomento chilena', conversion: 'CLP' },
  { id: 'CLP', format: { precision: 0, thousand: '.', decimal: ',' }, country: 'CL', name: 'Peso chileno', conversion: null },
  { id: 'EUR', format: { precision: 2, thousand: ',', decimal: '.' }, country: 'EU', name: 'Euro', conversion: null },
  { id: 'PEN', format: { precision: 2, thousand: ',', decimal: '.' }, country: 'PE', name: 'Sol peruano', conversion: null },
  { id: 'SEK', format: { precision: 2, thousand: ',', decimal: '.' }, country: 'SE', name: 'Corona SC', conversion: null }
]

const globalDocumentsTypes = ['US1', 'CL110']

const baseCountries = [
  { id: 'DE', name: 'Alemania', invoiceTypes: globalDocumentsTypes },
	{ id: 'AR', name: 'Argentina', invoiceTypes: globalDocumentsTypes },
	{ id: 'AU', name: 'Australia', invoiceTypes: globalDocumentsTypes },
	{ id: 'AT', name: 'Austria', invoiceTypes: globalDocumentsTypes },
	{ id: 'BE', name: 'Bélgica', invoiceTypes: globalDocumentsTypes },
	{ id: 'BO', name: 'Bolivia', invoiceTypes: globalDocumentsTypes },
	{ id: 'BR', name: 'Brasil', invoiceTypes: globalDocumentsTypes },
	{ id: 'CA', name: 'Canada', invoiceTypes: globalDocumentsTypes },
	{ id: 'CL', name: 'Chile', invoiceTypes: ['US1', 'CL33', 'CL34', 'CL39', 'CL41'] },
	{ id: 'CO', name: 'Colombia', invoiceTypes: globalDocumentsTypes },
	{ id: 'CR', name: 'Costa Rica', invoiceTypes: globalDocumentsTypes },
	{ id: 'DK', name: 'Dinamarca', invoiceTypes: globalDocumentsTypes },
	{ id: 'EC', name: 'Ecuador', invoiceTypes: globalDocumentsTypes },
	{ id: 'SV', name: 'El Salvador', invoiceTypes: globalDocumentsTypes },
	{ id: 'SK', name: 'Eslovaquia', invoiceTypes: globalDocumentsTypes },
	{ id: 'SI', name: 'Eslovenia', invoiceTypes: globalDocumentsTypes },
	{ id: 'ES', name: 'España', invoiceTypes: globalDocumentsTypes },
	{ id: 'US', name: 'Estados Unidos', invoiceTypes: globalDocumentsTypes },
	{ id: 'EE', name: 'Estonia', invoiceTypes: globalDocumentsTypes },
	{ id: 'PH', name: 'Filipinas', invoiceTypes: globalDocumentsTypes },
	{ id: 'FI', name: 'Finlandia', invoiceTypes: globalDocumentsTypes },
	{ id: 'FR', name: 'Francia', invoiceTypes: globalDocumentsTypes },
	{ id: 'GR', name: 'Grecia', invoiceTypes: globalDocumentsTypes },
	{ id: 'GT', name: 'Guatemala', invoiceTypes: globalDocumentsTypes },
	{ id: 'HK', name: 'Hong Kong', invoiceTypes: globalDocumentsTypes },
	{ id: 'HU', name: 'Hungria', invoiceTypes: globalDocumentsTypes },
	{ id: 'ID', name: 'Indonesia', invoiceTypes: globalDocumentsTypes },
	{ id: 'IE', name: 'Irlanda', invoiceTypes: globalDocumentsTypes },
	{ id: 'KY', name: 'Islas Caimán', invoiceTypes: globalDocumentsTypes },
	{ id: 'IL', name: 'Israel', invoiceTypes: globalDocumentsTypes },
	{ id: 'IT', name: 'Italia', invoiceTypes: globalDocumentsTypes },
	{ id: 'JP', name: 'Japon', invoiceTypes: globalDocumentsTypes },
	{ id: 'LV', name: 'Letonia', invoiceTypes: globalDocumentsTypes },
	{ id: 'LT', name: 'Lituania', invoiceTypes: globalDocumentsTypes },
	{ id: 'LU', name: 'Luxemburgo', invoiceTypes: globalDocumentsTypes },
	{ id: 'MY', name: 'Malasia', invoiceTypes: globalDocumentsTypes },
	{ id: 'MX', name: 'México', invoiceTypes: globalDocumentsTypes },
	{ id: 'NO', name: 'Noruega', invoiceTypes: globalDocumentsTypes },
	{ id: 'NZ', name: 'Nueva Zelanda', invoiceTypes: globalDocumentsTypes },
	{ id: 'NL', name: 'Paises Bajos', invoiceTypes: globalDocumentsTypes },
	{ id: 'PA', name: 'Panama', invoiceTypes: globalDocumentsTypes },
	{ id: 'PY', name: 'Paraguay', invoiceTypes: globalDocumentsTypes },
	{ id: 'PE', name: 'Perú', invoiceTypes: globalDocumentsTypes },
	{ id: 'PL', name: 'Polonia', invoiceTypes: globalDocumentsTypes },
	{ id: 'PT', name: 'Portugal', invoiceTypes: globalDocumentsTypes },
	{ id: 'PR', name: 'Puerto Rico', invoiceTypes: globalDocumentsTypes },
	{ id: 'GB', name: 'Reino Unido', invoiceTypes: globalDocumentsTypes },
	{ id: 'CZ', name: 'República Checa', invoiceTypes: globalDocumentsTypes },
	{ id: 'RU', name: 'Rusia', invoiceTypes: globalDocumentsTypes },
	{ id: 'SG', name: 'Singapur', invoiceTypes: globalDocumentsTypes },
	{ id: 'SE', name: 'Suecia', invoiceTypes: globalDocumentsTypes },
	{ id: 'CH', name: 'Suiza', invoiceTypes: globalDocumentsTypes },
	{ id: 'TW', name: 'Taiwan', invoiceTypes: globalDocumentsTypes },
	{ id: 'UY', name: 'Uruguay', invoiceTypes: globalDocumentsTypes },
	{ id: 'VE', name: 'Venezuela', invoiceTypes: globalDocumentsTypes }
]

const frequencies = [
  { id: 'weekly', name: 'semanal' },
  { id: 'every_fifteen_days', name: 'cada 15 días' },
  { id: 'monthly', name: 'mensual' },
  { id: 'bimonthly', name: 'cada 2 meses' },
  { id: 'quarterly', name: 'cada 3 meses' },
  { id: 'biannual', name: 'cada 6 meses' },
  { id: 'annual', name: 'anual' }
]

export default {
  directives: {
    mask,
    dni: {
      update: function (el, binding, vnode) {
        let country = binding.value || 'CL'
        if (vnode.context.listenerAttached) {
          el.removeEventListener('input', vnode.context.rutFunction)
          vnode.context.listenerAttached = false
        }
        vnode.context.rutFunction = (e) => {
          vnode.componentInstance.$emit('input', country === 'CL' ? rutFormat(e.target.value) : e.target.value)
        }
        el.addEventListener('input', vnode.context.rutFunction)
        vnode.context.listenerAttached = true
      }
    }
  },
  data: () => ({
    baseCountries: baseCountries,
    currencies: currencies,
    currentMonth: moment().format('MMMM'),
    dropdowns: [],
    dropdownShow: false,
    frequencies: frequencies,
    listenerAttached: false,
    periods: [
      { label: '3 meses', value: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD') },
      { label: '6 meses', value: moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD') },
      { label: '12 meses', value: moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD') },
      { label: '24 meses', value: moment().subtract(23, 'months').startOf('month').format('YYYY-MM-DD') }
    ],
    rutFunction: false,
    today: moment().format('YYYY-MM-DD')
  }),
  computed: {
    ...mapState({
      account: state => state.auth.account
    }),
    nameDocumentsType () {
      return (val = '') => documentTypes.find(d => d.id === val)?.name
    },
    idDocumentType () {
      return (val = '') => documentTypes.find(d => d.name === val)?.id
    }
  },
  filters: {
    nameDocumentsType (val = '') {
      return documentTypes.find(d => d.id === val)?.name
    },
    firstDocumentsTypeCurrency (val = '') {
      return documentTypes.find(d => d.id === val)?.currencies[0] ?? ''
    },
    currency (value, id) {
      const currency = currencies.find(c => c.id === documentTypes.find(d => d.id === id)?.currencies[0])
      if (!currency) {
        return value
      }
      const options = Object.assign({}, currency.format, { symbol: '' })
      return formatMoney(value, options)
    },
    number (val, rounded = true) {
      numeral.localeData().delimiters.thousands = rounded ? '.' : ','
      numeral.localeData().delimiters.decimal = rounded ? ',' : '.'
      return numeral(val).format(rounded ? '0,0' : '0,0.00')
    },
    date (val) {
      return moment(val).format('DD MMM YYYY')
    },
    dateBar (val) {
      return moment(val).format('DD/MM/YYYY')
    },
    dateConditional (val) {
      return moment(val, "hmm").format("HH:mm") > '24:00' ? moment(val).format('DD MMM YYYY hh:mm') : moment(val).fromNow()
    },
    dateTime (val) {
      return moment(val).format('DD MMM YYYY HH:mm')
    },
    dateTimeSecond (val) {
      return moment(val).format('DD MMM YYYY HH:mm:ss')
    },
    dateFromNow (val) {
      if (!val) {
        return null
      }
      return moment(val).fromNow()
    },
    capitalize (val) {
      return `${val.toString().charAt(0).toUpperCase()}${val.toString().slice(1)}`
    },
    lower (val) {
      return val ? val.toString().toLowerCase() : val
    },
    empty (val) {
      if (!val) return '---'
      return val
    }
  },
  methods: {
    currencyDetail (id) {
      return currencies.find(c => c.id === id)
    },
    dateIsBefore (before, after) {
      return moment(before).isBefore(after)
    },
    debounceMethod: debounce(function (callback) {
      return callback()
    }, 700),
    sumProperty (arr, prop) {
      /**
       * Return sum property in objects array
       * @param arr objects array to iterate
       * @param prop string name property
       */
      if (arr.length) {
        return arr.map(element => parseInt(element[prop])).reduce((a, b) => a + b)
      }
      return 0
    },
    toClipboard (string) {
      /**
       * Copy text to clipboard
       * @param string text to copy
       */
      this.$copyText(string)
      .then(() => {
        this.$dialog.message.info('Copiado')
      })
    },
    parseErrorsDict (dict, translate = 'api') {
      const containerTemplate = template('<ul class="error-Item"><%= content %></ul>')
      let output = []
      each(dict, (value, key) => {
        const fieldTemplate = template(
          '<li class="error-Field"><span class="error-Field--Name"><%= name %></span> <%= content %></li>'
        )
        const valueTemplate = template(
          '<span class="error-Field-Value"><%= value %></span>'
        )
        let plainValue = null
        let listValue = null
        let content = null
        if (isString(value)) {
          plainValue = value
        } else if (isArray(value)) {
          if (isString(value[0])) {
            plainValue = value.join(' ')
          } else {
            listValue = this.parseErrorsList(value)
          }
        }
        if (plainValue) {
          content = valueTemplate({ value: plainValue })
        } else if (listValue) {
          content = listValue
        }
        if (content) {
          if (translate) {
            if (key?.search(/_(\d+)$/) !== -1) {
              const values = key.split('_')
              key = this.$t(`${translate}.${values[0]}`, {number: values[1]})
            } else {
              key = this.$t(`${translate}.${key}`)
            }
          }
          output.push(fieldTemplate({
            name: key,
            content: content
          }))
        }
      })
      output = output.join(' ')
      if (output) {
        output = containerTemplate({ content: output })
      }
      return output
    },
    parseErrorsList (list, translate = 'api') {
      const containerTemplate = template('<%= content %>')
      let output = []
      each(list, (dict, index) => {
        if (!isEmpty(dict)) {
          output.push(this.parseErrorsDict(dict, translate))
        }
      })
      output = output.join(' ')
      if (output) {
        output = containerTemplate({ content: output })
      }
      return output
    },
    parseErrors (data, translate = 'api') {
      if (isArray(data)) {
        return this.parseErrorsList(data, translate)
      } else {
        return this.parseErrorsDict(data, translate)
      }
    },
    startTour (id) {
      /**
       * @param id Number intercom tour id
       */
      if (process.env.NODE_ENV === 'production') {
        window.Intercom('startTour', id)
      }
    },
    intercomNewMessage (message = '') {
      if (process.env.NODE_ENV === 'production') {
        window.Intercom('showNewMessage', message)
      }
    },
    formatNumber (value, round = true, decimalPlaces = 2) {
      if (round) {
        return Math.round(value)
      }
      return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
    },
    formatNumber2 (val, rounded = true) {
      numeral.localeData().delimiters.thousands = rounded ? '.' : ','
      numeral.localeData().delimiters.decimal = rounded ? ',' : '.'
      return numeral(val).format(rounded ? '0,0' : '0,0.00')
    },
    firstDocumentsTypeCurrency (val = '') {
      return documentTypes.find(d => d.id === val)?.currencies[0] ?? ''
    }
  }
}