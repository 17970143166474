import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  batchList: [],
  batchCount: null,
  batch_documentsList: [],
  batchDetail: {},
  batch_documentsCount: null
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}