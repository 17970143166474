import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  certificatesCount: null,
  certificatesList: [],
  activitiesCount: null,
  activitiesList: [],
  connectionsCount: 0,
  connectionsDetail: {},
  connectionsList: [],
  // cafsCount: null,
  // cafsDetail: {},
  // cafsList: [],
  cafsCount: null,
  cafsList: [],
  cafsDetail: {},
  rvdsCount: null,
  rvdsList: [],
  sequencesDetail: {},
  sequencesCount: null,
  sequencesList: [],
  supplierresponsesList: [],
  supplierresponsesCount: null,
  swapsDetail: {},
  swapsCount: null,
  swapsList: [],
  reportList: [],
  reportCount: 0
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}
