// import store from '@/store'

const reportRoutes = [
  {
    path: '/metrics/collections',
    name: 'MetricList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Coleccción de Métricas'
    },
    component: () => import('@/modules/reports/views/metrics/MetricList')
  },
  {
    path: '/dashboard/default',
    name: 'DefaultDashboardList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Dashboard predefinidos'
    },
    component: () => import('@/modules/reports/views/dashboards/DefaultDashboardList')
  },
  {
    path: '/dashboard/favorites',
    name: 'Favorites',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Dashboard favoritos'
    },
    component: () => import('@/modules/reports/views/dashboards/DefaultDashboardList')
  },
  {
    path: '/dashboard/create',
    name: 'DashboardCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Dashboard'
    },
    component: () => import('@/modules/reports/views/dashboards/DashboardCreateUpdate')
  },
  {
    path: '/dashboard/:id/edit',
    name: 'DashboardUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Dashboard'
    },
    component: () => import('@/modules/reports/views/dashboards/DashboardCreateUpdate')
  },
  {
    path: '/dashboard/:id/',
    name: 'DashboardRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Detalles del Dashboard'
    },
    component: () => import('@/modules/reports/views/dashboards/DashboardCreateUpdate')
  },
  {
    path: '/dashboards',
    name: 'Dashboards',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Bienvenida dashboards'
    },
    component: () => import('@/modules/reports/views/dashboards/WelcomeDashboard')
  },
  {
    path: '/reports/irs/built_in/rvds',
    name: 'IrsBuiltInRvds',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Reporte RVD'
      // breadcrumbs: [
      //   {
      //     text: 'Configuración',
      //     to: { name: 'AccountBasic' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Emisión',
      //     to: { name: 'IrsBuiltInSettings' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Reporte RVD',
      //     disabled: true
      //   }
      // ]
    },
    component: () => import('@/modules/reports/views/sii/ReportRvds')
  },
  {
    path: '/reports/irs/built_in/transactional',
    name: 'IrsBuiltInReportTransactional',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      title: 'Reporte transaccional'
      // breadcrumbs: [
      //   {
      //     text: 'Configuración',
      //     to: { name: 'AccountBasic' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Emisión',
      //     to: { name: 'IrsBuiltInSettings' },
      //     link: true,
      //     exact: true,
      //     disabled: false
      //   },
      //   {
      //     text: 'Reporte transaccional',
      //     disabled: true
      //   }
      // ]
    },
    component: () => import('@/modules/reports/views/sii/ReportTransactional')
  },
  {
    path: '/reports/document-summary',
    name: 'DocumentSummary',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      isActive: true,
      title: 'Resumen de registro de ventas'
    },
    component: () => import('@/modules/reports/documents/views/DocumentSummary')
  },
  {
    path: '/reports/document-messages',
    name: 'SentDocumentList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'SentDocumentList',
      requireAuth: true,
      requireAccount: true,
      isActive: true,
      title: 'Documentos enviados'
    },
    component: () => import('@/modules/reports/documents/views/SentDocumentList')
  }
]

export default reportRoutes
