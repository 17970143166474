import { actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  customersDetail: {},
  customersCount: null,
  customersList: [],
  contactsDetail: {},
  contactsCount: null,
  contactsList: [],
  business_activitiesList: [],
  extra_addressesList: [],
  orgunitsCount: null,
  orgunitsDetail: {},
  orgunitsList: [],
  retrieveLoader: false,
  createLoader: false,
  deleteLoader: false
}

const getters = {
  orgunits: state => {
    return state.orgunitsList
  }
}

export default {
  actions: actionsAbstract,
  getters,
  mutations: mutationsAbstract,
  namespaced: true,
  state
}
