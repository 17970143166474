import { account as api } from '@/backend/api'
import { stateAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  documents: {},
  documents_due: {},
  payment: {},
  subscriptions: {},
  customer: {},
  metrics: {},
  ...stateAbstract
}

const actions = {
  GET: ({ commit }, { resource, query }) => {
    return new Promise((resolve, reject) => {
      commit('SET_LOADER', 'retrieve')
      api.get(resource, query)
      .then(response => {
        commit('SET', { response: response, resource: resource })
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  }
}

const mutations = {
  SET: (state, { response, resource }) => {
    const r = resource.split('/').pop()
    if (state[r]) {
      state[r] = response.data
    }
    state.retrieveLoader = false
    state.createLoader = false
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
