<template>
  <v-app-bar class="pt-1 app-bar-main pl-0" flat fixed app absolute v-if="$store.state.auth.account.id && $route.meta.displayOrganization" style="position: fixed !important; transition: none !important">
    <!--:color="['MainCompliance', 'WelcomeCompliance'].includes($route.name) ? 'barCompliance' : `${['AccountMembers'].includes($route.name) ? 'transparent' : 'transparent'}`"-->
    <!-- <search-bar v-if="!['MainCompliance', 'WelcomeCompliance'].includes($route.name)" class="ml-8" /> -->
    <!-- brand compliance -->
    <v-btn class="ml-0 mr-n5" :to="{ name: 'AccountDashboard' }" text :ripple="false" v-if="$route.name === 'WelcolmeCompliance'">
      <v-icon size="25" left color="defaultGrey">mdi-arrow-left</v-icon>
    </v-btn>
    <!-- <img class="mx-5" v-if="['MainCompliance', 'WelcomeCompliance'].includes($route.name)" :src="require(`@/assets/compliance/appAsset-brand--Compliance${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="27" style="margin-left:-8px;" /> -->
    <!-- end brand compliance -->
     <v-spacer />

    <!-- brand compliance -->
    <!-- <v-btn class="mr-5" v-if="$route.name === 'WelcomeCompliance'" :to="{ name: 'MainCompliance' }" color="primary" :ripple="false">Contactar con ejecutivo</v-btn> -->
    <!-- end brand compliance -->
    <template>
      <!-- apps menu --
      <v-menu content-class="menu-dialog" v-if="$store?.state?.auth?.account?.country === 'CL' && env !== 'fc' && !['MainCompliance', 'WelcomeCompliance'].includes($route.name)" close-on-click close-on-content-click offset-y :nudge-bottom="8" :max-width="400" :nudge-right="-170" transition="slide-y-transition">
        <template v-slot:activator="{on, attrs}">
          <v-btn class="mr-n2 ml-1 px-0 d-none" text v-on="on" v-bind="attrs" :ripple="false">
            <v-icon large color="defaultGrey">mdi-dots-grid</v-icon>
          </v-btn>
        </template>
        <v-card class="py-3">
          <v-list>
            <v-list-item>
              <v-list-item-content class="ml-n2 px-5">
                <v-list-item-title class="body-1 secondary--text font-weight-bold">Aplicaciones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-n2" :to="{ name: 'MainCompliance' }" :ripple="false">
              <v-list-item-content class="ml-n2 px-5">
                <v-list-item-title>
                  <img :src="require(`@/assets/compliance/compliance${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" height="30">
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n6 ml-10 body-2">Lorem ipsum dolor sit amet consectetur adipisicing.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-n2 disabled-list" disabled>
              <v-list-item-content class="ml-n2 px-5">
                <v-list-item-title class="">
                  <img :src="require(`@/assets/compliance/marketing${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" height="30">
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n6 ml-10 body-2">Lorem ipsum dolor sit amet consectetur adipisicing.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-n2 disabled-list" disabled>
              <v-list-item-content class="ml-n2 px-5">
                <v-list-item-title class="">
                  <img :src="require(`@/assets/compliance/rev.mng${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" height="30">
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n6 ml-10 body-2">Lorem ipsum dolor sit amet consectetur adipisicing.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider class="mt-3" />
          <v-card-actions class="mx-0 pb-0 pt-2 justify-center">
            <v-btn text color="primary">Contactar a un ejecutivo</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!- end apps menu -->

      <!-- account menu -->
      <v-menu v-if="$store.state.auth.account.id" close-on-click close-on-content-click offset-y width="300" :nudge-bottom="8" left transition="slide-y-transition">
        <template v-slot:activator="{on, attrs}">
          <v-btn class="mr-6" v-on="on" v-bind="attrs" outlined :ripple="false">
            <img :src="require(`@/assets/account/account-choose-icon.svg`)" :width="16" />
            <span class="mx-2 text-truncate" style="max-width: 150px;">{{$store.state.auth.account.name | capitalize}}</span>
            <v-icon class="mr-n1" size="18">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item class="px-3" active-class="transparent" :to="{ name: 'AccountBasic' }" :ripple="false">
            <v-list-item-title class="body-2"><v-icon color="secondary" left small>mdi-cog</v-icon>Configuración</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item class="px-3" active-class="transparent" :to="{ name: 'DownloadCenterList' }" :ripple="false">
            <v-list-item-title class="body-2"><v-icon color="secondary" left small>mdi-download-box</v-icon>Centro de descarga</v-list-item-title>
          </v-list-item>
          <v-divider /> -->
          <v-list-item class="px-3" :to="{ name: 'AuthChoose' }" :ripple="false">
            <v-list-item-title class="body-2"><v-icon color="secondary" left small>mdi-swap-horizontal</v-icon>Cambiar organización</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- end account menu -->

      <!-- user menu --
      <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="8" :min-width="200" :nudge-left="15" left transition="slide-y-transition">
        <template v-slot:activator="{on, attrs}">
          <v-btn class="ml-2 mr-5 px-0" text v-on="on" v-bind="attrs" :ripple="false">
            <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item class="px-3" :ripple="false" active-class="transparent">
            <v-list-item-title class="body-2"><v-icon class="mt-n1" color="secondary" left small>mdi-account</v-icon>{{ $store.state.auth.userAccount.first_name || 'Sin definir' }} {{$store.state.auth.userAccount.last_name}} </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-3" :to="{ name: 'AuthSettings' }" :ripple="false" active-class="transparent">
            <v-list-item-title class="body-2"><v-icon class="mt-n1" color="secondary" left small>mdi-cog</v-icon>Cuenta de usuario</v-list-item-title>
          </v-list-item>
          <template v-if="env !== 'fc'">
          <v-divider />
          <v-list-item class="px-3" @click="saveDarkMode()" :ripple="false" active-class="transparent">
            <v-list-item-title class="body-2">
              <v-icon class="mt-n1" color="secondary" left small>mdi-theme-light-dark</v-icon>Modo {{ !$vuetify.theme.dark ? 'Oscuro' : 'Claro' }}
            </v-list-item-title>
          </v-list-item>
        </template>
          <v-divider />
          <v-list-item class="px-3" @click="deleteAuth" :ripple="false">
            <v-list-item-title class="body-2"><v-icon color="secondary" left small>mdi-exit-to-app</v-icon>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!- end user menu -->
    </template>
  </v-app-bar>
</template>
<script>
import SearchBar from '@/components/menu/SearchBar'
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    SearchBar
  },
  data: () => ({
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  methods: {
    deleteAuth () {
      this.$store.dispatch('auth/DELETE_AUTH')
      this.$store.commit('accounts/SET_ME', {})
    }
  }
}
</script>
